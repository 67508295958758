import React from 'react'
import { Api, Utils } from '@jesseteal/teal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Container } from '@material-ui/core'
import Page from 'components/Page'
import UserForm from 'components/UserForm'
import CarrierInfo from '../account/CarrierInfo'
import RoleManager from './components/RoleManager'
import SimpleGrid from 'components/Basic/SimpleGrid'
import K from 'Constants'
import { PK } from 'utils'

const ManageUser = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const user_id = PK.get(id)
  const isDev = Utils.token.get().sub === 1

  const impersonate = React.useCallback(async () => {
    Api.Post('/auth/imp', {
      remora: Utils.token.token(),
      userId: user_id,
    }).then((r) => {
      Utils.token.set(r.token)
      navigate(K.route.shipments.href)
    })
    // eslint-disable-next-line
  }, [id])
  return (
    <Page>
      <Container maxWidth="lg">
        <SimpleGrid
          items={[
            {
              content: (
                <UserForm
                  id={user_id}
                  adminForm={true}
                  onBack={() => navigate(K.route.admin_users.href)}
                />
              ),
              size: 6,
            },
            // add carrier info
            {
              content: (
                <SimpleGrid
                  items={[
                    {
                      content: (
                        <>
                          <RoleManager id={user_id} />
                          {isDev ? (
                            <Button variant="outlined" onClick={impersonate}>
                              Impersonate
                            </Button>
                          ) : null}
                        </>
                      ),
                    },
                    { content: <CarrierInfo id={user_id} /> },
                  ]}
                />
              ),
              size: 6,
            },
          ]}
        />
      </Container>
    </Page>
  )
}

export default ManageUser
