import React from 'react'
import { Grid } from '@material-ui/core'
import Typo from 'components/Typo'
import Input from './Input'

/*
usage:
<RawForm
  onChange={modified => {}}
  onEnter={() => {}}
  errors={errors}
  values={values}
  fields={[
    FieldDef.name('name').get()
  ]}
/>
*/
const RawForm = props => {
  const { fields, values, errors, edit = true, useAutoFocus = false } = props

  const column_grid = col_array => {
    return (
      <Grid container spacing={6}>
        {col_array.map((col, i) => {
          return (
            <Grid item xs={12} sm={col.column.size || 12} key={i}>
              {field_grid(col.column.fields)}
            </Grid>
          )
        })}
      </Grid>
    )
  }

  const field_grid = field_array => {
    return (
      <Grid container spacing={3}>
        {field_array
          .filter(f => !!f.label || !!f.spacer || !!f.header || !!f.children)
          .map((f, i) => {
            if (f.spacer) {
              return <Grid item xs={12} sm={f.spacer} key={i} />
            }
            if (f.children) {
              return (
                <Grid item xs={12} sm={f.size} key={i}>
                  {f.children}
                </Grid>
              )
            }
            if (f.header) {
              return (
                <Grid item xs={12} sm={f.size} key={i}>
                  {Typo.form.header(f.header)}
                </Grid>
              )
            }
            if (f.hide && f.hide(values)) {
              return <Grid item xs={12} sm={f.size || 12} key={f.name} />
            }
            return (
              <Grid item xs={12} sm={f.size || 12} key={f.name}>
                <Input
                  autoFocus={useAutoFocus && i === 0 && !values.id}
                  edit={edit}
                  field={f}
                  values={values}
                  errors={errors}
                  setValues={props.onChange}
                  onEnter={props.onEnter}
                />
              </Grid>
            )
          })}
      </Grid>
    )
  }

  if (!fields) {
    return null
  }
  // alt-view with columns
  if (!!fields[0].column) {
    return column_grid(fields)
  }
  return field_grid(fields)
}

export default RawForm
