import React from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleTabs from 'components/Basic/SimpleTabs'
import { Container } from '@material-ui/core'
import Page from 'components/Page'
import RequestList from './components/RequestList'
// import T from 'components/Typo'
import K from 'Constants'
import shared from 'shared/constants'
// import { SimpleGrid } from 'components/Basic'

const Requests = () => {
  const [tab, set_tab] = React.useState(0)
  const navigate = useNavigate()
  const getStatusFilter = () => {
    switch (tab) {
      case 0:
        return () => true
      case 1:
        return (r) => r.status === shared.status.OPEN
      case 2:
        return (r) => r.stats === shared.status.ASSIGNED
      case 3:
        return (r) => r.status === shared.status.COMPLETED
      case 4:
        return (r) => r.status === shared.status.CLOSED
      default:
        break
    }
  }
  return (
    <Page>
      <Container maxWidth="lg">
        <SimpleTabs
          tabs={[
            { content: null, label: 'All' },
            { content: null, label: 'Open' },
            { content: null, label: 'Assigned' },
            { content: null, label: 'Completed' },
            { content: null, label: 'Closed' },
          ]}
          onChange={set_tab}
        />
        <RequestList
          statusFilter={getStatusFilter()}
          row_action={(r) => navigate(K.route.admin_request.href(r.id))}
        />
      </Container>
    </Page>
  )
}

export default Requests
