import React from 'react'
import { SimpleTable } from 'components/Basic'

const CargoItemTable = ({ cargo }) => {
  return (
    <SimpleTable
      fullWidth
      data={cargo}
      columns={[
        {
          header: 'Description',
          cell: r =>
            r.hazmat
              ? `${r.description} [HazMat: ${r.un_code}]`
              : r.description,
        },
        { header: 'Height (in)', cell: 'height', align: 'right' },
        { header: 'Width (in)', cell: 'width', align: 'right' },
        { header: 'Length (in)', cell: 'length', align: 'right' },
        { header: 'Weight (lbs)', cell: 'weight', align: 'right' },
        // { header: 'Luggage (lbs)', cell: 'luggage', align: 'right' },
      ]}
      summaryRow={
        cargo.length > 1
          ? [
              { cell: () => 'Totals' },
              {
                align: 'right',
                cell: data => data.reduce((a, c) => a + Number(c.height), 0),
              },
              {
                align: 'right',
                cell: data => data.reduce((a, c) => a + Number(c.width), 0),
              },
              {
                align: 'right',
                cell: data => data.reduce((a, c) => a + Number(c.length), 0),
              },
              {
                align: 'right',
                cell: data => data.reduce((a, c) => a + Number(c.weight), 0),
              },
              // {
              //   align: 'right',
              //   cell: data =>
              //     data.reduce((a, c) => a + Number(c.luggage), 0),
              // },
            ]
          : null
      }
    />
  )
}

export default CargoItemTable
