import { Api } from '@jesseteal/teal-react'

export const useAdminDashboard = () => {
  const results = Api.useGraphQuery(`query AdminDashboard{
    report_admin_dashboard{
      user_count
      provider_count
      total_request
      pending_request
      open_request
      assigned_request
      total_bid
      open_bid
      closed_bid
      accepted_bid
      completed_bid
      assigned_bid
      total_amount_bid
      open_amount_bid
      closed_amount_bid
      accepted_amount_bid
      completed_amount_bid
      assigned_amount_bid
    }
  }`)
  return {
    ...results,
    rows: results.data?.report_admin_dashboard,
  }
}
