import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Container } from '@material-ui/core'
import { Api, Hooks, Utils } from '@jesseteal/teal-react'
import Page from 'components/Page'
import { SimpleCard, SimpleGrid } from 'components/Basic'
import ContentDisplay from 'components/ContentDisplay'
import FlightDetail from './FlightDetail'
import ClientFlightForm from './ClientFlightForm'
import { useClientRequests } from 'shared/hooks'
import K from 'Constants'
import shared from 'shared/constants'

const ClientFlightHome = () => {
  const navigate = useNavigate()
  const [dismissed, set_dismissed] = Hooks.useContextState('dismissed', false)
  const [pay_enabled] = Hooks.useContextState('pay_enabled')
  const set_snack = Hooks.useSetContextState('snack')
  const [flight_type, set_flight_type] = React.useState(null)
  const [tab, set_tab] = React.useState('current')
  const saveRequest = Api.useSave('request')
  const savePassenger = Api.useSave('request_passenger')

  const id = Utils.token.get().sub
  const payment_is_setup = pay_enabled ? Utils.token.get().sid : true

  const { rows: requests, refetch } = useClientRequests({
    from_id: id,
    deleted_at: null,
    type: { neq: shared.flight_type.cargo },
  })

  const flights = (requests || []).filter((r) => {
    if (tab === 'current') {
      return r.start_date >= Utils.date.now()
    } else {
      return r.start_date < Utils.date.now()
    }
  })

  // const handleDelete = id => {
  //   saveRequest({ id, deleted_at: Utils.date.dbNow() }).then(() => refetch())
  // }

  const hideResults = flight_type || !requests?.length

  return (
    <Page>
      <Container maxWidth="lg">
        <SimpleGrid
          items={[
            {
              content: flight_type ? (
                <ClientFlightForm
                  type={flight_type}
                  cancel={() => set_flight_type(false)}
                  save={(values, passengers) => {
                    // console.log({ modified, values })
                    saveRequest(values, true).then(
                      async ({ createrequest: request_id }) => {
                        for (var p of passengers) {
                          await savePassenger({ ...p, request_id }, true)
                        }
                        // console.log('aftersave', request_id)
                        set_flight_type(false)
                        refetch()
                      }
                    )
                  }}
                />
              ) : (
                <ContentDisplay
                  id={shared.content.charter_a_flight}
                  headeraction={{
                    onClick: () => {
                      if (!payment_is_setup) {
                        if (!dismissed) {
                          set_snack(K.snack.payment_required)
                        }
                        set_dismissed(false)
                      } else {
                        set_flight_type(shared.flight_type.charter)
                      }
                    },
                    icon: <K.icon.charter_a_flight />,
                  }}
                />
              ),
              size: flight_type ? 12 : 6,
            },
            {
              content: flight_type ? null : (
                <ContentDisplay
                  id={shared.content.book_a_flight}
                  headeraction={{
                    onClick: () => {
                      if (!payment_is_setup) {
                        if (!dismissed) {
                          set_snack(K.snack.payment_required)
                        }
                        set_dismissed(false)
                      } else {
                        set_flight_type(shared.flight_type.passenger)
                      }
                    },
                    icon: <K.icon.find_a_flight />,
                  }}
                />
              ),
              size: 6,
            },
            {
              content: hideResults ? null : (
                <SimpleCard
                  header="Flights"
                  tabs={[
                    {
                      label: 'Upcoming Flights',
                      onClick: () => set_tab('current'),
                      active: tab === 'current',
                    },
                    {
                      label: 'Past Flights',
                      onClick: () => set_tab('past'),
                      active: tab === 'past',
                    },
                  ]}
                >
                  {flights.length === 0 && <Box>No data available.</Box>}
                  <SimpleGrid
                    items={flights.map((r) => ({
                      content: (
                        <FlightDetail
                          request={r}
                          // actionsRight={[
                          //   {
                          //     icon: <K.icon.delete color={shared.color.delete} />,
                          //     onClick: () => onDelete(request.id),
                          //   },
                          // ]}
                          actionsLeft={[
                            {
                              label: 'View',
                              icon:
                                r.type === shared.flight_type.charter ? (
                                  <K.icon.charter_a_flight />
                                ) : (
                                  <K.icon.find_a_flight />
                                ),
                              onClick: () =>
                                navigate(K.route.flight.href(r.id)),
                            },
                          ]}
                        />
                      ),
                      size: 12,
                    }))}
                  />
                </SimpleCard>
              ),
              size: 6,
            },
            // {
            //   content: hideResults ? null : (
            //     <ContentDisplay id={shared.content.preflight_guide} />
            //   ),
            //   size: 6,
            // },
          ]}
        />
      </Container>
    </Page>
  )
}

export default ClientFlightHome
