// import { LABELS } from 'helpers/constants/Text';
// import STATES from './FieldDefStates';

export class FieldDef {
  /*
  goal:  return an object like this:
  {
    label: "Field X",
    name: field_x,
    lookup: field_x, // optional, if is lookup
    multiline: 5,    // optional, for textarea
    validate: {       // optional
      required: true, // optional
      email: true,    // optional
      date: true,     // optional
    }
  }
   */
  constructor(props) {
    this.def = {
      sort: true,
    }
  }

  get(size) {
    if (size) {
      return {
        ...this.def,
        size,
      }
    }
    return this.def
  }

  // resetters
  column(columnDef) {
    /*
      columnDef:
      {
        size: 12,
        fields: [
          __FieldDefValues__
        ]
      }
     */
    this.def = {
      column: columnDef,
    }
    return this.def
  }
  content(children) {
    this.def = { children }
    return this
  }
  header(txt) {
    this.def = { header: txt }
    return this
  }
  name(name) {
    this.def = {
      // label: LABELS[name],
      name,
    }
    return this
  }

  // --------------------------------------------------
  // adders
  // --------------------------------------------------
  append(txt) {
    this.def = { ...this.def, append: txt }
    return this
  }

  bool(boolExtra) {
    this.def = {
      ...this.def,
      bool: true,
      boolExtra, // appended after label
    }
    return this
  }

  className(className) {
    this.def = { ...this.def, className }
    return this
  }

  clearable(fn) {
    this.def = { ...this.def, clearable: fn }
    return this
  }

  confirm() {
    this.def = {
      ...this.def,
      password: true,
      validate: {
        ...this.def.validate,
        confirm: true,
      },
    }
    return this
  }

  date(dateConfig) {
    this.def = {
      ...this.def,
      ...dateConfig,
      date: true,
    }
    return this
  }

  disabled(disabled = true) {
    this.def = {
      ...this.def,
      disabled: disabled,
    }
    return this
  }

  email() {
    this.def = {
      ...this.def,
      validate: {
        ...this.def.validate,
        email: true,
      },
    }
    return this
  }

  foreignKey(config) {
    // { table, condition, filter, onChange }
    this.def = {
      ...this.def,
      foreignKey: config,
    }
    return this
  }

  format(fmt, message) {
    this.def = {
      ...this.def,
      validate: {
        ...this.def.validate,
        format: fmt,
        message: message || ' must be 9 digits (xx-xxxxxxx)',
      },
    }
    return this
  }

  hide(fn) {
    this.def.hide = fn
    return this
  }

  label(label) {
    this.def = { ...this.def, label }
    return this
  }

  legend(legend) {
    this.def = { ...this.def, legend }
    return this
  }

  lines(multiline) {
    this.def = {
      ...this.def,
      multiline,
    }
    return this
  }

  max(len) {
    return this
  }

  money() {
    this.def = {
      ...this.def,
      money: true,
      prepend: '$',
      validate: {
        ...this.def.validate,
        numericality: true,
      },
    }
    return this
  }

  number() {
    this.def = {
      ...this.def,
      number: true,
      validate: {
        ...this.def.validate,
        numericality: true,
      },
    }
    return this
  }

  password() {
    this.def = {
      ...this.def,
      password: true,
    }
    return this
  }

  phone() {
    this.def = {
      ...this.def,
      phone: true,
      validate: {
        ...this.def.validate,
        format: /\(?\d{3}\)?(\s\d{3})?-\d{4}/,
        message: ' must be a valid phone format',
      },
    }
    return this
  }

  prepend(txt) {
    this.def = { ...this.def, prepend: txt }
    return this
  }

  readOnly() {
    this.def = {
      ...this.def,
      readOnly: true,
    }
    return this
  }

  required(message) {
    this.def = {
      ...this.def,
      validate: {
        ...this.def.validate,
        required: true,
        message,
      },
    }
    return this
  }

  select(options, onChange) {
    this.def = {
      ...this.def,
      options,
      onChange,
    }
    return this
  }

  sort(b) {
    this.def = {
      ...this.def,
      sort: b,
    }
    return this
  }

  time() {
    this.def = {
      ...this.def,
      time: true,
    }
    return this
  }

  unique(table) {
    this.def = {
      ...this.def,
      validate: {
        ...this.def.validate,
        unique: table,
      },
    }
    return this
  }

  // pass custom validation function, return error message if invalid
  validate(custom) {
    this.def = {
      ...this.def,
      validate: {
        custom,
      },
    }
    return this
  }
}

export default new FieldDef()
