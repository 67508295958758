import React from 'react'
import { Navigate } from 'react-router-dom'
import UserLayout from 'layouts/UserLayout'
import CalendarView from 'pages/calendar/CalendarView'
import PrivacyPolicy from 'pages/home/PrivacyPolicy'
// import CustomerListView from 'devias/views/customer/CustomerListView';
// import DashboardView from 'devias/views/reports/DashboardView';
import NotFoundView from 'pages/errors/NotFoundView'
// import ProductListView from 'devias/views/product/ProductListView';
// import SettingsView from 'devias/views/settings/SettingsView';
// public
import Home from 'pages/home/Home'
// auth
import LoginView from 'pages/auth/LoginView'
import Logout from 'pages/auth/Logout'
import RegisterView from 'pages/auth/RegisterView'
import RegisterProvider from 'pages/auth/RegisterProvider'
import RegisterReceived from 'pages/auth/RegisterReceived'
import RegisterProfile from 'pages/auth/RegisterProfile'
import PasswordReset from 'pages/auth/PasswordReset'
import ForgotPassword from 'pages/auth/ForgotPassword'
// account
// import PaymentSetup from 'pages/account/PaymentSetup/index'
import Profile from 'pages/account/Profile'
import MessageOverview from 'pages/MessageOverview'
// Requests
import CargoList from 'pages/requests/CargoList'
import CargoDetail from 'pages/requests/CargoDetail'
import FlightList from 'pages/requests/FlightList'
import FlightDetail from 'pages/requests/FlightDetail'

// ADMIN
import AdminDashboard from 'pages/admin/AdminDashboard'
import ManageAirports from 'pages/admin/ManageAirports'
// import AirportForm from 'pages/admin/AirportForm';
import ManageUsers from 'pages/admin/ManageUsers'
import ManageUser from 'pages/admin/ManageUser'
import ManageClientUsers from 'pages/admin/ManageClientUsers'
import ManageContent from 'pages/admin/ManageContent'
import ManageSettings from 'pages/admin/ManageSettings'
import AdminTools from 'pages/admin/AdminTools'
import Requests from './pages/admin/Requests'
import AdminRequestDetail from './pages/admin/RequestDetail'
import K from 'Constants'
import constants from 'shared/constants'

const routes = [
  {
    path: '/',
    element: <UserLayout />,

    children: [
      // Shipments
      { path: K.route.shipments.href, element: <CargoList /> },
      { path: K.route.shipment.template, element: <CargoDetail /> },
      // Flights
      { path: K.route.flights.href, element: <FlightList /> },
      { path: K.route.flight.template, element: <FlightDetail /> },
      // Bids
      { path: K.route.bid.template, element: <CargoDetail /> },
      // CalendarView
      { path: K.route.calendar.href, element: <CalendarView /> },
      // Messages
      { path: K.route.messages.href, element: <MessageOverview /> },
      // User Profile
      { path: K.route.profile.href, element: <Profile /> },
      // --------------------------
      // Guest routes
      // --------------------------
      { path: K.route.home.href, element: <Home /> },
      { path: K.route.unknown.href, element: <NotFoundView /> },
      { path: K.route.login.href, element: <LoginView /> },
      { path: K.route.logout.href, element: <Logout /> },
      { path: K.route.register.href, element: <RegisterView /> },
      { path: K.route.register_provider.href, element: <RegisterProvider /> },
      {
        path: K.route.register_provider_ty.href,
        element: <RegisterReceived />,
      },
      {
        path: K.route.register_provider_profile.href,
        element: <RegisterProfile />,
      },
      { path: K.route.forgot.href, element: <ForgotPassword /> },
      {
        path: 'passwordreset/:emailkey/:loginkey/:email',
        element: <PasswordReset />,
      },
      { path: 'privacy', element: <PrivacyPolicy /> },
      { path: '*', element: <Navigate to="404" /> },
    ],
  },
  {
    path: '/admin',
    role: 'Admin',
    element: <UserLayout role={constants.role.admin} />,
    children: [
      { path: 'airports', element: <ManageAirports /> },
      { path: 'calendar', element: <CalendarView /> },
      { path: 'home', element: <AdminDashboard /> },
      { path: 'requests', element: <Requests /> },
      { path: 'request/:id', element: <AdminRequestDetail /> },
      // { path: 'airport', element: <AirportForm /> },
      { path: 'users', element: <ManageUsers /> },
      { path: 'user/:id', element: <ManageUser /> },
      { path: 'clients', element: <ManageClientUsers /> },
      { path: 'content', element: <ManageContent /> },
      { path: 'settings', element: <ManageSettings /> },
      { path: 'tools', element: <AdminTools /> },
    ],
  },
]

export default routes
