import React from 'react'
import { Beenhere, Check, Label, NewReleases } from '@material-ui/icons'
import constants from 'shared/constants'
import Typo from 'components/Typo'

// const status_color = status => {
//   switch (status) {
//     case constants.status.OPEN:
//       return constants.color.STATUS_OPEN;
//     case constants.status.PENDING:
//       return "orange";
//     case constants.status.ASSIGNED:
//       return "green";
//     default:
//       return "gray";
//   }
// };

const status_icon = (status) => {
  switch (status) {
    case constants.status.OPEN:
      return <NewReleases style={{ color: constants.color.STATUS_OPEN }} />
    case constants.status.PENDING:
      return <Label style={{ color: constants.color.STATUS_PENDING }} />
    case constants.status.ASSIGNED:
      return <Check style={{ color: constants.color.STATUS_ASSIGNED }} />
    default:
      return <Beenhere style={{ color: constants.color.STATUS_ASSIGNED }} />
  }
}

const RequestStatus = ({ request }) => {
  return (
    <div className="row">
      <div className="col_xs pr1">{status_icon(request.status)}</div>
      <div className="col_lg">{Typo.header(request.status)}</div>
    </div>
  )
}

export default RequestStatus
