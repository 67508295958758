import React from 'react'
import { Container } from '@material-ui/core'
import { Api } from '@jesseteal/teal-react'
import Page from 'components/Page'
import SimpleTable from 'components/Basic/SimpleTable'
import Loading from 'components/Loading'
import ContentForm from './components/ContentForm'
import shared from 'shared/constants'
import { useAllContent } from 'shared/hooks'

const ManageContent = () => {
  const [edit, set_edit] = React.useState(null)
  const { rows, refetch } = useAllContent()
  const save = Api.useSave('content')
  if (!rows) {
    return <Loading />
  }
  return (
    <Page title="Manage Content">
      <Container maxWidth="lg">
        {edit ? (
          <ContentForm
            content={edit}
            save={(values) => {
              save(values)
              refetch()
              set_edit(null)
            }}
            cancel={() => set_edit(null)}
          />
        ) : (
          <SimpleTable
            fullWidth
            header={shared.label.admin_content.header}
            subheader={shared.label.admin_content.subheader}
            data={rows}
            columns={[
              { header: 'Title' },
              { header: 'Header' },
              { header: 'Subheader' },
              { action: set_edit },
            ]}
          />
        )}
      </Container>
    </Page>
  )
}

export default ManageContent
