import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Container } from '@material-ui/core'
import { Utils } from '@jesseteal/teal-react'
import SimpleCard from 'components/Basic/SimpleCard'
import Page from 'components/Page'
import K from 'Constants'

/**
 * DEPRECATED?
 */
const AdminTools = () => {
  const navigate = useNavigate()
  return (
    <Page title="Admin Tools">
      <Container maxWidth="lg">
        <SimpleCard
          header="Testing Tools"
          subheader="To undo Impersonation, you must login again"
        >
          <Box mb={2}>
            <Button
              color="primary"
              onClick={() => {
                let t = Utils.token.get()
                t.roles = ['Admin', 'Provider']
                Utils.token._overwrite(t)
                navigate(K.route.home.Guest)
              }}
            >
              <K.icon.flights className="mr2" /> Impersonate Carrier
            </Button>
          </Box>
          <Button
            color="primary"
            onClick={() => {
              let t = Utils.token.get()
              t.roles = ['Admin', 'User']
              Utils.token._overwrite(t)
              navigate(K.route.home.User)
            }}
          >
            <K.icon.package className="mr2" />
            Impersonate Client
          </Button>
        </SimpleCard>
      </Container>
    </Page>
  )
}

export default AdminTools
