import icon from './K_icon'
import { PK } from '../utils'
/*
Admin: ,
Guest:,
Provider:,
User:,
*/
export default {
  // Auth
  login: {
    href: '/login',
    label: 'Sign In',
  },
  logout: {
    href: '/logout',
  },
  forgot: {
    href: '/reset',
    label: 'Reset Password',
  },
  register: {
    href: '/register',
    label: 'Sign up',
    icon: null,
  },
  register_provider: {
    href: '/register-carrier',
    label: 'Register Now',
  },
  register_provider_ty: {
    href: '/register-received',
  },
  register_provider_profile: {
    href: '/register-profile',
  },
  unknown: {
    href: '/404',
  },
  // Basic
  root: {
    Admin: '/',
    Guest: '/',
    Provider: '/',
    User: '/',
  },
  home: {
    Admin: '/admin/home',
    Guest: '/',
    Provider: '/shipments',
    User: '/shipments',
  },
  topbar_keys: {
    Admin: [
      // 'admin',
      'admin_requests',
      'admin_calendar',
      'admin_airports',
      'admin_users',
      'admin_content',
      'admin_settings',
    ],
    Guest: ['about'],
    Provider: ['shipments', 'flights', 'calendar', 'profile'],
    User: [
      'shipments',
      'flights',
      'calendar',
      'profile',
      // , 'settings'
    ],
  },
  about: { label: 'About Us', href: '/' },
  // SECTIONS
  account: {
    label: 'Account',
    href: '/account',
  },
  admin: {
    label: 'Dashboard',
    href: '/admin/home',
  },
  admin_airports: {
    label: 'Airports',
    icon: icon.airport,
    href: '/admin/airports',
  },
  admin_request: {
    href: (id) => `/admin/request/${PK.obscure(id)}`,
  },
  admin_requests: {
    label: 'Requests',
    icon: icon.requests,
    href: '/admin/requests',
  },
  admin_users: {
    label: 'Users',
    icon: icon.users,
    href: '/admin/users',
  },
  admin_user: {
    href: (id) => `/admin/user/${PK.obscure(id)}`,
  },
  admin_calendar: {
    label: 'Calendar',
    href: '/admin/calendar',
    icon: icon.calendar,
  },
  admin_content: {
    label: 'Content',
    href: '/admin/content',
    icon: icon.content,
  },
  admin_settings: {
    label: 'Settings',
    icon: icon.settings,
    href: '/admin/settings',
  },
  admin_tools: {
    label: 'Tools',
    icon: icon.user,
    href: '/admin/tools',
  },
  bid: {
    href: (id) => `/quote/${PK.obscure(id)}`,
    template: `/quote/:id`,
  },
  calendar: {
    href: '/calendar',
    icon: icon.calendar,
    label: 'Calendar',
  },
  flights: {
    label: 'Flights',
    href: '/flights',
    icon: icon.flights,
  },
  flight: {
    href: (id) => `/flight/${PK.obscure(id)}`,
    template: '/flight/:id',
  },
  messages: {
    label: 'Messages',
    icon: icon.messages,
    href: '/messages',
  },
  password: {
    header: 'Change Password',
  },
  payment: {
    label: 'Payment Setup',
    icon: icon.payment,
    href: '/profile?t=1',
  },
  profile: {
    label: 'Profile',
    icon: icon.user,
    href: '/profile',
  },
  settings: {
    label: 'Settings',
    icon: icon.settings,
    href: '/profile?t=3',
  },
  shipments: {
    label: 'Shipments',
    href: '/shipments',
    icon: icon.package,
  },
  shipment: {
    label: 'Shipment',
    href: (id) => `/shipment/${PK.obscure(id)}`,
    template: '/shipment/:id',
    icon: icon.package,
  },
}
