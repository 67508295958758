// import React from 'react'
// import { Link } from 'react-router-dom'
// import { Box } from '@material-ui/core'
// import Alert from '@material-ui/lab/Alert'
import { Hooks, Utils } from '@jesseteal/teal-react'
// import K from 'Constants'

const PaymentSetupNotification = () => {
  const [
    dismissed,
    // set_dismissed
  ] = Hooks.useContextState('dismissed', false)
  // const navigate = useNavigate()
  if (dismissed || !Utils.token.get().sub || Utils.token.get().sid) {
    return null
  }
  return null // payment disabled on initial launch
  // return (
  //   <Box m={2}>
  //     <Alert severity="warning" onClose={() => set_dismissed(true)}>
  //       Payment information is required to complete a transaction. Please go to
  //       your <Link to={K.route.payment.href}>Settings</Link> page and follow the
  //       steps.
  //     </Alert>
  //   </Box>
  // )
}

export default PaymentSetupNotification
