import React from 'react'
import { SimpleCard } from 'components/Basic'
import { useProviderInfo } from '../shared/hooks'
import shared from '../shared/constants'
import T from '../components/Typo'

const ProviderResponseMessage = ({ bid, ...props }) => {
  const { info } = useProviderInfo({ id: bid.provider_id })
  return (
    <>
      {info?.response_accept ? (
        <SimpleCard {...props}>{T.body(info?.response_accept)}</SimpleCard>
      ) : null}
      {info?.response_dropoff ? (
        <SimpleCard {...props} header={shared.label.dropoffInstructions}>
          {T.body(info?.response_dropoff)}
        </SimpleCard>
      ) : null}
    </>
  )
}

export default ProviderResponseMessage
