import { Api } from '@jesseteal/teal-react'

export const useContent = (id) => {
  const results = Api.useGraphQuery(
    `query Content($where:String!){
    content(where: $where){
      id title header subheader content
    }
  }`,
    { skip: !id, variables: { where: JSON.stringify({ id }) } }
  )
  return {
    ...results,
    content: results.data?.content?.[0],
  }
}

export const useAllContent = () => {
  const results = Api.useGraphQuery(
    `query ManageContent{ content { id title header subheader content } }`
  )
  return {
    ...results,
    rows: results.data?.content,
  }
}
