import React from 'react'
import { SimpleCard } from 'components/Basic'
import { Row, Col, SimpleTable } from 'components/Basic'
import { Utils } from '@jesseteal/teal-react'
import T from 'components/Typo'
import shared from 'shared/constants'
import { isProvider } from 'utils/role'

const FlightDetail = (props) => {
  const { request, ...other } = props
  const is_provider = isProvider()
  return (
    <SimpleCard {...other} header={`${request.type} Flight`}>
      <Row mb={2}>
        <Col>
          {T.label_value_stack('From', request.from_airport_id_airport.name)}
        </Col>
        <Col>
          {T.label_value_stack('To', request.to_airport_id_airport.name)}
        </Col>
      </Row>
      <Row>
        <Col>
          {T.label_value_stack('Date', Utils.date.format(request.start_date))}
        </Col>
        <Col>{T.label_value_stack('Status', request.status)}</Col>
        <Col>
          {T.label_value_stack(
            shared.label.cargo.identifier,
            shared.label.cargo.identifier_value(request)
          )}
        </Col>
      </Row>

      <div className="mt2 mb1">{T.header('Passengers')}</div>

      {is_provider ? (
        <SimpleTable
          columns={[
            { header: 'Name', cell: (p) => `${p.firstname} ${p.lastname}` },
            { header: 'Weight (lbs)', cell: 'weight', align: 'right' },
            { header: 'Luggage (lbs)', cell: 'luggage', align: 'right' },
            {
              header: 'Total (lbs)',
              cell: (r) => r.weight + r.luggage,
              align: 'right',
            },
          ]}
          data={request.request_passenger}
          summaryRow={
            request.request_passenger.length > 1
              ? [
                  { cell: () => 'Totals' },
                  {
                    align: 'right',
                    cell: (data) =>
                      data.reduce((a, c) => a + Number(c.weight), 0),
                  },
                  {
                    align: 'right',
                    cell: (data) =>
                      data.reduce((a, c) => a + Number(c.luggage), 0),
                  },
                  {
                    align: 'right',
                    cell: (data) =>
                      data.reduce(
                        (a, c) => a + Number(c.weight) + Number(c.luggage),
                        0
                      ),
                  },
                ]
              : null
          }
        />
      ) : (
        request.request_passenger.map((p, i) =>
          T.label_value_justified(
            `#${i + 1}`,
            `${p.firstname} ${p.lastname}`,
            i
          )
        )
      )}
    </SimpleCard>
  )
}

export default FlightDetail
