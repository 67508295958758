import React from 'react'
import { Utils } from '@jesseteal/teal-react'
import Loading from 'components/Loading'
import { SimpleCard, SimpleGrid } from 'components/Basic'
import PhotoManager from 'components/PhotoManager'
import T from 'components/Typo'
import { useRequest } from 'shared/hooks'
import shared from 'shared/constants'
import CargoItemTable from './CargoItemTable'
import { isUser } from 'utils/role'

const CargoDetail = ({ id, bid = null, ...props }) => {
  const { request: model } = useRequest(id)
  if (!model) {
    return <Loading />
  }
  const items = [
    {
      size: 6,
      content: T.label_value_stack(
        shared.label.cargo.from_airport_id,
        model.from_airport_id_airport.name
      ),
    },
    {
      size: 6,
      content: T.label_value_stack(
        shared.label.cargo.to_airport_id,
        model.to_airport_id_airport.name
      ),
    },
    {
      size: 4,
      content: T.label_value_stack(
        shared.label.cargo.start_date,
        Utils.date.format(model.start_date)
      ),
    },
    {
      size: 4,
      content: T.label_value_stack(
        shared.label.cargo.identifier,
        shared.label.cargo.identifier_value(model)
      ),
    },
  ]

  if (model?.request_cargo.length) {
    items.push({
      content: T.header(shared.label.cargo.packages_section_header),
    })
    items.push({
      content: <CargoItemTable cargo={model.request_cargo} />,
    })
  }
  // if (model?.request_image?.length) {
  items.push({
    content: <PhotoManager request={model} editable={isUser()} />,
  })
  // }

  return (
    <SimpleCard
      header={shared.label.cargo.view_header}
      subheader={shared.label.cargo.view_subheader}
      {...props}
    >
      <SimpleGrid items={items} />
    </SimpleCard>
  )
}

export default CargoDetail
