import React from 'react';
import SimpleCard from 'components/Basic/SimpleCard';
import SimpleForm from 'components/Basic/SimpleForm';
import SimpleGrid from 'components/Basic/SimpleGrid';
import FieldDef from 'components/Basic/FieldDef';
import ContentDisplay from 'components/ContentDisplay';

// import K from 'Constants';

const ContentForm = ({ content, save, cancel }) => {
  const [local, set_local] = React.useState(content);
  return (
    <SimpleGrid
      items={[
        { content: (
          <SimpleForm
            header={content.title}
            model={content}
            save={modified => {
              save({...modified, id: content.id});
            }}
            onChange={changes => {
              set_local({...local,...changes})
            }}
            cancel={cancel}
            fields={[
              FieldDef.name('header').label('Header').get(),
              FieldDef.name('subheader').label('Subheader').get(),
              FieldDef.name('content').label('Content').lines(10).required().get(),
            ]}
          />
        ), size: 6 },
        { content: (
          <SimpleCard header="Preview">
            <ContentDisplay content={local.content} />
          </SimpleCard>
        ), size: 6 },
      ]}
    />
  );
};

export default ContentForm;
