import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Container } from '@material-ui/core'
import { Utils } from '@jesseteal/teal-react'
import Page from 'components/Page'
import ContentDisplay from 'components/ContentDisplay'
import K from 'Constants'
import shared from 'shared/constants'
import { baseRole } from 'utils/role'

const PrivacyPolicy = () => {
  const navigate = useNavigate()
  const is_auth = !!Utils.token.get().sub
  return (
    <Page>
      <Container maxWidth="lg">
        <Box py={2}>
          <ContentDisplay
            id={shared.content.privacy_policy}
            actionsRight={
              is_auth
                ? [
                    {
                      label: 'Continue',
                      onClick: () => navigate(K.route.home[baseRole()]),
                    },
                  ]
                : null
            }
          />
        </Box>
      </Container>
    </Page>
  )
}

export default PrivacyPolicy
