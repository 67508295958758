import React from 'react'
import { Container } from '@material-ui/core'
import { Api } from '@jesseteal/teal-react'
import Page from 'components/Page'
import SimpleTable from 'components/Basic/SimpleTable'
import Loading from 'components/Loading'
import SettingForm from './components/SettingForm'
import shared from 'shared/constants'
import { useSettings } from 'shared/hooks'

const ManageSettings = () => {
  const [edit, set_edit] = React.useState(null)
  const { rows } = useSettings()
  const save = Api.useSave('setting')
  if (!rows) {
    return <Loading />
  }

  return (
    <Page title="Manage Settings">
      <Container maxWidth="lg">
        {edit ? (
          <SettingForm
            setting={edit}
            save={(values) => {
              save(values)
              set_edit(null)
            }}
            cancel={() => set_edit(null)}
          />
        ) : (
          <SimpleTable
            fullWidth
            header={shared.label.admin_settings.header}
            subheader={shared.label.admin_settings.subheader}
            data={rows}
            columns={[
              { header: 'Title' },
              { header: 'Setting' },
              { action: set_edit },
            ]}
          />
        )}
      </Container>
    </Page>
  )
}

export default ManageSettings
