import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Fab, useMediaQuery } from '@material-ui/core'
import { Utils } from '@jesseteal/teal-react'
import K from 'Constants'
import shared from 'shared/constants'
import { baseRole } from 'utils/role'

const TopBarLinks = () => {
  const [token, set_token] = React.useState(Utils.token.get())
  const navigate = useNavigate()
  const lgScreen = useMediaQuery('(min-width:1000px)')
  const loc = useLocation()
  const is_guest = !token.roles
  const block_links = token?.pst === 'Approved'
  const color = (to) => {
    if (loc.pathname === to) {
      return 'secondary'
    }
    return 'primary'
  }

  React.useEffect(() => {
    set_token(Utils.token.get())
    // eslint-disable-next-line
  }, [JSON.stringify(Utils.token.get())])

  const menu = (K.route.topbar_keys[baseRole() || 'Guest'] || [])
    .filter((k) => {
      if (block_links) {
        return false
      }
      if (token.t === shared.flight_type.cargo && k === 'flights') {
        return false
      }
      if (token.t === shared.flight_type.passenger && k === 'shipments') {
        // TODO: fix magic strings
        return false
      }
      return true
    })
    .map((k) => K.route[k])
  return (
    <Box flexGrow={1} className="pl2">
      {!is_guest &&
        menu.map((m, i) => (
          <Fab
            key={i}
            title={m.label}
            variant="extended"
            size={'small'}
            color={color(m.href)}
            onClick={() => navigate(m.href)}
            style={{
              justifyContent: 'center',
            }}
          >
            {m.icon && <m.icon className={lgScreen ? 'mr1' : ''} size={18} />}
            {lgScreen && m.label}
          </Fab>
        ))}
    </Box>
  )
}

export default TopBarLinks
