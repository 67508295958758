import React from 'react'
import RoleRoute from 'components/RoleRoute'
import AdminCalendarView from './AdminCalendarView'
import ClientCalendarView from './ClientCalendarView'
import ProviderCalendarView from './ProviderCalendarView'
import shared from 'shared/constants'

const CalendarView = () => {
  return (
    <RoleRoute
      routes={{
        [shared.role.admin]: <AdminCalendarView />,
        [shared.role.user]: <ClientCalendarView />,
        [shared.role.provider]: <ProviderCalendarView />,
      }}
    />
  )
}

export default CalendarView
