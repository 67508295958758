import React from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Button,
} from '@material-ui/core'
import DeleteButton from './SimpleDeleteConfirm'
/*
<SimpleDialog
  open={open}
  title=""
  actionsRight={[ { label: "", onClick: () => {}, color: "primary"}]}
  onClose={}>
  content
</SimpleDialog>
 */
const SimpleDialog = ({
  title,
  children,
  actionsLeft,
  actionsRight,
  open,
  onClose,
  size = 'md',
  noGutter = false,
}) => {
  const handleClose = fn => {
    if (fn) {
      if (fn() !== false) {
        onClose()
      }
    } else {
      onClose()
    }
  }
  const Wrap = noGutter ? Box : DialogContent
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={size}>
      {title && (
        <>
          <DialogTitle>{title}</DialogTitle>
          <Divider />
        </>
      )}
      <Wrap>{children}</Wrap>
      {(actionsLeft || actionsRight) && (
        <>
          <Divider />
          <DialogActions>
            {actionsLeft &&
              actionsLeft.map((t, i) => (
                <Button
                  key={i}
                  onClick={() => handleClose(t.onClick)}
                  color={t.color || 'default'}
                >
                  {t.label}
                </Button>
              ))}
            <div className="flex-grow" />
            {actionsRight &&
              actionsRight.map((t, i) => {
                if (t.confirm) {
                  return (
                    <DeleteButton
                      onClick={t.onClick}
                      label={t.label}
                      color="error"
                    />
                  )
                }
                return (
                  <Button
                    key={i}
                    onClick={() => handleClose(t.onClick)}
                    color={t.color || 'default'}
                  >
                    {t.label}
                  </Button>
                )
              })}
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default SimpleDialog
