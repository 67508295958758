import { Api } from '@jesseteal/teal-react';
import { schema } from '../schema';
import constants from '../constants';

export const useSetMessagesViewed = () => {
  const [mutate] = Api.useMutation(`
    mutation setMessagesViewed($bid_id: Int!, $viewer_id: Int!) {
      setMessagesViewed(bid_id: $bid_id, viewer_id: $viewer_id)
    }`);
  return mutate;
};

export const useMessagesForProvider = (provider_id) => {
  const results = Api.useGraphQuery(
    `query getMessagesForProvider($where:String){
      bid(where:$where){
        ${schema['bid']}
        message(order:"created_at desc"){
          ${schema['message']}
          from_id_user{
            id firstname lastname
          }
          to_id_user{
            id firstname lastname
          }
        }
        request_id_request{
          id
          from_id
          start_date
          status
          type
          from_airport_id_airport{
            id name code
          }
          to_airport_id_airport{
            id name code
          }
        }
        provider_id_user{
          id firstname lastname
        }
      }
    }`,
    {
      networkOnly: true,
      variables: {
        where: JSON.stringify({
          status: { neq: constants.status.CLOSED },
          provider_id,
        }),
      },
    },
  );
  return {
    ...results,
    rows: results.data?.bid,
  };
};

export const useMessagesForClient = (client_id) => {
  const results = Api.useGraphQuery(
    `query MessageList_Client($where:String,$whereBid:String){
      request(where:$where){
        id type
        bid(where:$whereBid){
          ${schema['bid']}
          message(order:"created_at desc"){
            ${schema['message']}
            from_id_user{
              id firstname lastname
            }
            to_id_user{
              id firstname lastname
            }
          }
          request_id_request{
            id
            from_id
            start_date
            status
            type
            from_airport_id_airport{
              id name code
            }
            to_airport_id_airport{
              id name code
            }
          }
        }
      }
    }`,
    {
      networkOnly: true,
      variables: {
        where: JSON.stringify({
          from_id: client_id,
          status: {
            AND: [
              { status: { neq: constants.status.CLOSED } },
              { status: { neq: constants.status.COMPLETED } },
            ],
          },
        }),
        whereBid: JSON.stringify({
          status: { neq: constants.status.CLOSED },
        }),
      },
    },
  );

  return {
    ...results,
    rows: results.data?.request,
  };
};

export const useMessagesForBid = (bid_id) => {
  const results = Api.useGraphQuery(
    `query Messages($where:String){
      message(where:$where, order: "created_at desc"){
        ${schema['message']}
        from_id_user{
          id firstname lastname
        }
      }
    }`,
    {
      networkOnly: true,
      variables: {
        where: JSON.stringify({ bid_id }),
      },
    },
  );
  return {
    ...results,
    rows: results.data?.message,
  };
};

export const useMessagesForBidToUser = (bid_id, to_id) => {
  const results = Api.useGraphQuery(
    `query Messages($where:String,$whereUser:String){
      message(where:$where, order: "created_at desc"){
        ${schema['message']}
        from_id_user{
          id firstname lastname
        }
      }
      user(where:$whereUser){
        id firstname lastname
      }
    }`,
    {
      networkOnly: true,
      variables: {
        where: JSON.stringify({
          bid_id,
        }),
        whereUser: JSON.stringify({
          id: to_id,
        }),
      },
    },
  );
  return {
    ...results,
    rows: results.data?.message,
  };
};
