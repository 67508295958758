import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import FlyField from 'components/Basic/FlyField'
import SimpleCard from 'components/Basic/SimpleCard'
import SimpleGrid from 'components/Basic/SimpleGrid'
import { Container } from '@material-ui/core'
import K from 'Constants'
import shared from 'shared/constants'

const AirportForm = ({ model, save, cancel, onDelete }) => {
  return (
    <Formik
      initialValues={model}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Name is required'),
        // latitude: Yup.number().required('Latitude is required'),
        // longitude: Yup.number().required('Longitude is required'),
      })}
      onSubmit={(values, opt) => {
        save(values)
      }}
    >
      {(props) => {
        return (
          <Container maxWidth="sm">
            <form onSubmit={props.handleSubmit}>
              <SimpleCard
                header={shared.label.admin_airports.form_header}
                subheader={shared.label.admin_airports.form_subheader}
                headeraction={{
                  icon: <K.icon.remove />,
                  onClick: cancel,
                }}
                actionsLeft={[
                  {
                    icon: (
                      <K.icon.delete
                        className="mr1"
                        color={shared.color.delete}
                      />
                    ),
                    label: (
                      <span style={{ color: shared.color.delete }}>Delete</span>
                    ),
                    onClick: onDelete,
                  },
                ]}
                actionsRight={[
                  {
                    label: (
                      <>
                        <K.icon.save className="mr1" /> Save
                      </>
                    ),
                    onClick: props.handleSubmit,
                    disabled: () => !props.dirty,
                  },
                ]}
              >
                <SimpleGrid
                  items={[
                    {
                      content: (
                        <FlyField name="name" label="Airport Name" {...props} />
                      ),
                    },
                    {
                      content: <FlyField name="code" label="Code" {...props} />,
                    },
                    // { content: <FlyField name="latitude" label="Latitude" type="number" {...props} /> },
                    // { content: <FlyField name="longitude" label="Longitude" type="number" {...props} /> },
                  ]}
                />
              </SimpleCard>
            </form>
          </Container>
        )
      }}
    </Formik>
  )
}

export default AirportForm
