import React from "react";
import { useNavigate } from "react-router-dom";
import { Utils } from '@jesseteal/teal-react';

const Logout = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    Utils.token.set(null);
    navigate("/");
  }, [navigate]);
  return null;
};

export default Logout;
