import React from 'react'
import { Utils } from '@jesseteal/teal-react'
import { SimpleTable, Row, Col } from 'components/Basic'
import shared from 'shared/constants'
import T from 'components/Typo'
import { useMessagesForBid } from 'shared/hooks'

const FormattedMessage = ({ message }) => {
  return (
    <div>
      <Row>
        <Col>
          {T.caption(
            `${message.from_id_user.firstname} ${message.from_id_user.lastname}`
          )}
        </Col>
        <Col className="tr">
          {T.caption(Utils.date.format(message.created_at, 'MMM d h:mm a'))}
        </Col>
      </Row>
      <Row>{message.message}</Row>
    </div>
  )
}

const AdminMessages = (props) => {
  const { bid_id, ...other } = props
  const { rows } = useMessagesForBid(bid_id)
  if (!rows) {
    return null
  }
  return (
    <SimpleTable
      fullWidth
      header={shared.label.message.header}
      data={rows}
      row_limit={5}
      {...other}
      columns={[
        {
          header: shared.label.message.subheader,
          sort: false,
          cell: (r) => <FormattedMessage message={r} />,
        },
      ]}
    />
  )
}

export default AdminMessages
