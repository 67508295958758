import React from 'react'
// import { useNavigate } from 'react-router-dom';
import { Button, Container } from '@material-ui/core'
import Page from 'components/Page'
import { SimpleCard, SimpleGrid } from 'components/Basic'
import { Row } from 'components/Basic/SimpleRowCol'
import SetupBank from './SetupBank'
import SetupCredit from './SetupCredit'
import CurrentInfo from './CurrentInfo'
import K from 'Constants'
import { Utils } from '@jesseteal/teal-react'

const PaymentSetup = ({ id }) => {
  // const navigate = useNavigate();
  const [add_cc, set_add_cc] = React.useState(false)
  const [add_bank, set_add_bank] = React.useState()
  // const [user, set_user] = React.useState(Utils.token.get())
  // console.log({ user: Utils.token.get() })
  return (
    <Page>
      <Container maxWidth="lg">
        {!add_cc && !add_bank && (
          <SimpleGrid
            items={[
              {
                size: { lg: 4, md: 4, sm: 3, xs: 12 },
                content: <div />,
              },
              {
                size: { lg: 4, md: 4, sm: 6, xs: 12 },
                content: Utils.token.get().sid ? (
                  <CurrentInfo
                    onReset={() => {
                      // set_user(Utils.token.get())
                    }}
                  />
                ) : (
                  <SimpleCard header="Secure Payment Setup">
                    <Row>
                      <Button
                        color="primary"
                        className="mb1 col"
                        variant="contained"
                        onClick={() => set_add_cc(true)}
                      >
                        <K.icon.credit className="mr1" />
                        Credit Card
                      </Button>
                    </Row>
                    <Row>
                      <Button
                        color="primary"
                        className="col"
                        variant="contained"
                        onClick={() => set_add_bank(true)}
                      >
                        <K.icon.bank className="mr1" />
                        Bank Account
                      </Button>
                    </Row>
                  </SimpleCard>
                ),
              },
              {
                size: { lg: 4, md: 4, sm: 3, xs: 12 },
                content: <div />,
              },
            ]}
          />
        )}

        {add_cc && <SetupCredit onClose={() => set_add_cc(false)} />}
        {add_bank && <SetupBank onClose={() => set_add_bank(false)} />}
      </Container>
    </Page>
  )
}

export default PaymentSetup
