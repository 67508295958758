import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container } from '@material-ui/core'
import Page from 'components/Page'
import SimpleCard from 'components/Basic/SimpleCard'
import SimpleForm from 'components/Basic/SimpleForm'
import FieldDef from 'components/Basic/FieldDef'
import { Api, Hooks, Utils } from '@jesseteal/teal-react'
import K from 'Constants'
import shared from 'shared/constants'
import { isProvider } from 'utils/role'

const PasswordReset = () => {
  const navigate = useNavigate()
  const { emailkey, loginkey, email } = useParams()
  const [message, set_message] = React.useState('')
  const save = Api.useSave('user')

  Hooks.useOnMount(() => {
    Api.Post('/auth/resetcheck', {
      email,
      eke: emailkey,
      llh: loginkey,
    }).then((d) => {
      if (d.status === 200) {
        Utils.token.set(d.token)
      }
      set_message(d.message)
    })
  })

  return (
    <Page title="Password Reset">
      <Container maxWidth="sm">
        {message ? (
          <SimpleCard header="Error" subheader={message} />
        ) : (
          <SimpleForm
            header="Password Reset"
            model={{}}
            save={(modified) => {
              save({ password: modified.password, id: Utils.token.get().sub })

              if (Utils.token.hasRole(shared.role.admin)) {
                navigate(K.route.home.Admin, { replace: true })
              } else if (isProvider()) {
                navigate(K.route.home.Provider, { replace: true })
              } else {
                navigate(K.route.home.User, { replace: true })
              }
            }}
            saveLabel="Change Password"
            fields={[
              FieldDef.name('password').label('New Password').password().get(),
              FieldDef.name('confirm')
                .label('Confirm Password')
                .confirm()
                .get(),
            ]}
          />
        )}
      </Container>
    </Page>
  )
}

export default PasswordReset
