import React from 'react'
import { Api } from '@jesseteal/teal-react'
import { SimpleForm, FieldDef } from 'components/Basic'
import { useProviderInfo } from 'shared/hooks'
import T from 'components/Typo'
import shared from 'shared/constants'

const CarrierInfo = ({ id, provider_id }) => {
  const { info } = useProviderInfo({ id, provider_id })
  const saveProvider = Api.useSave('provider')
  if (!info) {
    return null
  }
  return (
    <SimpleForm
      model={info}
      editMode={false}
      save={(mod) => {
        saveProvider({
          ...mod,
          id: provider_id,
        })
      }}
      subheader={shared.label.carrierInfoSubheader}
      fields={[
        FieldDef.content(
          T.subheader(shared.label.carrierAcceptResponseHint)
        ).get(12),
        FieldDef.name('response_accept')
          .label(shared.label.carrierAcceptResponseLabel)
          .lines(8)
          .get(12),
        FieldDef.content(T.subheader(shared.label.carrierDropoffHint)).get(12),
        FieldDef.name('response_dropoff')
          .label(shared.label.carrierDropoffLabel)
          .lines(8)
          .get(12),
      ]}
    />
  )
}

export default CarrierInfo
