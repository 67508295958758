import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Grid } from '@material-ui/core'
import Page from 'components/Page'
import Loading from 'components/Loading'
import { useRequest } from 'shared/hooks'
import FlightDetail from './FlightDetail'
import ClientBids from './ClientBids'
import K from 'Constants'

const ClientFlightDetail = ({ id, noBack = false }) => {
  const navigate = useNavigate()
  const { request } = useRequest(id)

  if (!request) {
    return <Loading />
  }
  return (
    <Page title="Flight Detail">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item md={6} sm={12} xs={12}>
            <FlightDetail
              request={request}
              header="Details"
              subheader={`Status: ${request.status}`}
              avatar={
                noBack
                  ? null
                  : {
                      icon: K.icon.left,
                      onClick: () => navigate(K.route.flights.href),
                    }
              }
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <ClientBids id={id} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default ClientFlightDetail
