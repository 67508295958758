import React from 'react';
import { CircularProgress } from '@material-ui/core';
const Loading = () => {
  return (
    <span className="pt2 pb2 mt2 mb2">
      <CircularProgress />
    </span>
  );
};

export default Loading;
