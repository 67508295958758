import React from 'react'
import { Container } from '@material-ui/core'
import { Hooks, Utils } from '@jesseteal/teal-react'
import { useSearchParams } from 'react-router-dom'
import Page from 'components/Page'
import Password from 'components/Password'
import UserForm from 'components/UserForm'
import PaymentSetup from './PaymentSetup'
import ProviderAirports from 'components/ProviderAirports'
import CarrierInfo from './CarrierInfo'
import Notifications from './Notifications'
import { SimpleTabs } from 'components/Basic'
import shared from 'shared/constants'
import { isProvider } from 'utils/role'

const Profile = () => {
  const [pay_enabled] = Hooks.useContextState('pay_enabled')
  const is_provider = isProvider()
  const { sub: id, pid: provider_id } = Utils.token.get()
  const [params] = useSearchParams()

  const tabs = [
    {
      label: 'User Info',
      content: <UserForm id={id} />,
    },

    {
      label: 'Change Password',
      content: (
        <Password
          id={id}
          className="mt2 mb2 ctr"
          style={{
            width: 500,
          }}
        />
      ),
    },
  ]
  if (is_provider) {
    tabs.push({
      label: 'Airports Served',
      content: <ProviderAirports />,
    })
    if (provider_id)
      tabs.push({
        label: 'Carrier Info',
        content: <CarrierInfo provider_id={Utils.token.get().pid} />,
      })
  }
  if (pay_enabled) {
    tabs.push({
      label: 'Payment Info',
      content: <PaymentSetup id={id} />,
    })
  }
  tabs.push({
    label: 'Notifications',
    content: <Notifications id={id} />,
  })
  return (
    <Page>
      <Container maxWidth="lg">
        <SimpleTabs activeTab={params.get('t')} tabs={tabs} />
      </Container>
    </Page>
  )
}

export default Profile
