import React from 'react'
import { Utils } from '@jesseteal/teal-react'
import { SimpleTable } from 'components/Basic'
import shared from 'shared/constants'
import { useAdminRequestsList } from 'shared/hooks'
const RequestList = ({ statusFilter = () => true, row_action }) => {
  const limit = Utils.date.add(new Date(), -3, 'yyyy-MM-dd')
  const { rows } = useAdminRequestsList({
    or: [{ deleted_at: null }, { deleted_at: { gt: limit } }],
  })
  return (
    <SimpleTable
      fullWidth
      header="Requests"
      row_action={row_action}
      data={(rows || []).filter(statusFilter)}
      columns={[
        {
          header: 'ID',
          cell: (r) => shared.label.cargo.identifier_value(r),
        },
        {
          header: 'User',
          cell: (r) => `${r.user.lastname}, ${r.user.firstname}`,
        },
        {
          header: 'Status',
          cell: (r) => (r.deleted_at ? 'Cancelled' : r.status),
        },
        {
          header: 'Date',
          cell: (r) => Utils.date.format(r.start_date),
        },
        {
          header: 'Itinerary',
          cell: (r) => `${r.departure.code} - ${r.destination.code}`,
        },
        {
          header: 'Quotes',
          cell: (r) =>
            r.bid?.map((b, i) => (
              <div key={`bid${i}`}>{Utils.formatMoney(b.cost)}</div>
            )),
          style: { width: '120px' },
          align: 'right',
        },
      ]}
    />
  )
}

export default RequestList
