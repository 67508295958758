import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Link } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Page from 'components/Page'
import Terms from './Terms'
import SimpleForm from 'components/Basic/SimpleForm'
import FieldDef from 'components/Basic/FieldDef'
import K from 'Constants'
import shared from 'shared/constants'
import { Api } from '@jesseteal/teal-react'
import Typo from 'components/Typo'

const RegisterProvider = () => {
  const navigate = useNavigate()
  const [show_terms, set_show_terms] = React.useState(false)
  const [footer, set_footer] = React.useState(null)
  const save_user = Api.useSave('user')
  const save_role = Api.useSave('role')
  const save_provider = Api.useSave('provider')
  const save_provider_user = Api.useSave('provider_user')

  const fields = [
    FieldDef.header('User Information').get(12),
    FieldDef.name('firstname').label('First Name').required().get(6),
    FieldDef.name('lastname').label('Last Name').required().get(6),
    FieldDef.name('email').label('Email Address').email().required().get(),
    FieldDef.name('password').label('Password').password().required().get(6),
    FieldDef.name('confirm')
      .label('Confirm Password')
      .confirm()
      .required()
      .get(6),
    FieldDef.header('Carrier Information').get(12),
    FieldDef.name('name').label('Air Carrier Service Name').get(6),
    FieldDef.name('contact').label('Main Contact Name').get(6),
    FieldDef.name('phone').phone().label('Main Contact Phone').get(6),
    FieldDef.name('contact_email').email().label('Main Contact Email').get(6),
  ]

  fields.push(
    FieldDef.name('policy')
      .label('I have read the Terms and Conditions') //'Terms and Conditions'
      .bool()
      .required('Terms must be checked.')
      .get(6)
  )
  fields.push(
    FieldDef.content(
      <Box style={{ marginTop: '10px' }}>
        <Link href="#" onClick={() => set_show_terms(true)}>
          <K.icon.terms /> Terms &amp; Conditions
        </Link>
      </Box>
    ).get()
  )

  return (
    <Page>
      <Container maxWidth="md">
        <SimpleForm
          header="Create a Carrier Account"
          subheader="Register yourself as a Carrier"
          footer={footer}
          saveLabel="Submit Request"
          save={(values) => {
            const { firstname, lastname, email, password } = values
            save_user(
              { firstname, lastname, email, password, enabled: 0 },
              true
            ).then(async (r) => {
              const user_id = r.createuser
              if (user_id === 0) {
                set_footer(
                  <Alert severity="warning">
                    Email address already in use.
                  </Alert>
                )
              } else {
                const { name, contact, phone, contact_email: email } = values
                const provider = await save_provider(
                  { name, contact, phone, email },
                  true
                )
                await save_role({ user_id, role: shared.role.provider }, true)
                await save_provider_user(
                  {
                    user_id,
                    provider_id: provider.createprovider,
                  },
                  true
                )
                navigate(K.route.register_provider_ty.href)
              }
            })
          }}
          fields={fields}
        />
        <Box mt={3}>
          {Typo.body(
            <>
              Have an account?{' '}
              <Link href="#" onClick={() => navigate(K.route.login.href)}>
                {K.route.login.label}
              </Link>
            </>
          )}
        </Box>
      </Container>
      <Terms show={show_terms} onClose={() => set_show_terms(false)} />
    </Page>
  )
}

export default RegisterProvider
