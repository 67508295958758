import React from 'react'
import { Grid } from '@material-ui/core'

/*
usage:
  <SimpleGrid
    spacing={6}
    items={[
      { content: <component />, size: 4 },
      { content: <component />, size: 4 },
      { content: <component />, size: 4 },
      { content: <component />, size: 4 },
      { content: <component />, size: 4 },
      { content: <component />, size: 4 },
    ]}
  />
*/

const size_props = size => {
  if (typeof size === 'object') {
    return size
  }
  switch (size) {
    case 3:
    case 4:
      return { lg: size, md: 6, sm: 12, xs: 12 }
    default:
      return { lg: size, md: 12, sm: 12, xs: 12 }
  }
}

const SimpleGrid = ({ items, spacing = 3 }) => {
  return (
    <Grid container spacing={spacing}>
      {items
        .filter(t => !!t.content)
        .map((it, i) => {
          const props = size_props(it.size || 12)
          return (
            <Grid key={i} item {...props}>
              {it.content}
            </Grid>
          )
        })}
    </Grid>
  )
}

export default SimpleGrid
