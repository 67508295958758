import { Api } from '@jesseteal/teal-react'
import { schema } from '../schema'

export const useUser = (id) => {
  const results = Api.useGraphQuery(
    `query UserForm($where:String){
      user(where:$where){ ${schema['user']} }
    }`,
    {
      networkOnly: true,
      variables: {
        where: JSON.stringify({
          id,
        }),
      },
    }
  )
  return {
    ...results,
    user: results.data?.user?.[0],
  }
}

export const useRole = (user_id) => {
  const results = Api.useGraphQuery(
    `query GetRoleId($where: String){
      role(where: $where) { id role type }
    }`,
    {
      variables: {
        where: JSON.stringify({ user_id }),
      },
    }
  )
  return {
    ...results,
    role: results.data?.role?.[0],
  }
}

export const useUsersWithRole = () => {
  const results = Api.useGraphQuery(
    `query ManageUsers($where: String){
    user(where: $where, order:"lastname"){
      ${schema['user']}
      role(order:"role"){
        id role
      }
    }
  }`,
    {
      networkOnly: true,
      variables: {
        where: JSON.stringify({ deleted_at: null }),
      },
    }
  )
  return {
    ...results,
    rows: results.data?.user,
  }
}
