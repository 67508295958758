import React from 'react'
import RoleRoute from 'components/RoleRoute'
import ClientCargo from './components/ClientCargo'
import ProviderCargo from './components/ProviderCargo'
import shared from 'shared/constants'

const CargoList = () => {
  return (
    <RoleRoute
      routes={{
        [shared.role.user]: <ClientCargo />,
        [shared.role.provider]: <ProviderCargo />,
      }}
    />
  )
}

export default CargoList
