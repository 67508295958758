import React from 'react'
import { Chip } from '@material-ui/core'
import { Api } from '@jesseteal/teal-react'
import SimpleCard from 'components/Basic/SimpleCard'
import K from 'Constants'
import shared from 'shared/constants'
import { useRole } from 'shared/hooks'
import { debounce } from 'lodash'

const RoleManager = ({ id }) => {
  const { data, refetch } = useRole(id)

  const handleClick = React.useCallback(
    debounce(async (role) => {
      await save({ user_id: parseInt(id, 10), role }, true)
      refetch()
    }, 200),
    []
  )

  const save = Api.useSave('role')
  if (!data) {
    return null
  }
  const roles = data.role.map((t) => t.role)

  const show_chip = (role) => {
    const active = roles.includes(role)
    return (
      <Chip
        variant="outlined"
        clickable
        onClick={() => handleClick(role)}
        className="mr2"
        color={active ? 'primary' : 'default'}
        icon={active ? <K.icon.check /> : null}
        label={role.replace('Provider', 'Carrier')}
      />
    )
  }

  return (
    <SimpleCard header="Role">
      {show_chip(shared.role.admin)}
      {show_chip(shared.role.provider)}
      {show_chip(shared.role.user)}
      {show_chip(shared.role.test_user)}
      {show_chip(shared.role.test_provider)}
    </SimpleCard>
  )
}

export default RoleManager
