import React from 'react'
import { Utils } from '@jesseteal/teal-react'
import ClientIcon from './ClientIcon'
import ProviderIcon from './ProviderIcon'
import { isProvider, isUser } from 'utils/role'

const MessageNotificationIcon = () => {
  const [is_provider] = React.useState(isProvider())
  const [is_client] = React.useState(isUser())
  if (is_provider && Utils.token.get().pst !== 'Approved') {
    return <ProviderIcon />
  } else if (is_client) {
    return <ClientIcon />
  }
  return null
}

export default MessageNotificationIcon
