import React from 'react'
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AppBar, Fab, Toolbar } from '@material-ui/core'
import Logo from 'components/Logo'
import { Utils } from '@jesseteal/teal-react'
import K from 'Constants'
import TopBarLinks from './TopBarLinks'
import MessageNotificationIcon from 'components/Message/MessageNotificationIcon'
import { baseRole } from 'utils/role'

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const is_auth = !!Utils.token.get().sub
  const logo_url =
    Utils.token.get().pst === 'Approved'
      ? K.route.register_provider_profile.href
      : K.route.root[baseRole()] || '/'
  return (
    <AppBar className={className} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to={logo_url}>
          <Logo />
        </RouterLink>

        <TopBarLinks />

        {/* <Hidden mdDown> */}
        {is_auth && (
          <>
            <MessageNotificationIcon />
            <Fab
              color="primary"
              size="medium"
              onClick={() => {
                // set_token(null);
                // console.log('clear it');
                Utils.token.set(null)
                navigate('/')
              }}
            >
              <K.icon.logout />
            </Fab>
          </>
        )}
        {!is_auth && location.pathname !== '/login' && (
          <Fab color="primary" size="medium" onClick={() => navigate('/login')}>
            <K.icon.login />
          </Fab>
        )}
        {/* </Hidden> */}
        {/**
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <K.icon.menu />
          </IconButton>
        </Hidden>
         */}
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
}

export default TopBar
