import React from 'react'
import { useParams } from 'react-router-dom'
// import { Api, Utils } from '@jesseteal/teal-react'
import { SimpleGrid } from 'components/Basic'
import { useNavigate } from 'react-router-dom'
import { Container } from '@material-ui/core'
import Page from 'components/Page'
import AdminCargoDetail from './components/AdminCargoDetail'
import BidList from './components/BidList'
import AdminMessages from './components/AdminMessages'
import { PK } from 'utils'

// import T from 'components/Typo'
import K from 'Constants'

const RequestDetail = () => {
  const { id: obscured_id } = useParams()
  const id = PK.get(obscured_id)
  const navigate = useNavigate()
  const [bid_id, set_bid_id] = React.useState()
  return (
    <Page>
      <Container maxWidth="lg">
        <SimpleGrid
          items={[
            {
              size: 6,
              content: (
                <AdminCargoDetail
                  id={id}
                  onBack={() => navigate(K.route.admin_requests.href)}
                />
              ),
            },
            {
              size: 6,
              content: (
                <>
                  <BidList id={id} onClick={(r) => set_bid_id(r.id)} />
                  {bid_id && <AdminMessages bid_id={bid_id} className="mt2" />}
                </>
              ),
            },
          ]}
        />
      </Container>
    </Page>
  )
}

export default RequestDetail
