import React from 'react'
import { Modal } from '@material-ui/core'
/*
<SimpleModal
  open={open}
  onClose={}>
  content
</SimpleModal>
 */
const SimpleModal = ({ children, open = true, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div style={{ marginTop: '10%', width: '50%', marginLeft: '25%' }}>
        {children}
      </div>
    </Modal>
  )
}

export default SimpleModal
