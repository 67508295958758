import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import AppContext from './AppContext';

ReactDOM.render(
    <BrowserRouter>
      <AppContext />
    </BrowserRouter>,
  document.getElementById('remora')
);
