import React from 'react'
import RoleRoute from 'components/RoleRoute'
import ClientFlights from './components/ClientFlights'
import ProviderFlights from './components/ProviderFlights'
import shared from 'shared/constants'

const FlightList = () => {
  return (
    <RoleRoute
      routes={{
        [shared.role.user]: <ClientFlights />,
        [shared.role.provider]: <ProviderFlights />,
      }}
    />
  )
}

export default FlightList
