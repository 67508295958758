import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    // border: '1px solid red',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: 256,
    // },
  },
  contentContainer: {
    // border: '1px solid blue',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    // border: '1px solid green',
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}))

export default useStyles
