import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container } from '@material-ui/core'
import { Api, Utils } from '@jesseteal/teal-react'
import Page from 'components/Page'
import { SimpleDeleteConfirm, SimpleGrid } from 'components/Basic'
import K from 'Constants'
import shared from 'shared/constants'
import { useRequest } from 'shared/hooks'
import CargoDetail from './CargoDetail'
import ClientBids from './ClientBids'

const ClientRequest = ({ id, noBack = false }) => {
  const [confirm_delete, set_confirm_delete] = React.useState(false)
  const navigate = useNavigate()
  const { request } = useRequest(id)

  const save = Api.useSave('request', 'request')
  if (!request) {
    return null
  }
  return (
    <Page>
      <Container maxWidth="lg">
        {confirm_delete && (
          <SimpleDeleteConfirm
            onDelete={() => {
              save({
                id: request.id,
                deleted_at: Utils.date.dbNow(),
              }).then(() => {
                navigate(K.route.shipments.href)
              })
            }}
            onCancel={() => set_confirm_delete(false)}
          />
        )}
        <SimpleGrid
          items={[
            {
              content: (
                <CargoDetail
                  id={id}
                  onBack={
                    noBack ? null : () => navigate(K.route.shipments.href)
                  }
                  actionsRight={
                    request.status === shared.status.COMPLETED
                      ? null
                      : [
                          {
                            label: (
                              <React.Fragment>
                                <K.icon.cancel className="mr1" /> Cancel Request
                              </React.Fragment>
                            ),
                            className: 'c-red',
                            onClick: () => set_confirm_delete(true),
                          },
                        ]
                  }
                />
              ),
              size: 7,
            },
            {
              content: <ClientBids id={request.id} />,
              size: 5,
            },
          ]}
        />
      </Container>
    </Page>
  )
}

export default ClientRequest
