import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Link } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Page from 'components/Page'
import SimpleForm from 'components/Basic/SimpleForm'
import FieldDef from 'components/Basic/FieldDef'
import K from 'Constants'
import shared from 'shared/constants'
import { Api, Utils } from '@jesseteal/teal-react'
import Typo from 'components/Typo'

const LoginView = () => {
  const navigate = useNavigate()
  const [footer, set_footer] = React.useState(null)

  const login_with = (values) => {
    Api.Post('/auth', values).then((r) => {
      if (r.status === 200) {
        Utils.token.set(r.token)
        if (Utils.token.hasRole(shared.role.admin)) {
          navigate(K.route.home.Admin, { replace: true })
        } else if (Utils.token.get().pst === 'Approved') {
          navigate(K.route.register_provider_profile.href, { replace: true })
        } else {
          navigate(K.route.shipments.href, { replace: true })
        }
      } else {
        set_footer(<Alert severity="warning">Bad username or password!</Alert>)
      }
    })
  }

  return (
    <Page>
      <Container maxWidth="sm">
        <SimpleForm
          header="Sign In"
          subheader="Welcome to Remora"
          saveLabel="Sign In"
          saveIcon={K.icon.login}
          footer={footer}
          save={(values) => {
            login_with(values)
          }}
          fields={[
            FieldDef.name('email').label('Email').email().required().get(),
            FieldDef.name('password')
              .label('Password')
              .password()
              .required()
              .get(),
          ]}
        />
        <Box my={3}>
          {Typo.body(
            <>
              Don&apos;t have an account?{' '}
              <Link href="#" onClick={() => navigate(K.route.register.href)}>
                {K.route.register.label}
              </Link>
            </>
          )}
        </Box>
        <Box my={3}>
          {Typo.body(
            <>
              Want to become a Carrier?{' '}
              <Link
                href="#"
                onClick={() => navigate(K.route.register_provider.href)}
              >
                {K.route.register_provider.label}
              </Link>
            </>
          )}
        </Box>
        {Typo.body(
          <>
            Problems logging in?{' '}
            <Link href="#" onClick={() => navigate(K.route.forgot.href)}>
              {K.route.forgot.label}
            </Link>
          </>
        )}
        {/*
        <Box my={3}>
          {Typo.body(
            <Button
              onClick={() =>
                login_with({
                  email: 'client4@example.com',
                  password: 'test',
                })
              }
            >
              Login as Alice
            </Button>
          )}
        </Box>
        <Box my={3}>
          {Typo.body(
            <Button
              onClick={() =>
                login_with({
                  email: 'pilot@example.com',
                  password: 'test',
                })
              }
            >
              Login as Chuck
            </Button>
          )}
        </Box>
        */}
      </Container>
    </Page>
  )
}

export default LoginView
