import React from 'react'
import SimpleForm from 'components/Basic/SimpleForm'
import FieldDef from 'components/Basic/FieldDef'
import K from 'Constants'
import Alert from '@material-ui/lab/Alert'
import { Api } from '@jesseteal/teal-react'

const Password = ({ id, ...other }) => {
  const save = Api.useSave('user')
  const [modified, set_modified] = React.useState({})
  const [footer, set_footer] = React.useState(null)
  const [hide_save, set_hide_save] = React.useState(false)
  const onSave = () => {
    save({
      id,
      password: modified.f1,
    })
    set_modified({})
    set_footer(<Alert severity="success">Password Updated</Alert>)
    set_hide_save(true)
  }

  return (
    <SimpleForm
      {...other}
      header={K.route.password.header}
      subheader={K.route.password.subheader}
      fields={[
        FieldDef.name('f1')
          .label('Password')
          .password()
          .get(),
        FieldDef.name('f2')
          .label('Confirm Password')
          .confirm()
          .get(),
      ]}
      footer={footer}
      onChange={t => {
        set_modified(t)
        if (!!t.f2 && t.f1 !== t.f2) {
          if (!footer) {
            set_footer(
              <Alert severity="warning">Confirmation doesn't match.</Alert>
            )
          }
        } else if (footer) {
          set_footer(null)
        }
      }}
      actionsRight={
        hide_save
          ? []
          : [
              {
                label: (
                  <>
                    <K.icon.save className="mr1" /> Update Password
                  </>
                ),
                onClick: onSave,
                disabled: () => {
                  return !modified.f1 || modified.f1 !== modified.f2
                },
              },
            ]
      }
    />
  )
}

export default Password
