import React from 'react'
// } from '@material-ui/core';
import { Api, Utils } from '@jesseteal/teal-react'
import { SimpleGrid } from 'components/Basic'
import shared from 'shared/constants'
import CargoDetail from './CargoDetail'
import FlightDetail from './FlightDetail'
import BidForm from './BidForm'

const ProviderBidForm = ({ request, onSave, onCancel }) => {
  const save = Api.useSave('bid')
  const saveMessage = Api.useSave('message')
  const save_bid = (modified, values) => {
    save(
      {
        provider_id: Utils.token.get().sub,
        request_id: request.id,
        fly_date: values.fly_date,
        status: shared.status.OPEN,
        cost: parseFloat(values.cost),
      },
      true
    ).then((r) => {
      if (modified.message && modified.message.trim() !== '') {
        saveMessage(
          {
            bid_id: r.createbid,
            from_id: Utils.token.get().sub,
            message: modified.message.trim(),
          },
          true
        )
      }
      onSave && onSave()
    })
  }
  return (
    <SimpleGrid
      spacing={1}
      items={[
        {
          size: 6,
          content:
            request.type === shared.flight_type.cargo ? (
              <CargoDetail id={request.id} onBack={() => onCancel()} />
            ) : (
              <FlightDetail request={request} onBack={() => onCancel()} />
            ),
        },
        {
          size: 6,
          content: <BidForm request={request} onSave={save_bid} />,
        },
      ]}
    />
  )
}

export default ProviderBidForm
