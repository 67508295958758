import config from './K_config'
import icon from './K_icon'
import route from './K_route'
import shared from '../shared/constants'
import snack from './K_snack'

export default {
  dev: 'jesseteal@gmail.com',
  config,
  icon,
  route,
  shared,
  snack,
}
