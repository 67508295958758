import React from 'react'
// import { View, Linking } from 'react-native';
// import { Text, Paragraph, Avatar } from 'react-native-paper';
import { SimpleCard } from 'components/Basic'
import jwtDecode from 'jwt-decode'
import { Api, Utils, Hooks } from '@jesseteal/teal-react'
// import { Cache } from 'utils';
// import { useOnFocus } from 'utils/hooks';
// import Style from 'components/Style';
import K from 'Constants'
import T from 'components/Typo'

const CurrentInfo = ({ onReset }) => {
  const [info, set_info] = Hooks.useContextState('payment_info')
  // const set_user = Hooks.useSetContextState('user')

  const load = () => {
    console.log('loading')
    Api.Post('/pay/summary', { remora: Utils.token.token() }).then(res => {
      // console.log('CurrentInfo:load',{res});
      if (res.info) {
        const _info = jwtDecode(res.info)
        // console.log({_info});
        set_info(_info)
      }
    })
  }

  Hooks.useOnMount(() => {
    console.log('onMount, load?')
    if (!info) {
      load()
    }
  })

  return (
    <SimpleCard
      header={info ? `Payment Settings` : 'Loading'}
      subheader={
        info?.bank ? 'Bank Account' : info?.brand ? 'Credit / Debit Card' : null
      }
      avatar={
        info?.bank
          ? { icon: K.icon.bank }
          : info?.brand
          ? { icon: K.icon.credit }
          : null
      }
      actionsRight={[
        {
          icon: <K.icon.delete />,
          label: 'Remove Payment Option',
          onClick: () => {
            const t = Utils.token.get()
            set_info(null)
            Utils.token._overwrite({
              ...t,
              sid: null,
            })
            onReset && onReset()
          },
        },
      ]}
    >
      {info &&
        info.brand &&
        T.label_value_justified(info.brand, `ending in *${info.last4}`)}

      {info &&
        info.bank &&
        T.label_value_justified(info.bank, `account ending in *${info.last4}`)}
    </SimpleCard>
  )
}

export default CurrentInfo
