import React from 'react'
import { Api, Hooks } from '@jesseteal/teal-react'

export const useAppSetting = (title) => {
  const [setting, set_setting] = Hooks.useContextState(`setting_${title}`)
  const { data } = Api.useGraphQuery(
    `query AppSetting($where: String){
      setting(where: $where){
        setting
      }
    }`,
    {
      skip: setting !== null,
      networkOnly: true,
      variables: {
        where: JSON.stringify({ title }),
      },
    }
  )
  React.useEffect(() => {
    if (data && data.setting.length) {
      set_setting(JSON.parse(data.setting[0].setting))
    }
    // eslint-disable-next-line
  }, [(data || {}).setting])
  return setting
}

export const useSettings = () => {
  const results = Api.useGraphQuery(
    `query ManageContent{ setting { id title setting } }`
  )
  return {
    ...results,
    rows: results.data?.setting,
  }
}
