import React from 'react'
import { Box, Container } from '@material-ui/core'
import Page from 'components/Page'
import ContentDisplay from 'components/ContentDisplay'
import shared from 'shared/constants'

const RegisterReceived = () => {
  return (
    <Page>
      <Container maxWidth="lg">
        <Box py={2}>
          <ContentDisplay id={shared.content.registration_received} />
        </Box>
      </Container>
    </Page>
  )
}

export default RegisterReceived
