import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { Utils } from '@jesseteal/teal-react'
import { SimpleDialog } from 'components/Basic'
import { Container } from '@material-ui/core'
import Page from 'components/Page'
import MaxBox from 'components/Basic/MaxBox'
import { useBids } from 'shared/hooks'
import shared from 'shared/constants'
import ProviderBidDetail from '../requests/components/ProviderBidDetail'

const ProviderCalendarView = () => {
  const [show_bid, set_show_bid] = React.useState(null)
  const { rows: bids } = useBids({
    // for provider
    provider_id: Utils.token.get().sub,
  })

  if (!bids) {
    return null
  }
  return (
    <Page>
      <Container maxWidth="lg">
        <MaxBox>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            height="100%"
            events={bids
              .filter((bid) => {
                return [shared.status.OPEN, shared.status.ACCEPTED].includes(
                  bid.status
                )
              })
              .map((bid) => {
                const colors =
                  bid.status === shared.status.ACCEPTED
                    ? {
                        backgroundColor: shared.color.primary,
                        borderColor: shared.color.secondary,
                      }
                    : {
                        textColor: '#333',
                        backgroundColor: '#CCC',
                        borderColor: '#999',
                      }
                return {
                  id: bid.id,
                  // id: bid.request_id,
                  date: bid.fly_date,
                  ...colors,
                  title: `${shared.label.cargo.identifier_value(
                    bid.request_id_request
                  )} ${bid.request_id_request.type} ${
                    bid.request_id_request.from_airport_id_airport.code
                  } - ${bid.request_id_request.to_airport_id_airport.code}`,
                }
              })}
            eventClick={(info) => {
              set_show_bid(info.event.id)
            }}
          />
        </MaxBox>
      </Container>

      {show_bid && (
        <SimpleDialog size="lg" open={true} onClose={() => set_show_bid(null)}>
          <ProviderBidDetail id={show_bid} noBack={true} />
        </SimpleDialog>
      )}
    </Page>
  )
}

export default ProviderCalendarView
