import React from 'react'
import ReactMarkdown from 'react-markdown'
import SimpleCard from 'components/Basic/SimpleCard'
import { useContent } from 'shared/hooks'

const ContentDisplay = (props) => {
  const { id, header, subheader, content, headeraction, ...other } = props
  const { content: stored_content } = useContent(id)

  const display_header = header || stored_content?.header
  const display_subheader = subheader || stored_content?.subheader
  const source = content || stored_content?.content

  return source ? (
    <SimpleCard
      header={display_header}
      subheader={display_subheader}
      headeraction={headeraction}
      {...other}
    >
      <ReactMarkdown className="blrb" source={source} />
    </SimpleCard>
  ) : null
}

export default ContentDisplay
