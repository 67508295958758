import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { SimpleDialog } from 'components/Basic'
import { Container } from '@material-ui/core'
import Page from 'components/Page'
import MaxBox from 'components/Basic/MaxBox'
import { useBids } from 'shared/hooks'
import shared from 'shared/constants'
import ProviderBidDetail from '../requests/components/ProviderBidDetail'

const AdminCalendarView = () => {
  // const navigate = useNavigate()
  const [show_bid, set_show_bid] = React.useState(null)
  const { rows: bids, data } = useBids({
    or: [
      { status: { eq: shared.status.ACCEPTED } },
      { status: { eq: shared.status.ASSIGNED } },
      { status: { eq: shared.status.COMPLETED } },
    ],
  })
  console.log({ bids, data })
  if (!bids) {
    return null
  }
  return (
    <Page>
      <Container maxWidth="lg">
        <MaxBox>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            height="100%"
            events={bids.map((bid) => {
              const colors =
                bid.status === shared.status.ACCEPTED
                  ? {
                      backgroundColor: shared.color.primary,
                      borderColor: shared.color.secondary,
                    }
                  : {
                      textColor: '#333',
                      backgroundColor: '#CCC',
                      borderColor: '#999',
                    }
              return {
                id: bid.id,
                date: bid.fly_date,
                ...colors,
                title: `${shared.label.cargo.identifier_value(
                  bid.request_id_request
                )} ${bid.request_id_request.type} ${
                  bid.request_id_request.from_airport_id_airport.code
                } - ${bid.request_id_request.to_airport_id_airport.code}`,
              }
            })}
            eventClick={(info) => {
              // navigate(K.route.bid.href(info.event.id))
              set_show_bid(info.event.id)
            }}
          />
        </MaxBox>
      </Container>

      {show_bid && (
        <SimpleDialog size="lg" open={true} onClose={() => set_show_bid(null)}>
          <ProviderBidDetail id={show_bid} noBack={true} />
        </SimpleDialog>
      )}
    </Page>
  )
}

export default AdminCalendarView
