import React from 'react'
import { Api, Hooks, Utils } from '@jesseteal/teal-react'
import { SimpleConfirm, SimpleTable } from 'components/Basic'
import { useBids } from 'shared/hooks'
import K from 'Constants'
import shared from 'shared/constants'
import Messages from 'components/Messages'
import ProviderResponseMessage from '../../../components/ProviderResponseMessage'

const ClientBids = ({ id }) => {
  const set_snack = Hooks.useSetContextState('snack')
  const [confirm, set_confirm] = React.useState(null)
  // const [selected_bid, set_selected_bid] = React.useState(null)
  const { rows: bids, refetch } = useBids({ request_id: id })

  const saveBid = Api.useSave('bid', 'request')

  const winning_bid = React.useMemo(() => {
    if (bids?.length) {
      const match = bids.find((b) => b.status === shared.status.ACCEPTED)
      return match
    }
  }, [bids])

  if (!bids) {
    return null
  }
  const show_data = bids.filter((b) => {
    if (!winning_bid) {
      return true
    }
    return winning_bid?.id === b.id
  })
  return (
    <React.Fragment>
      <SimpleTable
        fullWidth
        header={shared.label.bid.header}
        data={show_data}
        columns={[
          {
            header: shared.label.bid.cost,
            cell: (r) => Utils.formatMoney(r.cost),
          },
          {
            header: shared.label.bid.fly_date,
            cell: (r) => Utils.date.format(r.fly_date),
          },
          {
            header: shared.label.bid.status,
          },
          {
            // header: 'Accept',
            action: (bid) =>
              set_confirm({
                id: bid.id,
                status: 'Accepted',
                header: 'Accept Quote?',
                message: 'Accept this quote',
                submit: 'Accept',
              }),
            icon: (r) =>
              r.status === shared.status.OPEN ? (
                <K.icon.accept title="Accept Bid" color="green" />
              ) : null,
            style: { width: 60 },
          },
          {
            // header: 'Decline',
            action: (bid) =>
              set_confirm({
                id: bid.id,
                status: 'Closed',
                header: 'Decline Quote?',
                message: 'Decline this quote',
                submit: 'Decline',
              }),
            icon: (r) =>
              r.status === shared.status.OPEN ? (
                <K.icon.cancel title="Decline Bid" color="red" />
              ) : null,
            style: { width: 60 },
          },
          // {
          //   header: '',
          //   action: (bid) => {
          //     if (bid.status === shared.status.ACCEPTED) {
          //       set_selected_bid(bid)
          //     }
          //   },
          //   icon: (r) =>
          //     r.status === shared.status.ACCEPTED ? (
          //       <Badge
          //         badgeContent={
          //           (r.message || []).filter(
          //             (m) => m.viewed_at === null && m.from_id !== me_id
          //           ).length
          //         }
          //         color="error"
          //       >
          //         <K.icon.messages />
          //       </Badge>
          //     ) : null,
          //   style: { width: 60 },
          // },
        ]}
      />
      {winning_bid ? (
        <ProviderResponseMessage className="mt2" bid={winning_bid} />
      ) : null}
      {winning_bid && (
        <Messages className="mt2" bid_id={winning_bid.id} header="Messages" />
      )}
      {/*
      {selected_bid && (
        // <SimpleDrawer onClose={() => set_selected_bid(null)}>
        <Messages
          className="mt2"
          bid_id={selected_bid.id}
          header={selected_bid ? 'Messages for selected quote' : null}
          subheader={
            selected_bid
              ? `Cost: ${Utils.formatMoney(
                  selected_bid.cost
                )}, Date: ${Utils.date.format(selected_bid.fly_date)}`
              : null
          }
          headeraction={{
            icon: <K.icon.remove />,
            onClick: () => set_selected_bid(null),
          }}
        />
        // </SimpleDrawer>
      )} */}

      {confirm && (
        <SimpleConfirm
          onAction={() => {
            saveBid({
              id: confirm.id,
              status: confirm.status,
            }).then((r) => {
              if (r.status) {
                refetch()
              } else {
                set_snack({
                  message: r.error,
                  type: 'error',
                })
              }
            })
            set_confirm(null)
          }}
          onCancel={() => set_confirm(null)}
          header={confirm.header}
          message={confirm.message}
          actionLabel={confirm.submit}
        />
      )}
    </React.Fragment>
  )
}

export default ClientBids
