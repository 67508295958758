import React from 'react'
import { SimpleTabs, SimpleForm, FieldDef } from 'components/Basic'
import ProviderAirports from 'components/ProviderAirports'
import { useNavigate } from 'react-router-dom'
import { Container } from '@material-ui/core'
import { Api, Utils } from '@jesseteal/teal-react'
import Page from 'components/Page'
import Typo from 'components/Typo'
import shared from '../../shared/constants'
// import ContentDisplay from 'components/ContentDisplay'
// import K from 'Constants'

const RegisterProfile = () => {
  const navigate = useNavigate()
  const saveProvider = Api.useSave('provider')
  const refreshToken = () => {
    Api.Post('/auth/refresh', {
      remora: Utils.token.token(),
    }).then((res) => {
      if (res.status === 200 && res.token) {
        Utils.token.set(res.token)
        navigate('/')
      }
    })
  }
  const doSave = (mod) => {
    saveProvider({
      ...mod,
      id: Utils.token.get().pid,
      status: 'Completed',
    }).then(refreshToken)
  }
  const skip_action = [{ label: 'Skip', onClick: () => doSave() }]
  const [actions, set_actions] = React.useState(skip_action)
  return (
    <Page>
      <Container maxWidth="lg">
        {Typo.h2('Profile Completion')}
        {Typo.h3('Please provide the following information.')}
        <SimpleTabs
          tabs={[
            {
              label: '1) Select Airports Served',
              content: (
                <ProviderAirports
                  header={''}
                  subheader={'Leave blank if you service all'}
                />
              ),
            },
            {
              label: '2) Initial Messages',
              content: (
                <SimpleForm
                  onChange={(mod) => {
                    console.log({ mod })
                    if (
                      mod.response_accept?.trim() ||
                      mod.response_dropoff?.trim()
                    ) {
                      set_actions(null)
                    } else if (!actions) {
                      set_actions(skip_action)
                    }
                  }}
                  actionsRight={actions}
                  save={doSave}
                  subheader={shared.label.carrierInfoSubheader}
                  fields={[
                    FieldDef.content(
                      Typo.subheader(shared.label.carrierAcceptResponseHint)
                    ).get(12),
                    FieldDef.name('response_accept')
                      .label(shared.label.carrierAcceptResponseLabel)
                      .lines(8)
                      .get(12),
                    FieldDef.content(
                      Typo.subheader(shared.label.carrierDropoffHint)
                    ).get(12),
                    FieldDef.name('response_dropoff')
                      .label(shared.label.dropoffInstructions)
                      .lines(8)
                      .get(12),
                  ]}
                />
              ),
            },
          ]}
        />
      </Container>
    </Page>
  )
}

export default RegisterProfile
