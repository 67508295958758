import React from 'react'
import { Hooks, Utils } from '@jesseteal/teal-react'
import { useNavigate } from 'react-router-dom'
import { Badge, IconButton } from '@material-ui/core'
import { NotificationsOutlined } from '@material-ui/icons'
import K from 'Constants'
import { useMessagesForClient } from 'shared/hooks'

const ClientIcon = () => {
  const client_id = Utils.token.get().sub
  const [unread_message_count, set_unread_message_count] =
    Hooks.useContextState('unread_message_count', 0)
  const navigate = useNavigate()
  const { rows: requests } = useMessagesForClient(client_id)
  React.useEffect(() => {
    if (requests) {
      let newMessageCount = 0
      const bid_with_messages = []
      requests.forEach((r, i) => {
        bid_with_messages.push(...r.bid.filter((b) => !!b.message.length))
      })
      bid_with_messages.forEach((bid) => {
        bid.message.forEach((msg) => {
          if (msg.to_id === client_id && !msg.viewed_at) {
            newMessageCount++
          }
        })
      })
      // console.log('requests Updated:', newMessageCount)
      set_unread_message_count(newMessageCount)
    }
    // eslint-disable-next-line
  }, [requests])

  // console.log('ClientIcon', { requests })
  return (
    <IconButton color="inherit" onClick={() => navigate(K.route.messages.href)}>
      <Badge badgeContent={unread_message_count} color="error">
        <NotificationsOutlined />
      </Badge>
    </IconButton>
  )
}

export default ClientIcon
