import 'react-perfect-scrollbar/dist/css/styles.css'
import React from 'react'
import { useRoutes } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import GlobalStyles from 'components/GlobalStyles'
import GlobalSnack from 'components/Basic/GlobalSnack'
import theme from 'theme'
import routes from './routes'

import './style/App.css'
import './style/Colors.css'

const App = () => {
  const routing = useRoutes(routes)
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <GlobalSnack />
      {routing}
    </ThemeProvider>
  )
}

export default App
