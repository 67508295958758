const NameDisplay = ({ user, me_id, is_client }) => {
  if (!user) {
    return null
  }
  if (user.id === me_id) {
    return 'Me'
  }
  if (is_client) {
    return 'Carrier'
    // return `Carrier #${user.id}`
  }
  const f = user.firstname
  const l = user.lastname
  return `${f} ${l.charAt(0)}.`
}

export default NameDisplay
