import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
// import {
//   Box,
//   Button,
//   Container,
//   Link
// } from '@material-ui/core';
import SimpleCard from 'components/Basic/SimpleCard';
import SimpleGrid from 'components/Basic/SimpleGrid';
import FlyField from 'components/Basic/FlyField';
import K from 'Constants';

const SettingForm = ({ setting, save, cancel }) => {
  return (
    <Formik
      initialValues={setting}
      validationSchema={Yup.object().shape({
        setting: Yup.string().required('Setting is required'),
      })}
      onSubmit={(values,opt) => {
        console.log(values);
        save(values);
      }}
    >
      {props => {
        return (
          <form onSubmit={props.handleSubmit}>
            <SimpleCard
              header={setting.title}
              headeraction={{
                icon: <K.icon.remove />,
                onClick: cancel
              }}
              actionsRight={[
                { label: (<><K.icon.save className="mr1" /> Save</>), onClick: props.handleSubmit},
                // { label: 'Cancel', onClick: cancel}
              ]}
            >
              <SimpleGrid items={[
                { content: <FlyField name="setting" label="Setting" type="textarea" {...props} /> },
              ]}/>
            </SimpleCard>
          </form>
        )}
      }
    </Formik>
  );
};

export default SettingForm;
