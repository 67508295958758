import React from 'react'
import { FieldDef, SimpleForm } from 'components/Basic'
import K from 'Constants'
import shared from 'shared/constants'

const BidForm = ({ request, onSave, onCancel, model = {}, ...props }) => {
  const isCharter = request.type === shared.flight_type.charter
  const initialValues = {
    cost: model.cost,
    fly_date: isCharter ? request.start_date : model.fly_date,
  }
  return (
    <SimpleForm
      header={shared.label.bid.form_header}
      model={initialValues}
      fields={[
        FieldDef.name('fly_date')
          .label(shared.label.bid.fly_date)
          .date({ minDate: request.start_date })
          .disabled(isCharter)
          .required()
          .get(6),
        FieldDef.name('cost')
          .label('Cost')
          .number()
          .prepend('$')
          .required()
          .get(6),
        // FieldDef.name('message')
        //   .label(shared.label.bid.optional_message)
        //   .get(),
      ]}
      saveIcon={K.icon.check}
      saveLabel={
        model.status === shared.status.CLOSED
          ? shared.label.bid.resubmit
          : shared.label.bid.submit
      }
      save={onSave}
      cancel={onCancel}
      {...props}
    />
  )
}

export default BidForm
