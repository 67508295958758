import React from 'react';
import { Hooks } from '@jesseteal/teal-react';

/*
  Displays as full height block if on initial screen

  usage:

    <MaxBox mb={20}>
      scrolled content here, mb is margin-bottom, default 0
    </MaxBox>
 */
const MaxBox = ({children, mb = 0, style = {}}) => {
  const ref = React.useRef(null);
  const { top } = Hooks.usePosition(ref);
  const { height} = Hooks.useWindowSize();
  const fudge_factor = 25; // fixed header
  const _style = {
    height: (top && top < height) ? (height - top - mb - fudge_factor) + 'px' : 'auto',
    overflow: 'auto',
    ...style
  }
  return (
    <div ref={ref} style={_style}>
      {children}
    </div>
  );
};

export default MaxBox;
