import { schema } from './fields';

export const AdminRequestSchema = `
  ${schema['request']}
  from_id_user {
    id
    firstname
    lastname
    phone
    email
  }
  request_cargo {
    ${schema['request_cargo']}
  }
  request_image {
    id
    url
    caption
  }
  request_passenger {
    ${schema['request_passenger']}
  }
  from_airport_id_airport{
    id name code
  }
  to_airport_id_airport{
    id name code
  }
  bid(where: $whereBid,order:"fly_date"){
    ${schema['bid']}
  }
`;
export const BasicRequestSchema = `
  ${schema['request']}
  request_cargo {
    ${schema['request_cargo']}
  }
  request_image {
    id
    url
    caption
  }
  request_passenger {
    ${schema['request_passenger']}
  }
  from_airport_id_airport{
    id name code
  }
  to_airport_id_airport{
    id name code
  }
  bid(where: $whereBid,order:"fly_date"){
    ${schema['bid']}
  }
`;
