import { Utils } from '@jesseteal/teal-react'
import shared from 'shared/constants'

export const isUser = () => {
  // return Utils.token.hasRole([shared.role.user, shared.role.test_user])
  return Utils.token.hasRole(shared.role.user)
}

export const isProvider = () => {
  return Utils.token.hasRole([shared.role.provider, shared.role.test_provider])
}

export const isAdmin = () => {
  return Utils.token.hasRole(shared.role.admin)
}

export const isTestRole = () => {
  return Utils.token.hasRole([shared.role.test_user, shared.role.test_provider])
}

export const baseRole = () => {
  if (isUser()) {
    return shared.role.user
  } else if (isProvider()) {
    return shared.role.provider
  } else if (isAdmin()) {
    return shared.role.admin
  }
  return undefined
}
