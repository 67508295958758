import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'
import SimpleCardActions from './SimpleCardActions'
import SimpleDeleteConfirm from './SimpleDeleteConfirm'
import K from 'Constants'
/*
Usage Example

import { SimpleTable, Menubar, SimpleForm } from 'components';

const columns = [
  {
    header: 'Email',
    cell: 'email',
    sort: 'email',
    align: 'left'
  }
];

<SimpleTable data={list} columns={columns}/>
*/

const SimpleTable = (props) => {
  let { data, columns, summaryRow, header, subheader, row_limit = 10 } = props
  const [rowsPerPage, setRowsPerPage] = React.useState(row_limit)
  const [onConfirmDelete, setOnConfirmDelete] = React.useState(null)
  const [page, setPage] = React.useState(0)
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState(-1)

  React.useEffect(() => {
    setPage(0) // reset page
  }, [data])

  if (!data || !columns) {
    return null
  }

  if (orderBy > -1 && columns[orderBy].sort !== false) {
    let { sort, cell, header } = columns[orderBy]
    data = _.sortBy(data, sort || cell || header.toLowerCase())
    if (order !== 'asc') {
      data = _.reverse(data)
    }
  }
  const start = page * rowsPerPage
  const end = start + rowsPerPage
  const Content = props.fullWidth
    ? ({ children }) => <Box>{children}</Box>
    : CardContent
  return (
    <Card className={props.className}>
      {(header || subheader) && (
        <>
          <CardHeader
            action={
              props.headeraction ? (
                <IconButton onClick={props.headeraction.onClick}>
                  {props.headeraction.icon}
                </IconButton>
              ) : null
            }
            subheader={subheader}
            title={header}
          />
          <Divider />
        </>
      )}
      <Content style={props.minHeight ? { minHeight: props.minHeight } : null}>
        <PerfectScrollbar>
          <Table size={props.size || 'small'}>
            <TableHead>
              <TableRow>
                {columns.map((c, i) => {
                  if (c.sort !== false && !c.navTo && !c.action && !c.delete) {
                    return (
                      <TableCell
                        key={i}
                        align={c.align}
                        style={c.style || null}
                      >
                        <TableSortLabel
                          active={orderBy === i}
                          direction={order}
                          onClick={(event) => {
                            const gotoDesc = orderBy === i && order === 'asc'
                            setOrder(gotoDesc ? 'desc' : 'asc')
                            setOrderBy(i)
                          }}
                        >
                          {c.header}
                        </TableSortLabel>
                      </TableCell>
                    )
                  }
                  return (
                    <TableCell key={i} align={c.align} style={c.style || null}>
                      {c.header}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(!data || data.length === 0) && (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    No records found.
                  </TableCell>
                </TableRow>
              )}
              {data &&
                data
                  // .filter(row => { // tbd
                  //   if(props.hide){
                  //     return props.hide(row);
                  //   }
                  // })
                  .slice(start, end)
                  .map((row, row_index) => (
                    <TableRow
                      hover
                      key={row.id || `row${row_index}`}
                      className={props.row_action ? 'clickable' : null}
                      onClick={
                        props.row_action ? () => props.row_action(row) : null
                      }
                    >
                      {columns.map((c, i) => {
                        if (typeof c.cell === 'function') {
                          return (
                            <TableCell key={i} align={c.align}>
                              {c.cell(row)}
                            </TableCell>
                          )
                        } else if (c.navTo) {
                          return (
                            <TableCell key={i} align={c.align}>
                              <NavLink to={c.navTo(row)}>
                                <IconButton size="small">
                                  <K.icon.more />
                                </IconButton>
                              </NavLink>
                            </TableCell>
                          )
                        } else if (c.action) {
                          return (
                            <TableCell key={i} align={c.align}>
                              <IconButton
                                size="small"
                                onClick={() => c.action(row)}
                                {...(c.icon_props?.(row) || {})}
                              >
                                {c.icon ? c.icon(row) : <K.icon.more />}
                              </IconButton>
                            </TableCell>
                          )
                        } else if (c.delete) {
                          return (
                            <TableCell key={i} align={c.align}>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setOnConfirmDelete({
                                    action: () => {
                                      c.delete(row)
                                      setOnConfirmDelete(null)
                                    },
                                  })
                                }}
                              >
                                {c.icon ? (
                                  c.icon(row)
                                ) : (
                                  <K.icon.delete color="#f44336" />
                                )}
                              </IconButton>
                            </TableCell>
                          )
                        }
                        return (
                          <TableCell key={i} align={c.align}>
                            {row[c.cell || c.header.toLowerCase()]}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
              {summaryRow && (
                <TableRow>
                  {summaryRow.map((c, i) => {
                    return (
                      <TableCell
                        key={i}
                        colSpan={c.colSpan || 1}
                        align={c.align}
                      >
                        <strong>{c.cell(data)}</strong>
                      </TableCell>
                    )
                  })}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Content>
      {!!onConfirmDelete && (
        <SimpleDeleteConfirm
          onDelete={onConfirmDelete.action}
          onCancel={() => setOnConfirmDelete(null)}
        />
      )}
      {data.length > rowsPerPage && (
        <>
          <Divider />
          <CardActions>
            <TablePagination
              component="div"
              count={data.length}
              onPageChange={(event, page) => {
                setPage(page)
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(event.target.value)
              }}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </CardActions>
        </>
      )}
      <SimpleCardActions
        actionsLeft={props.actionsLeft}
        actionsRight={props.actionsRight}
      />
    </Card>
  )
}

export default SimpleTable
