import { Utils } from '@jesseteal/teal-react'

const flight_type = {
  charter: 'Charter',
  passenger: 'Passenger',
  cargo: 'Cargo',
}

export default {
  color: {
    STATUS_OPEN: 'green',
    STATUS_PENDING: 'orange',
    STATUS_ASSIGNED: 'green',
    STATUS_UNKNOWN: 'gray',

    background: '#F4F6F8',
    primary: '#025b5b',
    secondary: '#08888D',

    delete: '#900',

    tile_icon_color: 'rgb(2,90,90)',
    tile_icon_background: 'rgb(2,90,90,.1)',
    // from mobile
    PRIMARY: 'rgb(2,90,90)',
    SECONDARY: '#08888D',

    PAPER: '#E0DCD4',
    FIELD_BORDER: '#666',
    FIELD_BACKGROUND: '#F6F6F6',

    DISABLED: '#E0DCD4',
    MUTED: '#666',
    ERROR_BACKGROUND: 'rgba(214,90,90,.1)',
    ERROR_TEXT: 'rgb(214,90,90)',
    SUCCESS_BACKGROUND: 'rgba(90,214,90,.1)',
    SUCCESS_TEXT: 'rgb(90,214,90)',

    ERROR_BUTTON_BACKGROUND: 'rgba(200,90,90,.9)',
    ERROR_BUTTON_CONFIRM_BACKGROUND: 'rgba(250,150,50,.9)',

    GRAY: '#999',
    DGRAY: '#333',
    LGRAY: '#CCC',
  },
  content: {
    markdown: 1,
    home: 2,
    client_help: 3,
    provider_help: 4,
    terms: 5,
    capacity_help: 6,
    payment_info: 7,
    charter_a_flight: 8,
    book_a_flight: 9,
    preflight_guide: 10,
    privacy_policy: 11,
    registration_received: 12,
  },
  flight_type,
  label: {
    account: {
      subheader: 'Account Information',
      header: 'Profile',
    },
    admin_airports: {
      header: 'Manage Airports',
      subheader: 'Create and Edit Available Airports',
      form_header: 'Airport Details',
      form_subheader: 'Edit airport details below',
    },
    admin_users: {
      header: 'User Accounts',
    },
    admin_content: {
      header: 'Manage Content',
      subheader: 'Edit text content used in App',
    },
    admin_settings: {
      header: 'Manage Settings',
      subheader: 'Control App Settings',
    },
    register_as_provider: 'Register as a Pilot / Air Carrier',
    bid: {
      header: 'Offers',
      form_header: 'Submit Quote',
      view_header: 'Quote',

      // view_subheader:
      // fields
      cost: 'Cost',
      fly_date: 'Flight Date',
      status: 'Status',
      withdraw_action: 'Withdraw Quote',
      withdraw_header: 'Withdraw Quote',
      withdraw_message: 'This action will void your quote.',
      change_action: 'Edit Quote',
      reopen_action: 'Re-Open Quote',
      optional_message: 'Message to Shipper (Optional)',
      submit: 'Submit',
      resubmit: 'Re-Open Quote',
    },
    cargo: {
      // header: 'Shipments',
      // subheader: 'Open Shipments',
      form_header: 'Shipment Details',
      form_subheader: 'Enter your shipping information',
      header: 'Shipments',
      packages_section_header: 'Packages',
      provider_header: 'Open Shipments',
      subheader: 'Open Shipments',
      submit: 'Send out to Quote',
      view_header: 'Shipment Details',
      view_subheader: null,
      // fields
      start_date: 'Preferred Ship Date',
      from_airport_id: 'From',
      to_airport_id: 'To',
      identifier: 'Remora #',
      package_required: 'At least 1 package is required',
      identifier_value: (request) => {
        // console.log('identifier_value', { request })
        const type =
          request.type === flight_type.cargo
            ? 'S'
            : request.type === flight_type.charter
            ? 'C'
            : 'P'
        // ${Number(Utils.date.format(request.start_date, 'yy'))
        //   .toString(36)
        //   .toUpperCase()}
        return `${type}-${Utils.padNumber(request.id, 3)}`
      },
    },
    carrierAcceptResponseHint:
      'Write a message that will be shown when a Quote is accepted. (Optional)',
    carrierAcceptResponseLabel: 'Initial Response',
    carrierDropoffHint:
      'Provide Drop-Off Instructions. This will be displayed once a Quote has been accepted. (Optional)',
    carrierDropoffLabel: 'Drop Off Information',
    carrierInfoHeader: 'Carrier Information',
    carrierInfoSubheader: 'Information provided when a Quote is accepted',
    dropoffInstructions: 'Drop-Off Instructions',
    flight: {
      provider_header: 'Open Flights',
      start_date: 'Travel Date',
      passenger_required: 'At least 1 passenger is required',
    },
    message: {
      header: 'Messages',
      subheader: 'Recent messages first',
    },
    provider_airports: {
      header: 'Served Airports',
      subheader: 'Select airports you service.',
    },
  },
  role: {
    admin: 'Admin',
    provider: 'Provider',
    user: 'User',
    test_user: 'Test_User',
    test_provider: 'Test_Provider',
  },
  status: {
    // Request, Flight, and Bid Status
    OPEN: 'Open',
    CLOSED: 'Closed',

    // Request Status
    PENDING: 'Pending', // Open and offer made
    ASSIGNED: 'Assigned', // Closed and assigned
    COMPLETED: 'Completed', // business transaction completed

    // Bid only?
    ACCEPTED: 'Accepted', // Closed and accepted
  },
}
