import React from 'react'
import { AppBar, Tab, Tabs } from '@material-ui/core'
import { Utils } from '@jesseteal/teal-react'
/* usage
<SimpleTabs
  tabs={[
    {
      label: 'Org Info',
      content: (<x></x>)
    }
  ]}
/>
*/

const SimpleTabs = ({ tabs, activeTab = 0, onChange }) => {
  const [tab, setTab] = React.useState(Number(activeTab))
  React.useEffect(() => {
    setTab(Number(activeTab)) // update state when params change
  }, [activeTab])
  return (
    <AppBar position="static" color="default">
      <Tabs
        value={tab}
        onChange={(e, i) => {
          setTab(i)
          onChange && onChange(i)
        }}
        textColor="primary"
      >
        {tabs.map((t, i) => {
          if (t.role && !Utils.token.hasRole(t.role)) return null
          return <Tab label={t.label} key={i} />
        })}
      </Tabs>
      {tabs[tab].content}
    </AppBar>
  )
}

export default SimpleTabs
