import { Api } from '@jesseteal/teal-react';
import { schema } from '../schema';

export const useProviders = (
  where = { status: { in: ['New', 'Approved'] } },
) => {
  const results = Api.useGraphQuery(
    `query ManageNewCarrier($where: String){
      provider(where: $where){
        id name contact email phone status
        provider_user{
          user_id_user{
            id
            firstname
            lastname
            email
          }
        }
      }
    }`,
    {
      networkOnly: true,
      variables: {
        where: JSON.stringify(where),
      },
    },
  );
  return {
    ...results,
    rows: results.data?.provider,
  };
};

export const useProviderInfo = ({ id, provider_id }) => {
  const sql = provider_id
    ? `query CarrierInfo($where:String){
        provider(where:$where){ ${schema['provider']} }
      }`
    : `query CarrierUserInfo($where:String){
      user(where: $where){
        provider_user{
          provider_id_provider{
            ${schema['provider']}
          }
        }
      }
    }`;
  const results = Api.useGraphQuery(sql, {
    variables: {
      where: JSON.stringify({
        id: id || provider_id,
      }),
    },
  });
  const info = provider_id
    ? results.data?.provider?.[0]
    : results.data?.user?.[0]?.provider_user?.[0]?.provider_id_provider;
  return {
    ...results,
    info,
  };
};

export const useProviderDropOff = (id) => {
  const results = Api.useGraphQuery(
    `query DropOff($where:String){
      user(where:$where){
        id email phone address city zip notes
      }
    }`,
    { variables: { where: JSON.stringify({ id }) } },
  );
  return {
    ...results,
    user: results.data?.user?.[0],
  };
};
