import React from 'react'
import { Typography } from '@material-ui/core'

// const text = (content) => {
//   let t = {
//     content,
//     color: 'textPrimary',
//     variant: 'body1',
//     small: () => {
//       t.variant = 'body2'
//       return t;
//     }
//   }
//   return t;
// }
// variants: h1 - h6, subtitle1, subtitle2, body1, body2, button, caption, overline
// colors: primary, secondary, textPrimary, textSecondary, error
// attr: gutterBottom
//
// Box colors:
//  primary.main, secondary.main, error.main, warning.main, info.main, success.main,
//  text.primary, text.secondary, text.disabled

const body = t => (
  <Typography color="textPrimary" variant="body1">
    {t}
  </Typography>
)
const body2 = t => (
  <Typography color="textSecondary" variant="body2">
    {t}
  </Typography>
)
const label = t => (
  <Typography color="textSecondary" variant="subtitle1">
    {t}
  </Typography>
)
const header = t => (
  <Typography color="textSecondary" variant="overline">
    {t}
  </Typography>
)
const subheader = t => (
  <Typography color="textSecondary" variant="body2">
    {t}
  </Typography>
)
const h2 = t => (
  <Typography color="textPrimary" variant="h2">
    {t}
  </Typography>
)
const h3 = t => (
  <Typography color="textPrimary" variant="h3">
    {t}
  </Typography>
)
const h4 = t => (
  <Typography color="textPrimary" variant="h4">
    {t}
  </Typography>
)
const h5 = t => (
  <Typography color="textPrimary" variant="h5">
    {t}
  </Typography>
)

const label_value_justified = (label, value, key = 0) => (
  <div className="row" key={key}>
    <div className="col_md">{header(label)}</div>
    <div className="col tr">{body(value)}</div>
  </div>
)

const label_value_stack = (label, value) => (
  <div>
    {header(label)}
    {body(value)}
  </div>
)

export default {
  page: {
    header: t => (
      <Typography color="textPrimary" variant="h2">
        {t}
      </Typography>
    ),
  },
  caption: t => (
    <Typography variant="caption" color="textSecondary">
      {t}
    </Typography>
  ),
  body,
  body2,
  label,
  header,
  subheader,
  h2,
  h3,
  h4,
  h5,
  label_value_justified,
  label_value_stack,
  form: {
    label: t => (
      <Typography color="textSecondary" variant="subtitle1">
        {t}
      </Typography>
    ),
    header: t => (
      <Typography color="textSecondary" variant="overline">
        {t}
      </Typography>
    ),
  },
  tile: {
    header: t => (
      <Typography
        className="tr"
        color="textSecondary"
        variant="h6"
        gutterBottom
      >
        {t}
      </Typography>
    ),
    value: t => (
      <Typography className="tr" color="textPrimary" variant="h3">
        {t}
      </Typography>
    ),
  },
  error: t => (
    <Typography color="error" variant="body1">
      {t}
    </Typography>
  ),
  poopoo: t => (
    <>
      {header('header')}
      {subheader('subheader')}
      {body('body')}
      {body2('body2')}
      {label('label')}
      {h2('h2')}
      {h3('h3')}
      {h4('h4')}
      {h5('h5')}
      {label_value_stack('label/value', 'stack')}
      {label_value_justified('label/value', 'justified')}
    </>
  ),
}
