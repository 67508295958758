import React from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { Container } from '@material-ui/core'
import { Api, Hooks, Utils } from '@jesseteal/teal-react'
import Page from 'components/Page'
import ClientCargoForm from './ClientCargoForm'
import { SimpleTable } from 'components/Basic'
import RequestStatus from 'components/RequestStatus'
import K from 'Constants'
import shared from 'shared/constants'
import { useClientRequests } from 'shared/hooks'

const ClientCargo = () => {
  const navigate = useNavigate()
  const [dismissed, set_dismissed] = Hooks.useContextState('dismissed', false)
  const [pay_enabled] = Hooks.useContextState('pay_enabled')
  const set_snack = Hooks.useSetContextState('snack')
  const saveRequest = Api.useSave('request')
  const saveCargo = Api.useSave('request_cargo')
  const [model, set_model] = React.useState(null)

  const id = Utils.token.get().sub
  const payment_is_setup = pay_enabled ? Utils.token.get().sid : true
  const { rows: requests, refetch } = useClientRequests({
    from_id: id,
    deleted_at: null,
    type: shared.flight_type.cargo,
  })
  return (
    <Page>
      <Container maxWidth="lg">
        {model && payment_is_setup ? (
          <ClientCargoForm
            model={model}
            save={(values, cargo) => {
              // console.log({ modified, values })
              saveRequest(values, true).then(
                async ({ createrequest: request_id }) => {
                  for (var p of cargo) {
                    await saveCargo({ ...p, request_id }, true)
                  }
                  // console.log('aftersave', request_id)
                  set_model(null)
                  refetch()
                }
              )
            }}
            onCancel={() => set_model(null)}
          />
        ) : (
          <SimpleTable
            fullWidth
            header={shared.label.cargo.header}
            subheader={shared.label.cargo.subheader}
            headeraction={{
              icon: <K.icon.add />,
              onClick: () => {
                if (!payment_is_setup) {
                  if (!dismissed) {
                    set_snack(K.snack.payment_required)
                  }
                  set_dismissed(false)
                } else {
                  set_model({ from_id: id, status: 'Open', type: 'Cargo' })
                }
              },
            }}
            data={requests}
            row_action={(r) => navigate(K.route.shipment.href(r.id))}
            columns={[
              {
                header: 'Description',
                cell: (r) =>
                  r.request_cargo.map((c) => c.description).join(', '),
              },
              {
                header: 'From',
                cell: (r) => Utils.resolve(r, 'from_airport_id_airport.name'),
              },
              {
                header: 'To',
                cell: (r) => Utils.resolve(r, 'to_airport_id_airport.name'),
              },
              {
                header: 'Status',
                cell: (r) => <RequestStatus request={r} />,
              },
              {
                header: 'Quotes',
                cell: (r) => {
                  const non_closed = r.bid.filter(
                    (b) => b.status !== shared.status.CLOSED
                  )
                  const win = r.bid.find(
                    (b) => b.status === shared.status.ACCEPTED
                  )
                  if (win) {
                    return Utils.formatMoney(
                      win.cost + (pay_enabled ? win.client_fee : 0)
                    )
                  } else if (non_closed.length) {
                    if (non_closed.length === 1) {
                      return Utils.formatMoney(
                        non_closed[0].cost +
                          (pay_enabled ? non_closed[0].client_fee : 0)
                      )
                    } else {
                      const min = _.minBy(non_closed, (r) => r.cost)
                      const max = _.maxBy(non_closed, (r) => r.cost)
                      if (max.cost === min.cost) {
                        return Utils.formatMoney(min.cost)
                      }
                      return `${Utils.formatMoney(
                        min.cost + (pay_enabled ? min.client_fee : 0)
                      )} - ${Utils.formatMoney(
                        max.cost + (pay_enabled ? max.client_fee : 0)
                      )}`
                    }
                  }
                  return non_closed.length || ''
                },
                align: 'center',
              },
              // {
              //   action: (r) => navigate(K.route.shipment.href(r.id)),
              // },
            ]}
          />
        )}
      </Container>
    </Page>
  )
}

export default ClientCargo
