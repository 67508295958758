import React from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import T from 'components/Typo'

const SimpleDeleteConfirm = ({ onDelete, onCancel, message = null }) => {
  if (typeof onDelete !== 'function') {
    return null
  }

  return (
    <Dialog open={true} onClose={onCancel}>
      <DialogTitle>{T.header('Confirm Delete')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message
            ? message
            : 'Data will be lost. This action cannot be undone.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="default">
          Cancel
        </Button>
        <Button onClick={onDelete} color="secondary" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SimpleDeleteConfirm
