import {
  ArrowRightCircle,
  Award,
  Home,
  BarChart,
  Briefcase,
  Calendar,
  CheckCircle,
  Circle,
  ChevronLeft,
  ChevronRight,
  Command, // apple command icon
  DollarSign,
  Edit,
  FileText,
  // Lock as LockIcon,
  LogIn,
  LogOut,
  MapPin,
  Menu,
  MessageCircle,
  MoreHorizontal,
  // Navigation,
  Package,
  Plus,
  Save,
  Search,
  Settings,
  Send,
  ShoppingBag,
  Slash,
  Trash2,
  // User,
  // UserPlus as UserPlusIcon,
  Users,
  X,
} from 'react-feather'
import {
  AccountBalance,
  AccountCircle,
  EventSeat,
  FlightTakeoff,
  Payment,
} from '@material-ui/icons'

export default {
  accept: CheckCircle,
  action: MoreHorizontal,
  add: Plus,
  admin: Command,
  airport: MapPin,
  bank: AccountBalance,
  bid: Award,
  calendar: Calendar,
  cancel: Slash,
  chart: BarChart,
  charter_a_flight: FlightTakeoff,
  check: CheckCircle,
  content: Edit,
  credit: Payment,
  dashboard: BarChart,
  delete: Trash2,
  dev: Command,
  edit: Edit,
  find_a_flight: EventSeat,
  flights: Send,
  goto: ArrowRightCircle,
  left: ChevronLeft,
  login: LogIn,
  logout: LogOut,
  manifest: Briefcase, // active requests
  menu: Menu,
  messages: MessageCircle,
  money: DollarSign,
  more: MoreHorizontal,
  package: Package,
  payment: DollarSign,
  planes: Home, // provider plane management
  register: FileText,
  remove: X,
  requests: FileText,
  right: ChevronRight,
  save: Save,
  search: Search,
  send: Send,
  settings: Settings,
  shopping: ShoppingBag,
  terms: FileText,
  uncheck: Circle,
  user: AccountCircle,
  users: Users,
}
