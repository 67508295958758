import React from 'react'
// import { useNavigate } from 'react-router-dom';
import { Container } from '@material-ui/core'
import Page from 'components/Page'

const ManageClientUsers = () => {
  // const navigate = useNavigate();
  return (
    <Page>
      <Container maxWidth="lg">ManageClientUsers</Container>
    </Page>
  )
}

export default ManageClientUsers
