import React from 'react'
import { Api, Utils } from '@jesseteal/teal-react'
import { SimpleCard, MaxBox } from 'components/Basic'
import Loading from 'components/Loading'
import { useProviderAirports } from 'shared/hooks'
import { Checkbox, FormControlLabel } from '@material-ui/core' // import Page from 'components/Page';
import constants from 'shared/constants'

const ProviderAirports = ({
  header = constants.label.provider_airports.header,
  subheader = constants.label.provider_airports.subheader,
}) => {
  const { data, ready, refetch } = useProviderAirports(Utils.token.get().sub)
  const save = Api.useSave('provider_airport', 'airport')
  const remove = Api.useDelete('provider_airport', 'airport')
  const [busy, set_busy] = React.useState(0)
  const provider_id = Utils.token.get().sub

  return (
    <SimpleCard
      noGutter
      header={header}
      subheader={subheader}
      content_style={{ paddingLeft: 24 }}
    >
      {!ready && <Loading />}
      {ready && (
        <MaxBox mb={20}>
          {data.airport.map((airport, i) => {
            return (
              <div key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color={true ? 'primary' : 'default'}
                      checked={!!airport.provider_airport.length}
                      disabled={busy === airport.id}
                      onChange={
                        (e) => {
                          set_busy(airport.id)
                          if (e.target.checked) {
                            save(
                              { provider_id, airport_id: airport.id },
                              true
                            ).then(() => set_busy(0))
                          } else {
                            remove({ id: airport.provider_airport[0].id }).then(
                              () => {
                                refetch().then(() => set_busy(0))
                              }
                            )
                          }
                        }
                        // edit && setValues({ [field.name]: e.target.checked ? 1 : null })
                      }
                    />
                  }
                  label={`${airport.name}`}
                />
              </div>
            )
          })}
        </MaxBox>
      )}
    </SimpleCard>
  )
}

export default ProviderAirports
