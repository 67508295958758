import React from 'react'
import { Button } from '@material-ui/core'
import { SimpleDialog, SimpleTable } from 'components/Basic'
import SimpleForm from 'components/Basic/SimpleForm'
import FieldDef from 'components/Basic/FieldDef'
import Typo from 'components/Typo'
import K from 'Constants'
import shared from 'shared/constants'
import { Utils } from '@jesseteal/teal-react'

const ClientFlightForm = ({ save, cancel, type }) => {
  const [open, set_open] = React.useState(false)
  const [passengers, set_passengers] = React.useState([])
  const [passenger_error, set_passenger_error] = React.useState(false)
  const fields = () => {
    const config = [
      FieldDef.column({
        size: 6,
        fields: [
          FieldDef.header('Flight Details').get(),
          FieldDef.name('start_date')
            .label('Travel Date')
            .date({ minDate: new Date() })
            .required()
            .get(),
          FieldDef.name('from_airport_id')
            .label('From')
            .foreignKey({ table: 'airport' })
            .required()
            .get(),
          FieldDef.name('to_airport_id')
            .label('To')
            .foreignKey({ table: 'airport' })
            .required()
            .get(),
          // FieldDef.name('description')
          //   .label('Additional Information')
          //   .lines(4)
          //   .get(),
        ],
      }),
      FieldDef.column({
        size: 6,
        fields: [
          FieldDef.header('Passengers').get(),
          FieldDef.content(
            <SimpleTable
              fullWidth
              data={passengers}
              columns={[
                { header: 'First', cell: 'firstname' },
                { header: 'Last', cell: 'lastname' },
                { header: 'Weight (lbs)', cell: 'weight', align: 'right' },
                { header: 'Luggage (lbs)', cell: 'luggage', align: 'right' },
              ]}
              summaryRow={[
                { cell: () => 'Total', colSpan: 2 },
                {
                  align: 'right',
                  cell: (data) =>
                    data.reduce((a, c) => a + Number(c.weight), 0),
                },
                {
                  align: 'right',
                  cell: (data) =>
                    data.reduce((a, c) => a + Number(c.luggage), 0),
                },
              ]}
            />
          ).get(),
          FieldDef.content(
            <Button color="primary" onClick={() => set_open(true)}>
              <K.icon.add /> Add Passenger
            </Button>
          ).get(),
          FieldDef.content(
            Typo.error(
              passenger_error ? 'At least 1 passenger is required' : ''
            )
          ).get(),
        ],
      }),
    ]
    return config
  }
  return (
    <>
      <SimpleForm
        cancel={cancel}
        model={{
          from_id: Utils.token.get().sub,
          type,
        }}
        save={(mod, vals) => {
          if (passengers.length) {
            save(vals, passengers)
          } else {
            set_passenger_error(true)
          }
        }}
        saveLabel={shared.label.cargo.submit}
        header={
          type === shared.flight_type.charter
            ? 'Charter a Flight'
            : 'Find Available Seats'
        }
        fields={fields()}
      />
      <SimpleDialog open={open} onClose={() => set_open(false)} noGutter>
        <SimpleForm
          header="Add Passenger"
          cancel={() => set_open(false)}
          model={
            passengers.length
              ? null
              : {
                  firstname: Utils.token.get().f,
                  lastname: Utils.token.get().l,
                  user_id: Utils.token.get().sub,
                }
          }
          save={(modified, values) => {
            set_passengers(passengers.concat(values))
            set_open(false)
          }}
          fields={[
            // FieldDef.header('Passenger Information').get(),
            FieldDef.name('firstname').label('First Name').required().get(),
            FieldDef.name('lastname').label('Last Name').required().get(),
            FieldDef.name('weight')
              .label('Passenger Weight')
              .prepend('lbs')
              .number()
              .required()
              .get(),
            FieldDef.name('luggage')
              .label('Luggage Weight')
              .prepend('lbs')
              .number()
              .required()
              .get(),
          ]}
        />
      </SimpleDialog>
    </>
  )
}

export default ClientFlightForm
