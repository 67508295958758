import React from 'react'
import { Button } from '@material-ui/core'
import { SimpleDialog, SimpleForm } from 'components/Basic'
import FieldDef from 'components/Basic/FieldDef'
import K from 'Constants'
import shared from 'shared/constants'
import T from 'components/Typo'
import CargoItemTable from './CargoItemTable'

const ClientCargoForm = (props) => {
  const { model, save, onCancel, ...other } = props
  const [open, set_open] = React.useState(false)
  const [cargo, set_cargo] = React.useState([])
  const [cargo_error, set_cargo_error] = React.useState(false)
  // const [show_hazmat, set_show_hazmat] = React.useState(!!model.hazmat)
  return (
    <>
      <SimpleForm
        header={shared.label.cargo.form_header}
        subheader={props.displayOnly ? null : shared.label.cargo.form_subheader}
        {...other}
        model={model}
        editMode={!model.id}
        displayOnly={props.displayOnly}
        save={async (mod, vals) => {
          if (cargo.length) {
            save(vals, cargo)
          } else {
            set_cargo_error(true)
          }
        }}
        saveLabel={shared.label.cargo.submit}
        cancel={onCancel}
        actionsLeft={props.actionsLeft}
        fields={[
          {
            column: {
              size: 6,
              fields: [
                { header: 'Details' },
                FieldDef.name('start_date')
                  .label(shared.label.cargo.start_date)
                  .date({ minDate: new Date() })
                  .required()
                  .get(),
                FieldDef.name('from_airport_id')
                  .label('From')
                  .foreignKey({ table: 'airport' })
                  .required()
                  .get(),
                FieldDef.name('to_airport_id')
                  .label('To')
                  .foreignKey({ table: 'airport' })
                  .required()
                  .get(),
              ],
            },
          },
          FieldDef.column({
            size: 6,
            fields: [
              FieldDef.header('Packages').get(),
              FieldDef.content(<CargoItemTable cargo={cargo} />).get(),
              FieldDef.content(
                <Button color="primary" onClick={() => set_open(true)}>
                  <K.icon.add /> Add Package
                </Button>
              ).get(),
              FieldDef.content(
                T.error(cargo_error ? 'At least 1 package is required' : '')
              ).get(),
            ],
          }),
        ]}
      />
      <SimpleDialog open={open} onClose={() => set_open(false)} noGutter>
        <SimpleForm
          header="Add Package"
          cancel={() => set_open(false)}
          save={(modified, values) => {
            set_cargo(cargo.concat(values))
            set_open(false)
          }}
          fields={[
            // FieldDef.header('Passenger Information').get(),
            FieldDef.name('description').label('Description').required().get(),
            FieldDef.name('height')
              .label('Height')
              .prepend('in')
              .number()
              .required()
              .get(3),
            FieldDef.name('width')
              .label('Width')
              .prepend('in')
              .number()
              .required()
              .get(3),
            FieldDef.name('length')
              .label('Length')
              .prepend('in')
              .number()
              .required()
              .get(3),
            FieldDef.name('weight')
              .label('Weight')
              .prepend('lbs')
              .number()
              .required()
              .get(3),
            FieldDef.name('hazmat')
              .label('Contains Hazardous Material')
              .bool()
              .get(4),
            // {
            //   //color="primary"
            //   children: (
            //     <FormControlLabel
            //       control={
            //         <Checkbox
            //           name="hazmat"
            //           onChange={e => {
            //             set_show_hazmat(e.target.checked)
            //           }}
            //         />
            //       }
            //       label="Contains Hazardous Material"
            //     />
            //   ),
            //   size: 4,
            // },
            FieldDef.name('un_code')
              .label('U.N. Hazmat Code')
              .validate((values) => {
                if (values.hazmat && !values.un_code) {
                  return 'Hazmat Code is required'
                }
              })
              .hide((values) => !values.hazmat)
              .get(4),
          ]}
        />
      </SimpleDialog>
    </>
  )
}

export default ClientCargoForm
