import K from 'Constants'

export const tokenize_card = async ({ number, month, year, cvc }) => {
  const url = `${K.config.STRIPE_API}/v1/tokens`
  const body = `card[number]=${number}&card[exp_month]=${month}&card[exp_year]=${year}&card[cvc]=${cvc}`
  const t = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${K.config.STRIPE_KEY}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body,
  })
    .then(r => r.json())
    .catch(e => {
      console.log({ e })
    })
  return t
}

export const tokenize_bank = async ({
  routing_number,
  account_number,
  name,
}) => {
  const url = `${K.config.STRIPE_API}/v1/tokens`
  const body = `bank_account[country]=US&bank_account[currency]=usd&bank_account[routing_number]=${routing_number}&bank_account[account_number]=${account_number}&bank_account[account_holder_name]=${name}&bank_account[account_holder_type]=individual`
  const t = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${K.config.STRIPE_KEY}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body,
  })
    .then(r => r.json())
    .catch(e => {
      console.log({ e })
    })
  return t
}
