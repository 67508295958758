import { Api } from '@jesseteal/teal-react'
import { schema } from '../schema'

export const useAirports = (where = { deleted_at: null }) => {
  const results = Api.useGraphQuery(
    `query ManageAirports($where:String){
      airport(where:$where, order: "name"){ ${schema.airport} }
    }`,
    { variables: { where: JSON.stringify(where) } }
  )
  return {
    ...results,
    rows: results.data?.airport,
  }
}

export const useProviderAirports = (provider_id) => {
  const results = Api.useGraphQuery(
    `query useAirports($where: String){
      airport (order: "name") {
        id name code
        provider_airport(where: $where) { id }
      }
    }`,
    {
      networkOnly: true,
      variables: {
        where: JSON.stringify({
          provider_id,
        }),
      },
    }
  )
  return {
    ...results,
    rows: results.data?.airport,
  }
}
