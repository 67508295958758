import React from 'react'
import { Api, Utils } from '@jesseteal/teal-react'
import Loading from 'components/Loading'
import { SimpleCard, SimpleGrid, SimpleDeleteConfirm } from 'components/Basic'
import PhotoManager from 'components/PhotoManager'
import T from 'components/Typo'
import { useAdminRequest } from 'shared/hooks'
import shared from 'shared/constants'
import CargoItemTable from 'pages/requests/components/CargoItemTable'
import K from 'Constants'
import { isUser } from 'utils/role'

const AdminCargoDetail = ({ id, bid = null, ...props }) => {
  const [confirm_delete, set_confirm_delete] = React.useState(false)
  const { request: model } = useAdminRequest(id)
  const doSave = Api.useSave('request', 'request')
  if (!model) {
    return <Loading />
  }
  const items = [
    {
      size: 6,
      content: T.label_value_stack(
        'User',
        `${model.from_id_user.firstname} ${model.from_id_user.lastname}`
      ),
    },
    {
      size: 6,
      content: T.label_value_stack('Phone', model.from_id_user.phone),
    },
    {
      size: 12,
      content: T.label_value_stack('Email', model.from_id_user.email),
    },
    {
      size: 6,
      content: T.label_value_stack(
        shared.label.cargo.from_airport_id,
        model.from_airport_id_airport.name
      ),
    },
    {
      size: 6,
      content: T.label_value_stack(
        shared.label.cargo.to_airport_id,
        model.to_airport_id_airport.name
      ),
    },
    {
      size: 4,
      content: T.label_value_stack(
        shared.label.cargo.start_date,
        Utils.date.format(model.start_date)
      ),
    },
    {
      size: 4,
      content: T.label_value_stack(
        shared.label.cargo.identifier,
        shared.label.cargo.identifier_value(model)
      ),
    },
  ]

  if (model?.request_cargo.length) {
    items.push({
      content: T.header(shared.label.cargo.packages_section_header),
    })
    items.push({
      content: <CargoItemTable cargo={model.request_cargo} />,
    })
  }
  // if (model?.request_image?.length) {
  items.push({
    content: <PhotoManager request={model} editable={isUser()} />,
  })
  // }

  return (
    <SimpleCard
      header={shared.label.cargo.view_header}
      subheader={shared.label.cargo.view_subheader}
      actionsRight={
        model.deleted_at
          ? undefined
          : [
              {
                icon: <K.icon.delete color={shared.color.delete} />,
                label: (
                  <span style={{ color: shared.color.delete }}>Delete</span>
                ),
                onClick: () => {
                  set_confirm_delete(true)
                },
              },
            ]
      }
      {...props}
    >
      <SimpleGrid items={items} />

      {confirm_delete && (
        <SimpleDeleteConfirm
          onDelete={() => {
            set_confirm_delete(false)
            doSave({
              id,
              deleted_at: Utils.date.now(Utils.date.DB_DATETIME),
            })
            props.onBack()
            // .then(() => {
            //   refetch()
            // })
          }}
          onCancel={() => set_confirm_delete(false)}
        />
      )}
    </SimpleCard>
  )
}

export default AdminCargoDetail
