import { Api } from '@jesseteal/teal-react';
import { schema } from '../schema';

export const useBids = (where, includeAll = true) => {
  const results = Api.useGraphQuery(
    `query ClientBid($where:String){
      bid(where:$where){
        ${schema['bid']}
        ${
          includeAll
            ? `
              message{
                id
                from_id
                to_id
                viewed_at
              }
              request_id_request{
                id
                from_id
                start_date
                status
                type
                request_cargo {
                  ${schema['request_cargo']}
                }
                request_passenger {
                  ${schema['request_passenger']}
                }
                from_airport_id_airport{
                  id name code
                }
                to_airport_id_airport{
                  id name code
                }
              }
        `
            : ''
        }
      }
    }`,
    {
      networkOnly: true,
      variables: {
        where: JSON.stringify(where),
      },
    },
  );
  return {
    ...results,
    rows: results?.data?.bid,
  };
};

export const useBid = (id) => {
  const results = useBids({ id });
  return {
    ...results,
    bid: results.rows?.[0],
  };
};
