export const schema = {
  airport: 'id name code',
  content: 'id title content',
  message: 'id bid_id from_id to_id message created_at viewed_at',
  request:
    'id from_id from_airport_id to_airport_id type status start_date updated_at updated_by deleted_at',
  request_cargo: 'id weight width height length description hazmat un_code',
  request_passenger: 'id request_id firstname lastname weight luggage',
  request_image: 'id request_id url created_at',
  bid: 'id provider_id request_id fly_date status cost client_fee provider_fee client_fee_collected provider_fee_collected stripe_error updated_at updated_by deleted_at',
  provider:
    'id name contact phone email status response_accept response_dropoff',
  provider_airport: 'id provider_id airport_id',
  provider_user: 'id user_id provider_id',
  role: 'id user_id role',
  setting: 'id title setting',
  user: 'id email firstname lastname phone address city zip notes enabled notify_by_email',
}
