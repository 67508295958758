import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Link } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Page from 'components/Page'
import Terms from './Terms'
import SimpleForm from 'components/Basic/SimpleForm'
import FieldDef from 'components/Basic/FieldDef'
import K from 'Constants'
import { Api, Utils } from '@jesseteal/teal-react'
import Typo from 'components/Typo'
import { useAppSetting } from 'shared/hooks'

const RegisterView = () => {
  const navigate = useNavigate()
  const [show_terms, set_show_terms] = React.useState(false)
  const [footer, set_footer] = React.useState(null)
  const meta = useAppSetting('meta')
  const save = Api.useSave('user')
  // const save_role = Api.useSave('role')

  const fields = [
    FieldDef.name('firstname').label('First Name').required().get(6),
    FieldDef.name('lastname').label('Last Name').required().get(6),
    FieldDef.name('email').label('Email Address').email().required().get(),
    FieldDef.name('password').label('Password').password().required().get(6),
    FieldDef.name('confirm')
      .label('Confirm Password')
      .confirm()
      .required()
      .get(6),
    // FieldDef.name('provider')
    //   .label('Register as a Pilot / Air Carrier') //'Terms and Conditions'
    //   .bool()
    //   .get(6),
  ]

  if (meta?.tos) {
    fields.push(
      FieldDef.name('policy')
        .label('I have read the Terms and Conditions') //'Terms and Conditions'
        .bool()
        .required('Terms must be checked.')
        .get(6)
    )
    fields.push(
      FieldDef.content(
        <Box style={{ marginTop: '10px' }}>
          <Link href="#" onClick={() => set_show_terms(true)}>
            <K.icon.terms /> Terms &amp; Conditions
          </Link>
        </Box>
      ).get()
    )
  }

  return (
    <Page>
      <Container maxWidth="md">
        <SimpleForm
          header="Create New Account"
          subheader="Use your email to create new account"
          footer={footer}
          saveLabel="Sign up Now"
          save={(values) => {
            const user = { ...values }
            // const isProvider = !!user.provider
            delete user.policy
            delete user.provider
            delete user.confirm
            save(user, true).then(async (r) => {
              if (r.createuser === 0) {
                set_footer(
                  <Alert severity="warning">
                    Email address already in use.
                  </Alert>
                )
              } else {
                // disable db_hook if this is enabled
                // await save_role({
                //   user_id: r.createuser,
                //   role: isProvider ? 'Provider' : 'User'
                // }, true)
                Api.Post('/auth', {
                  email: user.email,
                  password: user.password,
                }).then((r) => {
                  Utils.token.set(r.token)
                  navigate(K.route.home.User, { replace: true })
                })
              }
            })
          }}
          fields={fields}
        />
        <Box mt={3}>
          {Typo.body(
            <>
              Have an account?{' '}
              <Link href="#" onClick={() => navigate(K.route.login.href)}>
                {K.route.login.label}
              </Link>
            </>
          )}
        </Box>
      </Container>
      <Terms show={show_terms} onClose={() => set_show_terms(false)} />
    </Page>
  )
}

export default RegisterView
