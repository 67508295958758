import React from 'react'
import { Badge, IconButton } from '@material-ui/core'
import { SimpleTable } from 'components/Basic'
import { useNavigate } from 'react-router-dom'
import { Container } from '@material-ui/core'
import Page from 'components/Page'
import { useMessagesForProvider } from 'shared/hooks'
import K from 'Constants'
import shared from 'shared/constants'

const ProviderMessages = ({ id }) => {
  const navigate = useNavigate()
  const { rows: bids } = useMessagesForProvider(id)

  if (!bids) {
    return null
  }
  const bidsWithMessages = bids.filter((bid) => bid.message?.length)

  return (
    <Page>
      <Container maxWidth="lg">
        <SimpleTable
          header={shared.label.message.header}
          columns={[
            {
              header: '',
              sort: false,
              style: { width: 70 },
              cell: (b) => {
                const unread = b.message.filter(
                  (m) => !m.viewed_at && m.to_id === id
                )
                return (
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={() => {
                      // if(b.request_id_request.type === shared.flight_type.cargo){
                      navigate(K.route.bid.href(b.id))
                      // }
                    }}
                  >
                    <Badge badgeContent={unread.length} color="error">
                      <K.icon.messages />
                    </Badge>
                  </IconButton>
                )
              },
            },
            {
              style: { width: 180 },
              header: shared.label.cargo.identifier,
              cell: (b) =>
                shared.label.cargo.identifier_value(b.request_id_request),
            },
            {
              header: 'Last Message',
              cell: (b) => b.message[0]?.message,
            },
          ]}
          data={bidsWithMessages}
        />
      </Container>
    </Page>
  )
}

export default ProviderMessages
