import React from 'react'
import { Utils } from '@jesseteal/teal-react'
import { Container } from '@material-ui/core'
import Page from 'components/Page'
import ProviderMessages from 'components/Message/ProviderMessages'
import ClientMessages from 'components/Message/ClientMessages'
import { isProvider, isUser } from 'utils/role'

const MessageOverview = () => {
  const [is_provider] = React.useState(isProvider())
  const [is_user] = React.useState(isUser())
  const id = Utils.token.get().sub
  if (is_provider) {
    return <ProviderMessages id={id} />
  } else if (is_user) {
    return <ClientMessages id={id} />
  }
  return (
    <Page>
      <Container maxWidth="lg">Messages are for Clients and Carriers</Container>
    </Page>
  )
}

export default MessageOverview
