import React from 'react'
import { useParams } from 'react-router-dom'
import RoleRoute from 'components/RoleRoute'
import ClientCargoDetail from './components/ClientCargoDetail'
import ProviderBidDetail from './components/ProviderBidDetail'
import shared from 'shared/constants'
import { PK } from 'utils'

const CargoDetail = () => {
  const { id } = useParams()
  return (
    <RoleRoute
      routes={{
        [shared.role.user]: <ClientCargoDetail id={PK.get(id)} />,
        [shared.role.provider]: <ProviderBidDetail id={PK.get(id)} />,
      }}
    />
  )
}

export default CargoDetail
