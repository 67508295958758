import React from 'react'
import { Container } from '@material-ui/core'
import { Utils } from '@jesseteal/teal-react'
import Page from 'components/Page'
import SimpleGrid from 'components/Basic/SimpleGrid'
import SimpleTable from 'components/Basic/SimpleTable'
import { useAdminDashboard } from 'shared/hooks'

const AdminDashboard = () => {
  const { data } = useAdminDashboard()
  if (!data) {
    return null
  }
  const values = data.report_admin_dashboard
  return (
    <Page>
      <Container maxWidth="lg">
        <SimpleGrid
          items={[
            {
              size: 4,
              content: (
                <SimpleTable
                  header="Users"
                  fullWidth
                  columns={[
                    { header: 'Type' },
                    { header: 'Total', align: 'right' },
                  ]}
                  summaryRow={[
                    { cell: () => 'Total' },
                    {
                      align: 'right',
                      cell: () => values.user_count + values.provider_count,
                    },
                  ]}
                  data={[
                    { type: 'Users', total: values.user_count },
                    { type: 'Providers', total: values.provider_count },
                  ]}
                />
              ),
            },
            {
              size: 4,
              content: (
                <SimpleTable
                  header="Shipments"
                  fullWidth
                  columns={[
                    { header: 'Type' },
                    { header: 'Total', align: 'right' },
                  ]}
                  summaryRow={[
                    { cell: () => 'Total' },
                    {
                      align: 'right',
                      cell: (data) =>
                        data.map((r) => r.total).reduce((a, b) => a + b),
                    },
                  ]}
                  data={[
                    { type: 'Open', total: values.open_request },
                    { type: 'Pending', total: values.pending_request },
                    { type: 'Assigned', total: values.assigned_request },
                  ]}
                />
              ),
            },
            {
              size: 4,
              content: (
                <SimpleTable
                  header="Bids"
                  fullWidth
                  columns={[
                    { header: 'Type' },
                    { header: 'Total', align: 'right' },
                    {
                      header: '$',
                      align: 'right',
                      cell: (r) => Utils.formatMoney(r.amount),
                    },
                  ]}
                  summaryRow={[
                    { cell: () => 'Total' },
                    {
                      align: 'right',
                      cell: (data) =>
                        data.map((r) => r.total).reduce((a, b) => a + b),
                    },
                    {
                      align: 'right',
                      cell: (data) =>
                        Utils.formatMoney(
                          data.map((r) => r.amount).reduce((a, b) => a + b)
                        ),
                    },
                  ]}
                  data={[
                    {
                      type: 'Open',
                      total: values.open_bid,
                      amount: values.open_amount_bid,
                    },
                    {
                      type: 'Assigned',
                      total: values.assigned_bid,
                      amount: values.assigned_amount_bid,
                    },
                    {
                      type: 'Accepted',
                      total: values.accepted_bid,
                      amount: values.accepted_amount_bid,
                    },
                    {
                      type: 'Completed',
                      total: values.completed_bid,
                      amount: values.completed_amount_bid,
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </Container>
    </Page>
  )
}

export default AdminDashboard
/*
<SimpleCard
  header="Users"
  Icon={<K.icon.users color={shared.color.tile_icon_color} />}>

 </SimpleCard>
 */
