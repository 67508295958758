import React from 'react'
import { useParams } from 'react-router-dom'
import RoleRoute from 'components/RoleRoute'
import ClientFlightDetail from './components/ClientFlightDetail'
import shared from 'shared/constants'
import { PK } from 'utils'

const FlightDetail = () => {
  const { id } = useParams()
  return (
    <RoleRoute
      routes={{
        [shared.role.user]: <ClientFlightDetail id={PK.get(id)} />,
      }}
    />
  )
}

export default FlightDetail
