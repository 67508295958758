import React from 'react'
import { Api, Utils } from '@jesseteal/teal-react'
import { SimpleForm, SimpleTable, Row, Col } from 'components/Basic'
import FieldDef from 'components/Basic/FieldDef'
import NameDisplay from 'components/NameDisplay'
import { useSetMessagesViewed, useMessagesForBid } from 'shared/hooks'
import K from 'Constants'
import constants from 'shared/constants'
import T from 'components/Typo'
import { isUser } from 'utils/role'

const FormattedMessage = ({ message, is_client, me_id }) => {
  return (
    <div>
      <Row>
        <Col>
          {T.caption(
            <NameDisplay
              me_id={me_id}
              user={message.from_id_user}
              is_client={is_client}
            />
          )}
        </Col>
        <Col className="tr">
          {T.caption(Utils.date.format(message.created_at, 'MMM d h:mm a'))}
        </Col>
      </Row>
      <Row>{message.message}</Row>
    </div>
  )
}

const Messages = ({ bid_id, ...props }) => {
  const setMessagesViewed = useSetMessagesViewed()
  const clearBids = Api.useGraphPurge('bid')
  const is_client = isUser()
  const { sub: me_id } = Utils.token.get()
  const { rows: messages, refetch } = useMessagesForBid(bid_id)

  // onMount
  React.useEffect(() => {
    setMessagesViewed({
      variables: { bid_id, viewer_id: me_id },
    }).then(() => {
      clearBids() // forces refresh on bids
    })
    // eslint-disable-next-line
  }, [])

  const save = Api.useSave('message')
  if (!messages) {
    return null
  }
  return (
    <>
      <SimpleTable
        fullWidth
        header={props.header || constants.label.message.header}
        data={messages}
        row_limit={5}
        {...props}
        columns={[
          {
            header: props.subheader || constants.label.message.subheader,
            sort: false,
            cell: (r) => (
              <FormattedMessage
                message={r}
                is_client={is_client}
                me_id={me_id}
              />
            ),
          },
        ]}
      />
      <SimpleForm
        model={{}}
        saveIcon={K.icon.send}
        saveLabel="Send"
        resetOnSave
        save={(modified) => {
          save(
            {
              bid_id,
              from_id: me_id,
              message: modified.message,
            },
            true
          ).then(refetch)
        }}
        fields={[
          FieldDef.name('message').label('Send a Message').required().get(),
        ]}
      />
    </>
  )
}

export default Messages
