import { Api } from '@jesseteal/teal-react'
import { AdminRequestSchema, BasicRequestSchema, schema } from '../schema'
import shared from '../constants'

export const useProviderRequests = (provider_id, where) => {
  const results = Api.useGraphQuery(
    `query FindRequests($where:String,$provider:String)
    {
      request(where:$where){
        ${schema['request']}
        request_cargo {
          ${schema['request_cargo']}
        }
        request_passenger {
          ${schema['request_passenger']}
        }
        from_airport_id_airport{
          id
          name
        }
        to_airport_id_airport{
          id
          name
        }
        bid(where:$provider){
          id
          provider_id
          cost
          status
          fly_date
        }
      }
    }`,
    {
      networkOnly: true,
      variables: {
        where: JSON.stringify(where),
        provider: JSON.stringify({
          provider_id,
          status: { neq: shared.status.CLOSED },
          deleted_at: null,
        }),
      },
    }
  )
  return {
    ...results,
    rows: results.data?.request,
  }
}

export const useAdminRequests = (
  where,
  whereBid = {
    status: { neq: shared.status.CLOSED },
    deleted_at: null,
  }
) => {
  const results = Api.useGraphQuery(
    `query ClientRequests($where:String, $whereBid:String){
      request(where:$where) { ${AdminRequestSchema} }
    }`,
    {
      networkOnly: true,
      variables: {
        where: JSON.stringify(where),
        whereBid: JSON.stringify(whereBid),
      },
    }
  )
  return {
    ...results,
    rows: results.data?.request,
  }
}

export const useClientRequests = (
  where,
  whereBid = {
    status: { neq: shared.status.CLOSED },
    deleted_at: null,
  }
) => {
  const results = Api.useGraphQuery(
    `query ClientRequests($where:String, $whereBid:String){
      request(where:$where) { ${BasicRequestSchema} }
    }`,
    {
      networkOnly: true,
      variables: {
        where: JSON.stringify(where),
        whereBid: JSON.stringify(whereBid),
      },
    }
  )
  return {
    ...results,
    rows: results.data?.request,
  }
}

export const useRequest = (id) => {
  const results = useClientRequests({ id })
  return {
    ...results,
    request: results.rows?.[0],
  }
}

export const useAdminRequest = (id) => {
  const results = useAdminRequests({ id })
  return {
    ...results,
    request: results.rows?.[0],
  }
}

export const useAdminRequestsList = (where = { deleted_at: null }) => {
  const results = Api.useGraphQuery(
    `query adminReqView($where: String!) {
      request(where: $where, order: "created_at desc"){
        id
        start_date
        status
        departure: from_airport_id_airport { id code }
        destination: to_airport_id_airport { id code }
        user: from_id_user { id firstname lastname}
        bid { id status cost fly_date }
        type
        deleted_at
      }
    }`,
    {
      networkOnly: true,
      variables: {
        where: JSON.stringify(where),
      },
    }
  )
  return {
    ...results,
    rows: results.data?.request,
  }
}
