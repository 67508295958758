import React from 'react'
import { Utils } from '@jesseteal/teal-react'
import { SimpleTable } from 'components/Basic'
import { useBids } from 'shared/hooks'
import shared from 'shared/constants'

const BidList = ({ id, onClick }) => {
  const { rows: bids } = useBids({ request_id: id })
  return (
    <SimpleTable
      fullWidth
      header={shared.label.bid.header}
      data={bids}
      row_action={onClick}
      columns={[
        {
          header: shared.label.bid.fly_date,
          cell: (r) => Utils.date.format(r.fly_date),
        },
        {
          header: shared.label.bid.status,
        },
        {
          header: shared.label.bid.cost,
          cell: (r) => Utils.formatMoney(r.cost),
          align: 'right',
        },
      ]}
    />
  )
}

export default BidList
