import { Api } from '@jesseteal/teal-react'

export const usePhotos = (request_id) => {
  const results = Api.useGraphQuery(
    `query PhotoManager($where: String){
      request_image(where:$where, order: "created_at"){
        id request_id url
      }
    }`,
    {
      networkOnly: true,
      variables: {
        where: JSON.stringify({ request_id }),
      },
    }
  )
  return {
    ...results,
    rows: results.data?.request_image,
  }
}
