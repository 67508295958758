import React from 'react'
import { Dialog, DialogActions, DialogContent, Button } from '@material-ui/core'
import ContentDisplay from 'components/ContentDisplay'
import shared from 'shared/constants'

const Terms = ({ show, onClose }) => {
  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ padding: 0 }}>
        <ContentDisplay id={shared.content.terms} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Terms
