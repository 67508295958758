import React from 'react'
import { Api } from '@jesseteal/teal-react'
import SimpleForm from 'components/Basic/SimpleForm'
import FieldDef from 'components/Basic/FieldDef'
import { refresh_token } from 'utils'
import constants from 'shared/constants'
import { useUser, useRole } from 'shared/hooks'

const UserForm = (props) => {
  const { id, adminForm = false } = props
  const { user } = useUser(id)
  const { role } = useRole(id)
  const doSave = Api.useSave('user')
  const saveRole = Api.useSave('role', ['user', 'role'])

  if (!user || !role) {
    return null
  }
  const is_user = [constants.role.user, constants.role.test_user].includes(
    role?.role
  )
  const model = { ...user, type: role?.type || 'any' }
  const fields = [
    FieldDef.name('firstname').label('First Name').required().get(4),
    FieldDef.name('lastname').label('Last Name').required().get(4),
    FieldDef.name('type')
      .label('Services')
      .select([
        { value: 'any', label: 'Cargo / Passenger' },
        { value: constants.flight_type.cargo, label: 'Cargo' },
        { value: constants.flight_type.passenger, label: 'Passenger' },
      ])
      .hide(() => is_user)
      .get(4),

    FieldDef.name('email').label('Email').readOnly().email().get(6),
    FieldDef.name('phone').label('Phone').phone().get(6),
    FieldDef.name('address')
      .label(!is_user ? 'Package Drop Off Location' : 'Address')
      .get(),
    FieldDef.name('city').label('City').get(6),
    // FieldDef.name('state').label('State').max(2).get(2),
    FieldDef.name('zip').label('Zip Code').max(5).get(4),
  ]
  if (adminForm) {
    fields.push(FieldDef.name('enabled').bool().label('Enabled').get(4))
  }
  // if (is_provider) {
  //   fields.push(
  //     FieldDef.name('notes').lines(4).label('Drop Off Instructions').get(12)
  //   )
  // }
  return (
    <SimpleForm
      header={constants.label.account.header}
      subheader={constants.label.account.subheader}
      model={model}
      editMode={false}
      save={(modified) => {
        const { type, ...other } = modified
        doSave({ ...other, id: model.id }, !model.id)
        if (type) {
          const role_id = role?.id
          saveRole({
            id: role_id,
            type: type === 'any' ? null : type,
          }).then(() => {
            refresh_token().then(() => console.log('token refreshed'))
          })
        }
      }}
      onBack={props.onBack}
      cancel={props.cancel}
      fields={fields}
    />
  )
}

export default UserForm
