import React from 'react'
import { isAdmin, isProvider, isUser } from 'utils/role'

const RoleRoute = ({ routes }) => {
  if (isUser()) {
    return routes['User']
  } else if (isProvider()) {
    return routes['Provider']
  } else if (isAdmin()) {
    return routes['Admin']
  }
  return <div>404 Role Route</div>
}

export default RoleRoute
