import React from 'react'
import { Outlet } from 'react-router-dom'
import PaymentSetupNotification from './PaymentSetupNotification'
// import DevMenubar from './DevMenubar'
// import NavBar from './NavBar'
import TopBar from './TopBar'
// import {
//   // Hooks,
//   Utils,
// } from '@jesseteal/teal-react'
import useStyles from './style'
import { ENVIRONMENT } from '../../utils'
// import Typo from 'components/Typo'
// import K from 'Constants'

const UserLayout = ({ role }) => {
  const classes = useStyles()
  // const navigate = useNavigate()
  // const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  // console.log('UserLayout', { token: Utils.token.get() })
  // if (!Utils.token.token()) {
  //   window.setTimeout(() => navigate('/login'), 1)
  //   return null
  // }
  // if (!Utils.token.hasRole(role) && role !== 'any') {
  //   window.setTimeout(() => navigate('/'), 1)
  //   return null
  // }
  // // onMobileNavOpen={() => setMobileNavOpen(true)}
  return (
    <div className={classes.root}>
      <TopBar />

      {/*
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      */}
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {ENVIRONMENT === 'staging' && (
              <h2
                style={{
                  textAlign: 'center',
                  color: '#F33',
                  borderBottom: '1px solid #666',
                  backgroundColor: '#FEFACD',
                }}
              >
                STAGING SERVER
              </h2>
            )}
            {/*<DevMenubar />*/}
            <PaymentSetupNotification />
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserLayout
