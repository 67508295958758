import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Link, Container } from '@material-ui/core'
import Page from 'components/Page'
import SimpleForm from 'components/Basic/SimpleForm'
import FieldDef from 'components/Basic/FieldDef'
import Alert from '@material-ui/lab/Alert'
import K from 'Constants'
import { Api } from '@jesseteal/teal-react'
import Typo from 'components/Typo'

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [footer, set_footer] = React.useState(null)

  return (
    <Page title="Forgot Password?">
      <Container maxWidth="sm">
        <SimpleForm
          header="Password Reset"
          subheader="Enter email to receive password reset instructions."
          footer={footer}
          saveIcon={K.icon.login}
          saveLabel="Send Reset"
          save={data => {
            Api.Post('/auth/reset', data).then(r => {
              if (r.sent) {
                set_footer(<Alert severity="success">Reset email sent.</Alert>)
              } else {
                set_footer(
                  <Alert severity="warning">
                    Unable to send. Please check email address.
                  </Alert>
                )
              }
            })
          }}
          fields={[
            FieldDef.name('email')
              .label('Email Address')
              .email()
              .required()
              .get(),
          ]}
        />
        <Box mt={3}>
          {Typo.body(
            <>
              Have an account?{' '}
              <Link href="#" onClick={() => navigate(K.route.login.href)}>
                {K.route.login.label}
              </Link>
            </>
          )}
        </Box>
      </Container>
    </Page>
  )
}

export default ForgotPassword
