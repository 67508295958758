import React from 'react'
import { /*useParams,*/ useNavigate } from 'react-router-dom'
import { Container } from '@material-ui/core'
import { Api } from '@jesseteal/teal-react'
import Page from 'components/Page'
import { SimpleGrid } from 'components/Basic'
import Messages from 'components/Messages'
import shared from 'shared/constants'
import { useBid } from 'shared/hooks'
import CargoDetail from './CargoDetail'
import FlightDetail from './FlightDetail'
import BidCard from './BidCard'
import BidForm from './BidForm'

const ProviderBidDetail = ({ id, noBack = false }) => {
  const [edit, set_edit] = React.useState(false)
  const navigate = useNavigate()
  const { bid } = useBid(id)
  const saveBid = Api.useSave('bid')
  if (!bid) {
    return null
  }
  return (
    <Page>
      <Container maxWidth="lg">
        <SimpleGrid
          items={[
            {
              content:
                bid.request_id_request.type === shared.flight_type.cargo ? (
                  <CargoDetail
                    id={bid.request_id}
                    onBack={noBack ? null : () => navigate(-1)}
                  />
                ) : (
                  <FlightDetail
                    request={bid.request_id_request}
                    onBack={noBack ? null : () => navigate(-1)}
                  />
                ),
              size: 6,
            },
            {
              content: (
                <React.Fragment>
                  {!edit && (
                    <BidCard id={bid.id} onEdit={() => set_edit(true)} />
                  )}
                  {edit && (
                    <BidForm
                      request={bid.request_id_request}
                      model={bid}
                      onCancel={() => set_edit(false)}
                      onSave={(mod, vals) => {
                        saveBid({
                          id: bid.id,
                          status: shared.status.OPEN,
                          fly_date: vals.fly_date,
                          cost: vals.cost,
                        })
                        set_edit(false)
                      }}
                    />
                  )}
                  {bid.status === shared.status.ACCEPTED && (
                    <Messages bid_id={bid.id} className="mt2" />
                  )}
                </React.Fragment>
              ),
              size: 6,
            },
          ]}
        />
      </Container>
    </Page>
  )
}

export default ProviderBidDetail
