import React from 'react'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { Hooks } from '@jesseteal/teal-react'

const GlobalSnack = ({ duration = 3000 }) => {
  const [snack, set_snack] = Hooks.useContextState('snack', null)

  if (!snack) {
    return null
  }
  // type: success, info, error, warning
  const data =
    typeof snack === 'object' ? snack : { message: snack, type: 'success' }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={!!snack}
      autoHideDuration={data.duration || 3000}
      onClose={(event, reason) => {
        if (reason === 'clickaway') {
          return
        }
        set_snack('')
      }}
    >
      <Alert
        severity={data.type}
        onClose={() => set_snack('')}
        style={{ minWidth: 300 }}
      >
        {data.message}
      </Alert>
    </Snackbar>
  )
}

export default GlobalSnack
