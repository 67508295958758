import React from 'react'
import {
  Box,
  // Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
} from '@material-ui/core'
import SimpleCardActions from './SimpleCardActions'
import SimpleTabs from './SimpleTabs'
import K from 'Constants'

/*
usage:
  import SimpleCard from 'components/Basic/SimpleCard';
  <SimpleCard
    header="Header"
    subheader="Sub Header"
  >
    <div>hello world</div>
  </SimpleCard>

  parameters:
    header: {string} - (optional)
    subheader: {string} - (optional)
    //todo:
    tabs: {array}
      - label
      - onClick
*/

const SimpleCard = (props) => {
  const {
    header,
    subheader,
    children,
    actionsLeft,
    actionsRight,
    className,
    style,
    tabs,
  } = props
  const Content = props.noGutter ? Box : CardContent
  return (
    <Card className={className} style={style} raised={props.raised}>
      {(header || subheader) && (
        <>
          <CardHeader
            avatar={
              props.avatar ? (
                <IconButton size="small" onClick={props.avatar.onClick}>
                  <props.avatar.icon />
                </IconButton>
              ) : props.onBack ? (
                <IconButton size="small" onClick={props.onBack}>
                  <K.icon.left />
                </IconButton>
              ) : null
            }
            action={
              props.headeraction ? (
                <IconButton size="small" onClick={props.headeraction.onClick}>
                  {props.headeraction.icon}
                </IconButton>
              ) : null
            }
            subheader={subheader}
            title={header}
          />
          <Divider />
        </>
      )}
      {tabs && (
        <SimpleTabs
          tabs={tabs.map((t) => ({
            label: t.label,
          }))}
          onChange={(i) => {
            tabs[i].onClick()
          }}
        />
      )}

      {tabs && <Divider />}
      {children && <Content style={props.content_style}>{children}</Content>}
      {props.footer && <SimpleCardActions>{props.footer}</SimpleCardActions>}
      <SimpleCardActions actionsLeft={actionsLeft} actionsRight={actionsRight}>
        {props.actionContent}
      </SimpleCardActions>
    </Card>
  )
}

export default SimpleCard
