import React from 'react'
import aws from 'utils/aws'
import { Button, Modal, Fade } from '@material-ui/core'
import Resizer from 'react-image-file-resizer'
import Loading from 'components/Loading'
import SimpleCard from 'components/Basic/SimpleCard'
import SimpleCardActions from 'components/Basic/SimpleCardActions'
import { usePhotos } from 'shared/hooks/usePhotos'
import MaxBox from 'components/Basic/MaxBox'
import K from 'Constants'
import { Api } from '@jesseteal/teal-react'

const PhotoManager = ({ request, editable = false }) => {
  const fileInput = React.useRef()
  const [uploading, set_uploading] = React.useState(false)
  const [image_index, set_image_index] = React.useState(null)
  const { rows, refetch } = usePhotos(request.id)
  const save = Api.useSave('request_image')

  const img_count = rows?.length || 0
  console.log('wtf', { i: rows })

  if (!rows) {
    return <Loading />
  }

  return (
    <SimpleCard
      header="Photos"
      headeraction={
        editable && !img_count < 5
          ? {
              icon: <K.icon.add />,
              onClick: () => fileInput.current.click(),
            }
          : null
      }
    >
      {img_count === 0 ? (
        <div>No images attached.</div>
      ) : (
        <div
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            // justifyContent: 'center',
          }}
        >
          {rows?.map((t, i) => {
            return (
              <Button key={t.id} onClick={() => set_image_index(i)}>
                <img
                  src={t.url.replace('.jpg', '_sm.jpg')}
                  alt=""
                  style={{ maxWidth: 50 }}
                />
              </Button>
            )
          })}
          {uploading && <Loading />}
        </div>
      )}
      <input
        style={{ display: 'none' }}
        ref={fileInput}
        type="file"
        onChange={(e) => {
          set_uploading(true)
          const request_id = request.id
          const uniq = guid()
          const filename = `${uniq}.jpg`
          const small_filename = `${uniq}_sm.jpg`
          resizeFile(e.target.files[0], 100).then((file) => {
            aws.upload_file(
              file,
              async (prog, filename) => {
                if (prog === 100) {
                  const url = aws.image_url(filename).replace('_sm.jpg', '.jpg')
                  await save({ request_id, url }, true)
                  refetch().then(() => {
                    set_uploading(false)
                  })
                }
              },
              small_filename
            )
          })
          resizeFile(e.target.files[0], 1000).then((file) => {
            aws.upload_file(file, null, filename)
          })
        }}
      />
      {image_index !== null && (
        <Modal
          open={true}
          onClose={() => {
            set_image_index(null)
          }}
          style={{
            margin: 80,
            width: 'auto',
          }}
        >
          <Fade in={true}>
            <SimpleCard>
              <SimpleCardActions
                showDivider={false}
                actionsRight={[
                  {
                    icon: <K.icon.left />,
                    onClick: () => {
                      let ix = image_index - 1
                      if (ix < 0) {
                        ix = rows.length - 1
                      }
                      set_image_index(ix)
                    },
                  },
                  {
                    icon: <K.icon.right />,
                    onClick: () => {
                      let ix = image_index + 1
                      if (ix >= rows.length) {
                        ix = 0
                      }
                      set_image_index(ix)
                    },
                  },
                ]}
              />
              <MaxBox style={{ textAlign: 'center' }}>
                <img src={rows[image_index].url} alt="" />
              </MaxBox>
            </SimpleCard>
          </Fade>
        </Modal>
      )}
    </SimpleCard>
  )
}

export default PhotoManager

const guid = () => {
  return Date.now().toString('36')
}

const resizeFile = (file, width) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      width,
      'JPEG',
      80,
      0,
      (uri) => {
        resolve(uri)
      },
      'file'
    )
  })
