import React from 'react'
import { Hooks, Utils } from '@jesseteal/teal-react'
import { useNavigate } from 'react-router-dom'
import { Badge, IconButton } from '@material-ui/core'
import { NotificationsOutlined } from '@material-ui/icons'
import K from 'Constants'
import { useMessagesForProvider } from 'shared/hooks'

const ProviderIcon = () => {
  const provider_id = Utils.token.get().sub
  const [unread_message_count, set_unread_message_count] =
    Hooks.useContextState('unread_message_count', 0)
  const navigate = useNavigate()
  const { rows: bids } = useMessagesForProvider(provider_id)
  React.useEffect(() => {
    if (bids) {
      let newMessageCount = 0
      const bid_with_messages = bids.filter((b) => !!b.message.length)
      bid_with_messages.forEach((bid) => {
        bid.message.forEach((msg) => {
          if (msg.to_id === provider_id && !msg.viewed_at) {
            newMessageCount++
          }
        })
      })
      set_unread_message_count(newMessageCount)
    }
    // eslint-disable-next-line
  }, [bids])

  return (
    <IconButton color="inherit" onClick={() => navigate(K.route.messages.href)}>
      <Badge badgeContent={unread_message_count} color="error">
        <NotificationsOutlined />
      </Badge>
    </IconButton>
  )
}

export default ProviderIcon
