import React from 'react'
import { Badge, IconButton } from '@material-ui/core'
import { SimpleTable } from 'components/Basic'
import { useNavigate } from 'react-router-dom'
import { Container } from '@material-ui/core'
import Page from 'components/Page'
import { useMessagesForClient } from 'shared/hooks'
import K from 'Constants'
import shared from 'shared/constants'

const ClientMessages = ({ id }) => {
  const navigate = useNavigate()
  const { rows: requests } = useMessagesForClient(id)

  if (!requests) {
    return null
  }

  const bid_with_messages = []
  requests.forEach((r, i) => {
    bid_with_messages.push(...r.bid.filter((b) => !!b.message.length))
  })

  return (
    <Page>
      <Container maxWidth="lg">
        <SimpleTable
          fullWidth
          header={shared.label.message.header}
          columns={[
            {
              header: '',
              sort: false,
              style: { width: 70 },
              cell: (b) => {
                const unread = b.message.filter(
                  (m) => !m.viewed_at && m.to_id === id
                )
                return (
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={() => {
                      if (
                        b.request_id_request.type === shared.flight_type.cargo
                      ) {
                        navigate(K.route.shipment.href(b.request_id))
                      } else {
                        navigate(K.route.flight.href(b.request_id))
                      }
                    }}
                  >
                    <Badge badgeContent={unread.length} color="error">
                      <K.icon.messages />
                    </Badge>
                  </IconButton>
                )
              },
            },
            {
              style: { width: 180 },
              header: shared.label.cargo.identifier,
              cell: (b) =>
                shared.label.cargo.identifier_value(b.request_id_request),
            },
            {
              header: 'Last Message',
              cell: (b) => b.message[0].message,
            },
          ]}
          data={bid_with_messages}
        />
      </Container>
    </Page>
  )
}

export default ClientMessages
