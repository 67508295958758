import React from 'react'
import { Container } from '@material-ui/core'
import { Api, Utils } from '@jesseteal/teal-react'
import SimpleDeleteConfirm from 'components/Basic/SimpleDeleteConfirm'
import SimpleModal from 'components/Basic/SimpleModal'
import SimpleTable from 'components/Basic/SimpleTable'
import SimpleGrid from 'components/Basic/SimpleGrid'
import AirportForm from './components/AirportForm'
import Page from 'components/Page'
import Loading from 'components/Loading'
import K from 'Constants'
import shared from 'shared/constants'
import { useAirports } from 'shared/hooks'

const ManageAirports = () => {
  const [edit, set_edit] = React.useState(null)
  const [delayed, set_delayed] = React.useState(null)
  const [confirm_delete, set_confirm_delete] = React.useState(false)
  const { rows, loading, refetch } = useAirports()

  const doSave = Api.useSave('airport', 'airport')

  React.useEffect(() => {
    if (delayed) {
      set_edit(delayed)
      set_delayed(null)
    }
  }, [delayed])

  if (loading || !rows) {
    return <Loading />
  }
  return (
    <Page title="Manage Airports">
      <Container maxWidth="lg">
        <SimpleGrid
          items={[
            {
              // size: 6,
              content: (
                <SimpleTable
                  fullWidth
                  row_limit={9999}
                  header={shared.label.admin_airports.header}
                  subheader={shared.label.admin_airports.subheader}
                  headeraction={{
                    icon: <K.icon.add />,
                    onClick: () => set_edit({ name: '' }),
                  }}
                  data={rows}
                  row_action={(r) => {
                    console.log('hi', { r })
                    set_edit(r)
                  }}
                  columns={[
                    { header: 'Name' },
                    // { header: 'Latitude' },
                    // { header: 'Longitude' },
                    // {
                    //   action: (item) => {
                    //     set_edit(null)
                    //     set_delayed(item)
                    //   },
                    // },
                    // {
                    //   delete: (row) =>
                    //     doSave({
                    //       id: row.id,
                    //       deleted_at: Utils.date.now(Utils.date.DB_DATETIME),
                    //     }).then(refetch),
                    // },
                  ]}
                />
              ),
            },
            // {
            //   size: 6,
            //   content: edit ? (
            //     <AirportForm
            //       model={edit}
            //       save={(values) => {
            //         doSave(values, !values.id).then(() => {
            //           if (!values.id) {
            //             refetch()
            //           }
            //         })
            //         set_edit(null)
            //       }}
            //       cancel={() => {
            //         set_edit(null)
            //       }}
            //     />
            //   ) : null,
            // },
          ]}
        />
        {confirm_delete && (
          <SimpleDeleteConfirm
            onDelete={() => {
              set_confirm_delete(false)
              doSave({
                id: edit.id,
                deleted_at: Utils.date.now(Utils.date.DB_DATETIME),
              }).then(() => {
                set_edit(null)
                refetch()
              })
            }}
            onCancel={() => set_confirm_delete(false)}
          />
        )}
        {edit && (
          <SimpleModal>
            <AirportForm
              model={edit}
              save={(values) => {
                doSave(values, !values.id).then(() => {
                  if (!values.id) {
                    refetch()
                  }
                })
                set_edit(null)
              }}
              cancel={() => {
                set_edit(null)
              }}
              onDelete={
                edit.id
                  ? () => {
                      set_confirm_delete(true)
                      // doSave({
                      //   id: edit.id,
                      //   deleted_at: Utils.date.now(Utils.date.DB_DATETIME),
                      // }).then(() => {
                      //   set_edit(null)
                      //   refetch()
                      // })
                    }
                  : undefined
              }
            />
          </SimpleModal>
        )}
      </Container>
    </Page>
  )
}

export default ManageAirports
