import validate from 'validate.js'

const validationSchema = (fields) => {
  // const schema = {
  //   city: {
  //     presence: { allowEmpty: false, message: 'is required' },
  //     length: {
  //       maximum: 10
  //     }
  //   }
  // };
  let schema = {}
  fields.forEach((f) => {
    let check = {}
    if (f.validate) {
      if (f.validate.required) {
        check['presence'] = {
          allowEmpty: false,
          message: f.validate.message || `${f.label} is required.`,
        }
      }
      if (f.validate.email) {
        check['email'] = { message: 'is not valid. ' }
      } else if (f.validate.numericality) {
        if (f.validate.numericality === true) {
          check['numericality'] = { message: f.label + ' is not a number. ' }
        } else {
          check['numericality'] = f.validate.numericality
        }
      } else if (f.validate.confirm) {
        check['equality'] = 'password'
      }
      if (f.validate.format) {
        check['format'] = {
          pattern: f.validate.format,
          flags: 'i',
          message: f.validate.message,
        }
      }
      // if(f.validate.unique){
      //   check['unique'] = { message: 'is already in use.', field: f.name, table: Pluralize(f.validate.unique) };
      // }
    }
    schema[f.name] = check
  })
  return schema
}

export const validate_fields = (values, fields) => {
  const errors = validate(values, validationSchema(fields), {
    fullMessages: false,
  })
  const custom_err = { ...errors }
  // console.log('Validate: errors', { errors })
  for (var field of fields) {
    // console.log({ field })
    if (field?.validate?.custom && !custom_err[field.name]) {
      const error_message = field.validate.custom(values)
      if (error_message) {
        custom_err[field.name] = error_message
      }
    }
  }
  // console.log('Validate: values', values)
  if (Object.keys(custom_err).length) {
    return custom_err
  }
}

export default {
  validate: (values, fields) => {
    return validate(values, validationSchema(fields))
  },
}
