import React from 'react'
import SimpleForm from 'components/Basic/SimpleForm'
// import Loading from 'components/Loading'
import K from 'Constants'
import { Api, Utils } from '@jesseteal/teal-react'
import { tokenize_bank } from 'utils/Stripe'
import Alert from '@material-ui/lab/Alert'

const SetupBank = ({ onClose }) => {
  const [error, set_error] = React.useState(null)
  const [submitting, set_submitting] = React.useState(false)

  const submit = React.useCallback(async values => {
    set_submitting(true)
    console.log({ values })
    const stripe = await tokenize_bank({
      ...values,
      name: `${Utils.token.get().f} ${Utils.token.get().l}`,
    })
    console.log({ stripe })
    if (stripe.error) {
      set_submitting(false)
      set_error(stripe.error.message)
    } else {
      Api.Post(`/pay/token`, {
        remora: Utils.token.token(),
        stripe: stripe.id,
      }).then(r => {
        if (r.status === 'ok') {
          Utils.token.set(r.token)
          onClose()
        } else {
          set_error(r.message)
        }
        // console.log('Api.Post:response', r)
      })
    }
    // eslint-disable-next-line
  }, [])
  return (
    <SimpleForm
      className="ctr"
      style={{ width: 500 }}
      header="Setup Bank Account"
      footer={error && <Alert severity="error">{error}</Alert>}
      cancel={onClose}
      save={submit}
      saveIcon={K.icon.bank}
      saveLabel="Save Payment Option"
      editMode={!submitting}
      fields={[
        {
          name: 'routing_number',
          label: 'Routing Number',
          number: true,
          validate: {
            required: true,
            custom: ({ routing_number }) => {
              const str = '' + routing_number
              if (str?.length !== 9) {
                return 'Routing Number must have 9 digits'
              }
            },
          },
        },
        {
          name: 'account_number',
          label: 'Account Number',
          number: true,
          validate: {
            required: true,
          },
        },
      ]}
    />
  )
}

export default SetupBank
