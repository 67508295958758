import React from 'react'
import _ from 'lodash'
import {
  Checkbox,
  InputAdornment,
  MenuItem,
  TextField,
  FormLabel,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Api, Utils } from '@jesseteal/teal-react'
import K from 'Constants'
import { schema } from 'shared/schema'
/*
usage:
  <Input
    autoFocus={true||false}
    field={FieldDef.name("").get()}
    disabled={(!values.id && !canCreate) || (!!values.id && !canUpdate)}
    values={local_values}
    errors={errors}
    setValues={data => {
      setValues(data);
      onChange(data);
    }}
    onEnter={onEnter}
    lookups={lookups}
  />
*/

const Input = (props) => {
  const {
    field,
    values,
    setValues,
    errors,
    onEnter,
    autoFocus,
    edit = true,
    disabled = false,
  } = props
  const variant = edit ? 'outlined' : 'standard'

  const prepend = field.prepend
    ? {
        startAdornment: (
          <InputAdornment position="start">{field.prepend}</InputAdornment>
        ),
      }
    : null
  const append = field.append
    ? {
        endAdornment: (
          <InputAdornment position="end">{field.append}</InputAdornment>
        ),
      }
    : null
  // const options = field.lookup ? lookups.filter(l => l.type === field.lookup).map(l => ({ value:l.id, label:l.name, group: l.group })) : field.options;
  const selectedValue = Utils.zeroIsOk(Utils.resolve(values, field.name))
  const clear =
    field.clearable && selectedValue !== ''
      ? {
          startAdornment: (
            <InputAdornment
              position="start"
              onClick={() => field.clearable(null)}
            >
              <K.icon.remove />
            </InputAdornment>
          ),
        }
      : null
  const { data: fkList } = field.foreignKey
    ? Api.useGraphQuery(
        `query Input($where:String, $order: String){
    ${field.foreignKey.table}(where:$where, order: $order) { ${
          schema[field.foreignKey.table]
        } }
  }`,
        {
          variables: {
            order: field.foreignKey.order || 'name',
            where: field.foreignKey.where
              ? JSON.stringify(field.foreignKey.where)
              : null,
          },
        }
      )
    : {}

  if (field.date) {
    // console.log('field',Utils.date.format(field.minDate, Utils.date.DB_DATETIME));
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {edit ? (
          <KeyboardDatePicker
            disableToolbar
            fullWidth
            inputVariant={variant}
            variant="inline"
            autoOk={true}
            disabled={disabled || field.disabled}
            label={field.label}
            minDate={Utils.date.parse(field.minDate)}
            maxDate={Utils.date.parse(field.maxDate) || undefined}
            required={edit && field.validate && field.validate.required}
            error={errors && !!errors[field.name]}
            helperText={(errors && errors[field.name]) || field.helperText}
            InputProps={{
              ...(prepend || append || clear),
              readOnly: !!field.readOnly || !edit,
            }}
            format="MM/dd/yyyy"
            value={Utils.date.format(Utils.resolve(values, field.name)) || null}
            onChange={(date, formatted) => {
              setValues({
                [field.name]: Utils.date.format(date, Utils.date.DB_DATE),
              })
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                onEnter && onEnter()
                ev.preventDefault()
              }
            }}
          />
        ) : (
          <TextField
            fullWidth
            label={field.label}
            value={Utils.date.format(Utils.resolve(values, field.name)) || null}
            InputProps={{ readOnly: true }}
            variant={variant}
          />
        )}
      </MuiPickersUtilsProvider>
    )
  } else if (field.time) {
    // deprecated?
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          fullWidth
          disabled={disabled || field.disabled}
          margin="normal"
          label={field.label}
          variant={variant}
          value={Utils.resolve(values, field.name)}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              onEnter && onEnter()
              ev.preventDefault()
            }
          }}
          onChange={(date, formatted) => {
            setValues({ [field.name]: date ? date.format('HH:mm') : null })
          }}
        />
      </MuiPickersUtilsProvider>
    )
  } else if (field.bool) {
    return (
      <>
        <FormControl component="fieldset" className="pt2">
          {field.legend && (
            <FormLabel component="legend">{field.legend}</FormLabel>
          )}
          <FormControlLabel
            control={
              <Checkbox
                color={edit ? 'primary' : 'default'}
                disabled={disabled || field.disabled}
                checked={!!Utils.resolve(values, field.name)}
                onChange={(e) =>
                  edit &&
                  setValues({ [field.name]: e.target.checked ? 1 : null })
                }
              />
            }
            label={field.label}
          />
          {field.boolExtra}
        </FormControl>
        {errors && !!errors[field.name] && (
          <FormHelperText error>{errors[field.name]}</FormHelperText>
        )}
      </>
    )
  }
  if (field.foreignKey && !fkList) {
    return null // wait for data
  }
  // console.log('[Input]', { name: field.name, selectedValue, values })
  return (
    <>
      <TextField
        autoComplete="off"
        autoFocus={autoFocus === true}
        disabled={disabled || field.disabled}
        required={edit && field.validate && field.validate.required}
        select={!!field.foreignKey || !!field.options}
        name={field.name}
        label={field.label}
        value={selectedValue}
        variant={variant}
        className={field.className}
        onBlur={(e) => {
          if (field.phone) {
            setValues({ [field.name]: Utils.formatPhone(e.target.value) })
          }
          if (field.number) {
            if (e.target.value.trim() === '') {
              setValues({ [field.name]: '' })
            } else {
              setValues({ [field.name]: parseFloat(e.target.value) })
            }
          }
        }}
        onChange={(e) => {
          if (
            field.number &&
            !Utils.isNumeric(e.target.value) &&
            e.target.value.trim() !== ''
          ) {
            return
          }
          setValues({ [field.name]: e.target.value })
          if (field.foreignKey && field.foreignKey.onChange) {
            const t = _.find(fkList[field.foreignKey.table], {
              id: Number(e.target.value),
            })
            field.foreignKey.onChange(t)
          } else if (field.options) {
            const t = _.find(field.options, { value: e.target.value })
            field.onChange && field.onChange(t)
          }
        }}
        type={field.password ? 'password' : 'text'}
        fullWidth
        multiline={!!field.multiline}
        maxRows={field.multiline || 6}
        minRows={field.multiline || 1}
        InputProps={{
          ...(prepend || append || clear),
          readOnly: !!field.readOnly || !edit,
        }}
        error={errors && !!errors[field.name]}
        helperText={(errors && errors[field.name]) || field.helperText}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter' && !field.multiline) {
            onEnter && onEnter()
            ev.preventDefault()
          }
        }}
      >
        {field.options &&
          field.options.map((o, i) => {
            return (
              <MenuItem key={i} value={o.value}>
                {o.label}
              </MenuItem>
            )
          })}
        {fkList &&
          fkList[field.foreignKey.table].map((o, i) => {
            const {
              foreignKey: { name = 'name', value = 'id' },
            } = field
            return (
              <MenuItem key={i} value={o[value]}>
                {typeof name === 'function' ? name(o) : o[name]}
              </MenuItem>
            )
          })}
      </TextField>
    </>
  )
}

export default Input
