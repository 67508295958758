import React from 'react'
import { Api } from '@jesseteal/teal-react'
import { SimpleForm } from 'components/Basic'
import FieldDef from 'components/Basic/FieldDef'
import { useUser } from 'shared/hooks'

const Notifications = ({ id }) => {
  const doSave = Api.useSave('user')
  const { user } = useUser(id)
  return (
    <SimpleForm
      header="Notifications"
      model={user}
      resetOnSave
      fields={[
        FieldDef.name('notify_by_email')
          .bool()
          .label('Send Email Notifications')
          .get(12),
      ]}
      save={(modified) => {
        doSave({ ...modified, id: user.id })
      }}
    ></SimpleForm>
  )
}

export default Notifications
