import { createTheme, colors } from '@material-ui/core'
import shadows from './shadows'
import typography from './typography'
import shared from 'shared/constants'

const theme = createTheme({
  palette: {
    background: {
      dark: shared.color.background,
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: shared.color.primary, //'rgb(2,90,90)',//colors.indigo[500]
    },
    secondary: {
      main: shared.color.secondary, //'#08888D' //colors.indigo[300]
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  shadows,
  typography,
})

export default theme
