import React from 'react'
import { Provider } from '@jesseteal/teal-react/hooks/useContextState'
import GraphqlProvider from '@jesseteal/teal-react/graphql/GraphqlProvider'
import App from './App'
import { Api, Hooks, Utils } from '@jesseteal/teal-react'
import { schema } from 'shared/schema'
// import jwtDecode from 'jwt-decode';

const AppContext = () => {
  // const [token, set_token] = React.useState(null);
  // const [saved_token, set_saved_token] = Hooks.useLocalStorage('token',null);
  let init_config = {
    graphql_path:
      (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '') +
      '/graphql',
    schema,
  }
  // console.log('token',Utils.token.token(),{token:Utils.token.get()});
  if (Utils.token.token()) {
    // Utils.token.set(saved_token,true)
    // console.log('saved_token',saved_token);
    init_config = {
      ...init_config,
      token: Utils.token.token(),
      update_by_field: 'updated_by',
      update_by: Utils.token.get().u,
    }
  }
  const [config, set_config] = React.useState(init_config)

  React.useLayoutEffect(() => {
    // occurs before other components onMount
    Api.set_base(
      process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : ''
    )
  })
  Hooks.useOnMount(() => {
    Utils.token.onTokenChange(() => {
      set_config({
        ...config,
        token: Utils.token.token(),
        schema,
        update_by_field: 'updated_by',
        update_by: Utils.token.get().u,
      })
    })
  })

  return (
    <Provider>
      <GraphqlProvider config={config}>
        <App />
      </GraphqlProvider>
    </Provider>
  )
}

export default AppContext

// onToken={token => {
//   set_saved_token(token);
//   set_config({
//     ...config,
//     token,
//     schema,
//     update_by_field: 'updated_by',
//     update_by: Utils.token.get().u
//   })
// }}
