import React from 'react'
import SimpleCard from 'components/Basic/SimpleCard'
import RawForm from 'components/RawForm/RawForm'
import { Utils } from '@jesseteal/teal-react'
import { validate_fields } from 'components/Basic/Validate'
import K from 'Constants'

/*
usage
<SimpleForm
  header="Header"
  subheader="Subheader"
  model={model}
  save={modified => {
    doSave({...modified, id: model.id}, !model.id).then(refetch);
    set_edit(null)
  }}
  cancel={() => set_edit(null)}
  fields={[
    FieldDef.name('name').label('Flight Date').required().get(6),
  ]}
/>
*/
const SimpleForm = (props) => {
  // console.log('[SimpleForm]', props.header)
  const {
    header,
    subheader,
    cancel,
    save,
    model,
    fields,
    editMode = true,
    ...rest
  } = props
  const [modified, set_modified] = React.useState({})
  const [errors, set_errors] = React.useState({})
  const [edit, set_edit] = React.useState(editMode)
  const merged = { ...model, ...modified }
  const onSave = () => {
    let all_fields = []
    // console.log('[SimpleForm] allfields preservied?', all_fields)
    if (!!fields[0].column) {
      for (var f of fields) {
        // console.log({ f })
        all_fields = all_fields.concat(f.column.fields)
      }
    } else {
      all_fields = fields
    }
    // console.log('[SimpleForm] all_fields', { fields, all_fields })
    const errors = validate_fields(merged, all_fields)
    if (!!errors) {
      // console.log({ errors })
      set_errors(errors)
      return // no save if errors
    }
    // console.log('SimpleForm: calling save', { modified, merged })
    save(modified, merged)
    set_edit(editMode)
    set_errors({})
    if (props.resetOnSave) {
      set_modified({})
      set_errors({})
    }
  }
  const onChange = (data) => {
    let changes = { ...modified, ...data }
    set_modified(changes)
    // console.log('[SimpleForm]', { changes })
    props.onChange && props.onChange(changes)
  }

  const get_actions = () => {
    if (props.displayOnly) {
      return null
    }
    if (props.actionsRight) {
      // allow full override
      return props.actionsRight
    }
    const options = []
    if (edit) {
      const Icon = props.saveIcon || K.icon.save
      const label = props.saveLabel || 'Save'
      options.push({
        label: (
          <>
            <Icon className="mr1" /> {label}
          </>
        ),
        onClick: onSave,
        disabled: () => !Utils.hasDiff(modified, {}),
      })
      if (editMode === false) {
        options.push({
          label: (
            <>
              <K.icon.cancel className="mr1" /> Cancel
            </>
          ),
          onClick: () => {
            set_edit(false)
            set_modified({})
            set_errors(null)
          },
        })
      }
    } else {
      options.push({
        label: (
          <>
            <K.icon.edit className="mr1" /> Edit
          </>
        ),
        onClick: () => set_edit(true),
      })
    }
    return options
  }

  return (
    <SimpleCard
      {...rest}
      header={header}
      subheader={subheader}
      headeraction={
        cancel ? { icon: <K.icon.remove />, onClick: cancel } : null
      }
      actionsRight={get_actions()}
    >
      <RawForm
        edit={edit}
        values={{ ...model, ...modified }}
        fields={fields}
        errors={errors}
        onChange={onChange}
        onEnter={onSave}
      />
    </SimpleCard>
  )
}

export default SimpleForm
