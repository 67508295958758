import { Api, Utils } from '@jesseteal/teal-react'

export const ENVIRONMENT = 'production'
// export const ENVIRONMENT = 'staging'

export const snack = {
  // info: snack_info,
}

// simple number obfuscation
export const PK = {
  obscure: (id) => {
    return (id * 1234 + 54321).toString(36)
  },
  get: (id) => {
    return (parseInt(id, 36) - 54321) / 1234
  },
}

export const refresh_token = async () => {
  // refresh token
  return await Api.Post('/auth/refresh', { remora: Utils.token.token() }).then(
    (res) => {
      // console.log({res});
      if (res.status === 200 && res.token) {
        // console.log('-=-= token refresh -=-=', res.token);
        Utils.token.set(res.token)
      } // else what?
    }
  )
}
