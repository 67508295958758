import React from 'react'
import { Api } from '@jesseteal/teal-react'
import { SimpleTable } from 'components/Basic'
import { useProviders } from 'shared/hooks'
import K from 'Constants'

const translate_status = (status) => {
  switch (status) {
    case 'New':
      return 'New Registration'
    case 'Approved':
      return 'Approved - Profile Required'
    default:
      return status
  }
}

const ManageNewCarrier = () => {
  const { rows } = useProviders()
  const save_provider = Api.useSave('provider', ['provider'])

  if (!rows) {
    return null
  }
  return (
    <SimpleTable
      fullWidth
      header="Manage New Carrier Requests"
      data={rows}
      columns={[
        { header: 'Status', cell: (r) => translate_status(r.status) },
        { header: 'Air Carrier Name', cell: (r) => r.name },
        { header: 'Contact', cell: (r) => r.contact },
        { header: 'Phone', cell: (r) => r.phone },
        { header: 'Email', cell: (r) => r.email },
        {
          header: 'User Name',
          cell: (r) =>
            `${r.provider_user?.[0]?.user_id_user?.firstname} ${r.provider_user?.[0]?.user_id_user?.lastname}`,
        },
        {
          action: (provider) => {
            save_provider({
              id: provider.id,
              status: 'Approved',
            })
          },
          icon_props: (provider) => ({ disabled: provider.status !== 'New' }),
          icon: () => <K.icon.accept />,
        },
        {
          action: (provider) => {
            save_provider({
              id: provider.id,
              status: 'Declined',
            })
          },
          icon: () => <K.icon.cancel />,
        },
      ]}
    />
  )
}

export default ManageNewCarrier
