import React from 'react';
import { TextField } from '@material-ui/core';

const FlyField = ({ name, label, type, touched, errors, handleBlur, handleChange, values }) => {
  return (
    <TextField
      error={Boolean(touched[name] && errors[name])}
      fullWidth
      helperText={touched[name] && errors[name]}
      label={label}
      name={name}
      multiline={type === 'textarea'}
      onBlur={handleBlur}
      onChange={handleChange}
      type={type || "text"}
      value={values[name]}
      variant="outlined"
    />
  );
};

export default FlyField;
