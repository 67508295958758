import AWS from "aws-sdk";

const keyPrefix = "uploads/";
const AWS_BUCKET = "remora-app-public";
const AWS_REGION = "us-west-2";

const config = {
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET
}
// console.log({config, proc: process.env});
AWS.config.update(config);

const myBucket = new AWS.S3({
  params: { Bucket: AWS_BUCKET },
  region: AWS_REGION
});

const image_url = (filename) => {
  return `https://${AWS_BUCKET}.s3-${AWS_REGION}.amazonaws.com/${keyPrefix}${filename}`
}

const upload_file = (file, onProgress, filename = null) => {
  const _filename = filename || Date.now().toString(36) + '.jpg'
  console.log(_filename);
  const params = {
    ACL: "public-read",
    Body: file,
    Bucket: AWS_BUCKET,
    Key: `${keyPrefix}${_filename}`,
    ContentType: 'image/jpg'
  };

  return myBucket
    .putObject(params)
    .on("httpUploadProgress", evt => {
      const progress = Math.round((evt.loaded / evt.total) * 100)
      onProgress && onProgress(progress, filename);
    })
    .send(err => {
      if (err) console.log(err);
    });
};

const fn = {
  upload_file,
  image_url
}

export default fn
