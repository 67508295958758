import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Utils } from '@jesseteal/teal-react'
import { Container } from '@material-ui/core'
import Page from 'components/Page'
import SimpleTable from 'components/Basic/SimpleTable'
import SimpleTabs from 'components/Basic/SimpleTabs'
import SimpleForm from 'components/Basic/SimpleForm'
import ManageNewCarrier from './components/ManageNewCarrier'
import FieldDef from 'components/Basic/FieldDef'
import K from 'Constants'
import shared from 'shared/constants'
import { useUsersWithRole } from 'shared/hooks'

const ManageUsers = () => {
  const navigate = useNavigate()
  const [term, set_term] = React.useState('')
  const [tab, set_tab] = React.useState(0)
  const { rows } = useUsersWithRole()
  if (!rows) {
    return null
  }
  return (
    <Page title="Manage Users">
      <Container maxWidth="lg">
        <SimpleTabs
          tabs={[
            { content: null, label: 'All' },
            { content: null, label: 'Carriers' },
            { content: null, label: 'Users' },
            {
              content: <ManageNewCarrier />,
              label: 'New Carrier Registrations',
            },
          ]}
          onChange={set_tab}
        />
        {tab < 3 && (
          <SimpleForm
            displayOnly
            onChange={(m) => {
              set_term(m.term.toLowerCase())
            }}
            fields={[
              FieldDef.name('term')
                .label('Find')
                .prepend(<K.icon.search />)
                .get(),
            ]}
          />
        )}
        {tab < 3 ? (
          <SimpleTable
            fullWidth
            row_action={(r) => navigate(K.route.admin_user.href(r.id))}
            header={shared.label.admin_users.header}
            subheader={shared.label.admin_users.subheader}
            data={rows
              ?.filter((u) => {
                if (tab === 0) {
                  return true
                }
                const role = tab === 1 ? shared.role.provider : shared.role.user
                return (
                  u.role.filter((r) => r.role.indexOf(role) > -1).length > 0
                )
              })
              .filter((u) => {
                if (term.trim() === '') {
                  return true
                }
                return (
                  `${u.firstname}${u.lastname}${u.email}`
                    .toLowerCase()
                    .indexOf(term) > -1
                )
              })}
            columns={[
              { header: 'Name', cell: (r) => `${r.lastname}, ${r.firstname}` },
              { header: 'Email', cell: (r) => r.email },
              {
                header: 'Role',
                cell: (r) =>
                  r.role
                    .map((t) => t.role)
                    .join(', ')
                    .replace('Provider', 'Carrier'),
              },
              {
                header: 'Last Login',
                cell: (r) =>
                  Utils.date.format(r.last_login, Utils.date.FORMAT_FULLDATE),
              },
              {
                header: 'Enabled',
                cell: (r) =>
                  r.enabled ? (
                    <K.icon.check size={18} color="green" />
                  ) : (
                    <K.icon.cancel size={18} color="red" />
                  ),
                style: { width: '120px' },
                align: 'center',
              },
              // {
              //   action: (r) => navigate(K.route.admin_user.href(r.id)),
              //   style: { width: '80px' },
              // },
            ]}
          />
        ) : null}
      </Container>
    </Page>
  )
}

export default ManageUsers
