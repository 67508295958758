import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { Utils } from '@jesseteal/teal-react'
import { SimpleDialog } from 'components/Basic'
import { Container } from '@material-ui/core'
import Page from 'components/Page'
import MaxBox from 'components/Basic/MaxBox'
import { useClientRequests } from 'shared/hooks'
import shared from 'shared/constants'
import ClientCargoDetail from '../requests/components/ClientCargoDetail'
import ClientFlightDetail from '../requests/components/ClientFlightDetail'

const ClientCalendarView = () => {
  const [show_request, set_show_request] = React.useState(null)
  const { rows: requests } = useClientRequests({
    // for provider
    from_id: Utils.token.get().sub,
    deleted_at: null,
  })

  if (!requests) {
    return null // data not pulled yet
  }
  return (
    <Page>
      <Container maxWidth="lg">
        <MaxBox>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            height="100%"
            events={requests
              .filter((r) => {
                return [
                  shared.status.OPEN,
                  shared.status.PENDING,
                  shared.status.ASSIGNED,
                ].includes(r.status)
              })
              .map((r) => {
                const colors =
                  r.status === shared.status.ASSIGNED
                    ? {
                        backgroundColor: shared.color.primary,
                        borderColor: shared.color.secondary,
                      }
                    : {
                        textColor: '#333',
                        backgroundColor: '#CCC',
                        borderColor: '#999',
                      }
                return {
                  //   id: r.id,
                  id: `${r.id}_${r.type}`,
                  // id: bid.request_id,
                  date: r.start_date,
                  ...colors,
                  title: `${shared.label.cargo.identifier_value(r)} ${r.type} ${
                    r.from_airport_id_airport.code
                  } - ${r.to_airport_id_airport.code}`,
                }
              })}
            eventClick={(info) => {
              const [id, type] = info.event.id.split('_')
              set_show_request({ id, type })
            }}
          />
        </MaxBox>
      </Container>

      {show_request && (
        <SimpleDialog
          size="lg"
          open={true}
          onClose={() => set_show_request(null)}
        >
          {show_request.type === shared.flight_type.cargo ? (
            <ClientCargoDetail id={show_request.id} noBack={true} />
          ) : (
            <ClientFlightDetail id={show_request.id} noBack={true} />
          )}
        </SimpleDialog>
      )}
    </Page>
  )
}

export default ClientCalendarView
