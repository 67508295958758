import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Utils } from '@jesseteal/teal-react'
import { Chip, Container } from '@material-ui/core'
import Page from 'components/Page'
import { SimpleCard, SimpleTable } from 'components/Basic'
import BidForm from './ProviderBidForm'
import { useProviderAirports, useProviderRequests } from 'shared/hooks'
import K from 'Constants'
import shared from 'shared/constants'

const ProviderCargo = () => {
  const navigate = useNavigate()
  const [request, set_request] = React.useState(null)
  const [tab, set_tab] = React.useState(shared.status.OPEN)
  const { data: airport_data } = useProviderAirports(Utils.token.get().sub)
  const limit = Utils.date.add(new Date(), -3, 'yyyy-MM-dd')
  const { rows: requests, refetch } = useProviderRequests(
    Utils.token.get().sub,
    {
      or: [{ deleted_at: null }, { deleted_at: { gt: limit } }],
      status: {
        in: [shared.status.OPEN, shared.status.PENDING, shared.status.ASSIGNED],
      },
      type: shared.flight_type.cargo,
    }
  )

  const selected_airports = React.useMemo(() => {
    if (!airport_data) {
      return []
    }
    return airport_data.airport
      .filter((a) => a.provider_airport.length)
      .map((a) => a.id)
  }, [airport_data])

  if (!requests || !airport_data) {
    return null
  }

  const make_bid = (req) => {
    // const flights = flightsForRequest(req, data.flight);
    set_request(req)
    // set_flights(flights)
  }

  const close_form = () => {
    set_request(null)
    // set_flights(null);
  }

  const served_requests = requests.filter((r) => {
    if (selected_airports.length === 0) {
      return true
    } else {
      return (
        selected_airports.includes(r.from_airport_id) &&
        selected_airports.includes(r.to_airport_id)
      )
    }
  })

  const filtered_requests = served_requests.filter((r) => {
    if (tab === shared.status.OPEN) {
      return [shared.status.OPEN, shared.status.PENDING].includes(r.status)
    }
    if (tab === 'Quoted') {
      return r.bid.length && r.bid[0].status === shared.status.OPEN
    }
    if (tab === 'Won') {
      return r.bid.length && r.bid[0].status === shared.status.ACCEPTED
    }
    return true
  })

  return (
    <Page>
      <Container maxWidth="lg">
        {request ? (
          <BidForm
            request={request}
            onSave={() => {
              refetch()
              close_form()
            }}
            onCancel={close_form}
          />
        ) : (
          <SimpleCard
            noGutter
            header={shared.label.cargo.provider_header}
            subheader={shared.label.cargo.provider_subheader}
            tabs={[
              {
                label: `${shared.status.OPEN} (${
                  served_requests.filter((r) =>
                    [shared.status.OPEN, shared.status.PENDING].includes(
                      r.status
                    )
                  ).length
                })`,
                onClick: () => set_tab(shared.status.OPEN),
              },
              {
                label: `Quoted (${
                  served_requests.filter(
                    (r) =>
                      r.bid.length &&
                      r.bid[0].status === shared.status.OPEN &&
                      [shared.status.OPEN, shared.status.PENDING].includes(
                        r.status
                      )
                  ).length
                })`,
                onClick: () => set_tab('Quoted'),
              },
              {
                label: `Won (${
                  served_requests.filter(
                    (r) =>
                      r.bid.length && r.bid[0].status === shared.status.ACCEPTED
                  ).length
                })`,
                onClick: () => set_tab('Won'),
              },
            ]}
          >
            <SimpleTable
              fullWidth
              data={filtered_requests}
              columns={[
                {
                  header: 'ID',
                  cell: (r) => shared.label.cargo.identifier_value(r),
                },
                {
                  header: 'Date',
                  cell: (r) => Utils.date.format(r.start_date),
                  sort: 'start_date',
                },
                {
                  header: 'From',
                  cell: (r) => Utils.resolve(r, 'from_airport_id_airport.name'),
                },
                {
                  header: 'To',
                  cell: (r) => Utils.resolve(r, 'to_airport_id_airport.name'),
                },
                {
                  header: 'Description',
                  cell: (r) =>
                    r.request_cargo.map((c) => c.description).join(', '),
                },
                {
                  header: 'Total Weight',
                  sort: (r) =>
                    r.request_cargo.reduce((a, c) => a + c.weight, 0),
                  cell: (r) =>
                    r.request_cargo.reduce((a, c) => a + c.weight, 0) +
                    ' (lbs)',
                  align: 'right',
                },
                {
                  header: shared.label.bid.header,
                  sort: false,
                  cell: (r) => {
                    const hasBid = r.bid.length > 0
                    const className = hasBid ? `bid-${r.bid[0].status}` : null
                    if (r.deleted_at) {
                      return (
                        <Chip variant="outlined" disabled label="Cancelled" />
                      )
                    }
                    return (
                      <Chip
                        color="primary"
                        className={className}
                        variant="outlined"
                        onClick={() => {
                          if (hasBid) {
                            navigate(K.route.bid.href(r.bid[0].id))
                          } else {
                            make_bid(r)
                          }
                        }}
                        label={
                          hasBid
                            ? Utils.formatMoney(r.bid[0].cost)
                            : shared.label.bid.form_header
                        }
                      />
                    )
                  },
                },
              ]}
            />
          </SimpleCard>
        )}
      </Container>
    </Page>
  )
}

export default ProviderCargo
