import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Api, Utils } from '@jesseteal/teal-react'
import Loading from 'components/Loading'
import { SimpleCard, SimpleGrid, SimpleConfirm } from 'components/Basic'
import T from 'components/Typo'
import { useBid } from 'shared/hooks'
import K from 'Constants'
import shared from 'shared/constants'

const BidCard = ({ id, onEdit = null, ...props }) => {
  const navigate = useNavigate()
  const [confirm_delete, set_confirm_delete] = React.useState(false)
  const saveBid = Api.useSave('bid', 'bid,request')
  const { bid: model } = useBid(id)
  if (!model) {
    return <Loading />
  }

  return (
    <SimpleCard
      header={shared.label.bid.view_header}
      subheader={shared.label.bid.view_subheader}
      actionsLeft={
        model.status === shared.status.ACCEPTED && onEdit
          ? null
          : [
              {
                label: (
                  <React.Fragment>
                    <K.icon.money size={20} className="mr1" />{' '}
                    {model.status === shared.status.CLOSED
                      ? shared.label.bid.reopen_action
                      : shared.label.bid.change_action}
                  </React.Fragment>
                ),
                onClick: () => onEdit(),
              },
            ]
      }
      actionsRight={
        model.status === shared.status.OPEN
          ? [
              {
                label: (
                  <React.Fragment>
                    <K.icon.cancel color="red" className="mr1" />{' '}
                    {shared.label.bid.withdraw_action}
                  </React.Fragment>
                ),
                className: 'c-red',
                onClick: () => set_confirm_delete(true),
              },
            ]
          : null
      }
      {...props}
    >
      <SimpleGrid
        items={[
          {
            size: 4,
            content: T.label_value_stack(
              shared.label.bid.fly_date,
              Utils.date.format(model.fly_date)
            ),
          },
          {
            size: 4,
            content: T.label_value_stack(shared.label.bid.status, model.status),
          },
          {
            size: 4,
            content: T.label_value_stack(
              shared.label.bid.cost,
              Utils.formatMoney(model.cost)
            ),
          },
        ]}
      />
      {confirm_delete && (
        <SimpleConfirm
          header={shared.label.bid.withdraw_header}
          message={shared.label.bid.withdraw_message}
          actionLabel="Withdraw"
          cancelLabel="Cancel"
          onAction={() => {
            saveBid({
              id: Number(id),
              status: shared.status.CLOSED,
            }).then(() => {
              navigate(
                model.request_id_request.type === shared.flight_type.cargo
                  ? K.route.shipments.href
                  : K.route.flights.href
              )
            })
          }}
          onCancel={() => set_confirm_delete(false)}
        />
      )}
    </SimpleCard>
  )
}

export default BidCard
